import { Button, Col, Drawer, Form, Input, Row, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import ReactInputMask from 'react-input-mask';
import { addBank, editBank, getBanks } from '../../../api/BankSerivice';
import appNotification from '../../../components/notification';

const Banks: React.FC = () => {
  const [progressPeding, setProgressPending] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const columns = [
    {
      title: 'Codigo da instituição',
      render: (render: any) => render.code,
      sorter: true,
    },
    {
      title: 'Nome da instituição',
      render: (render: any) => render.name,
      sorter: true,
    },
    {
      title: 'Editar',
      render: (render: any) => (
        <Button
          onClick={
            () =>
              setDrawerData({
                ...drawerData,
                status: true,
                action: 'Editar',
                initialValues: {
                  id: render.id,
                  code: render.code,
                  name: render.name,
                },
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <FaEdit color="#4C2D6E" />
        </Button>
      ),
    },
  ];
  const [drawerData, setDrawerData] = useState({
    status: false,
    initialValues: {
      id: '',
      code: '',
      name: '',
    },
    action: '',
  });
  const [form] = Form.useForm();
  useEffect(() => {
    async function fetchBanks(): Promise<any> {
      setProgressPending(true);
      const response = await getBanks(pagination);
      setData(response.data);
      setPagination(response.pagination);
      setProgressPending(false);
    }
    fetchBanks();
    // eslint-disable-next-line
  }, []);

  async function handleTableChange(paginationParam: any): Promise<void> {
    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPending(true);
      const newPagination = {
        current: paginationParam.current,
        pageSize: paginationParam.pageSize,
      };
      const response = await getBanks(newPagination);
      setData(response.data);
      setPagination(response.pagination);
      setProgressPending(false);
    }
  }

  async function handleSubmit(values: any): Promise<any> {
    setLoadingSubmit(true);
    if (drawerData.action === 'Adicionar') {
      const response = await addBank(values);
      if (response.status) {
        setData([...data, response.data]);
        setPagination({ ...pagination, total: pagination.total + 1 });
        setDrawerData({ ...drawerData, status: false });
        setLoadingSubmit(false);
        return appNotification('success', response.message);
      }
      setLoadingSubmit(false);
      return appNotification('error', response.message);
    }

    let editedBank = { ...values };
    delete editedBank.documentValue;
    const response = await editBank(editedBank, drawerData.initialValues.id);

    if (response.status) {
      editedBank = {
        id: drawerData.initialValues.id,
        code: values.code,
        name: values.name,
      };

      const currentData = [...data];

      const editedIndex = currentData.findIndex(
        data => data.id === editedBank.id,
      );
      if (editedIndex !== -1) {
        currentData[editedIndex] = editedBank;
      }

      setData(currentData);
      setDrawerData({ ...drawerData, status: false });
      setLoadingSubmit(false);
      return appNotification('success', response.message);
    }

    setLoadingSubmit(false);
    return appNotification('error', response.message);
  }

  return (
    <>
      <Button
        onClick={
          () =>
            setDrawerData({
              ...drawerData,
              status: true,
              action: 'Adicionar',
              initialValues: {
                id: '',
                code: '',
                name: '',
              },
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        Adicionar
      </Button>
      <Table
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        loading={progressPeding}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <Drawer
        afterVisibleChange={() => form.resetFields()}
        destroyOnClose
        title={`${drawerData.action} instituição financeira`}
        width={720}
        onClose={() => setDrawerData({ ...drawerData, status: false })}
        visible={drawerData.status}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => setDrawerData({ ...drawerData, status: false })}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>
            <Button onClick={form.submit} type="primary">
              {drawerData.action}
            </Button>
          </div>
        }
      >
        <Spin spinning={loadingSubmit}>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            hideRequiredMark
            initialValues={drawerData.initialValues}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  name="code"
                  label="Codigo da instituição financeira"
                  rules={[
                    {
                      required: true,
                      message:
                        'por favor, insira o codigo da instituição financeira',
                    },
                  ]}
                >
                  <ReactInputMask mask="999">
                    {() => (
                      <Input placeholder="insira o codigo da instituição financeira" />
                    )}
                  </ReactInputMask>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Nome da instituição financeira"
                  rules={[
                    {
                      required: true,
                      message:
                        'por favor, insira o nome da instituição financeira',
                    },
                  ]}
                >
                  <Input
                    placeholder="insira o nome da instituição financeira"
                    maxLength={120}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default Banks;
