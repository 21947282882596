/* eslint-disable react/jsx-one-expression-per-line */

import { BackButton, Container, ForgotPasswordBox, FormButton, FormContainer } from './style';
import { Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';

import appNotification from '../../../components/notification';
import { appVersion } from '../../../config';
import { authenticate } from '../../../api/LoginService';
import { forgetPassword } from '../../../api/ResetService';
import { useHistory } from 'react-router-dom';

interface formInitialValues {
  email: '';
  password: '';
}

interface resetInitialValue{
  email: '';
}

const Login: React.FC = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  async function handleLogin(payload: formInitialValues): Promise<void> {
    setLoading(true);

    const { status, message} = await authenticate(payload);
    if (status) {
      history.push('/home');
    } else {
      setLoading(false);
      appNotification('error', message);        
    }
  }

  async function handleReset(payload: resetInitialValue): Promise<void> {
    setLoading(true);

    const { status, message } = await forgetPassword(payload);
    if (status) {
      appNotification('success', message);
      setLoading(false);
      history.push('/');
    } else {
      setLoading(false);

      appNotification('error', message);
    }
  }
  
  const LoginForm = () => {
    return (
      <Spin spinning={loading}>
        <Container>
          <section>
            <h1>U4CRYPTO</h1>
            <FormContainer>
              <Form
                name="login"
                onFinish={handleLogin}
                onFinishFailed={errorInfo => console.log(errorInfo)}
              >
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Por favor, digite o e-mail válido.',
                    },
                    { required: true, message: 'Por favor, preecha o email.' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Senha"
                  name="password"
                  rules={[
                    { required: true, message: 'Por favor, preencha a senha.' },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  size="small"
                  htmlType="submit"
                >
                  LOGIN
                </Button>
              </Form>
            </FormContainer>          
            <ForgotPasswordBox>
            <div>Esqueceu a Senha ?</div>
            <div>
              Clique
              <FormButton onClick={()=> !showForgotPassword && setShowForgotPassword(true)}>aqui</FormButton>               
            </div>
            </ForgotPasswordBox>
            <p> {appVersion} </p>
          </section>
        </Container>
      </Spin>
    );
  }

  const ForgotPasswordForm = () => {

    return (
      <Spin spinning={loading}>
        <Container>
          <section>
            <h1>U4CRYPTO</h1>
            <FormContainer>
              <Form
                name="forgotPassword"
                onFinish={handleReset}
                onFinishFailed={errorInfo => console.log(errorInfo)}
              >
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Por favor, digite o e-mail válido.',
                    },
                    { required: true, message: 'Por favor, preecha o email.' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Button
                  shape="round"
                  type="primary"
                  size="small"
                  htmlType="submit"
                >
                  ENVIAR
                </Button>
                <div>
                    <ForgotPasswordBox>
                      <BackButton onClick={()=> showForgotPassword && setShowForgotPassword(false)}> <strong>Voltar</strong></BackButton>  
                    </ForgotPasswordBox>
                </div>               
              </Form>
            </FormContainer>
            <p> {appVersion} </p>
          </section>
        </Container>
      </Spin>
    );
  }

  return (    
     ! showForgotPassword ? (<LoginForm />) : (<ForgotPasswordForm />) 
  );
  
};

export default Login;
