import { DownCircleOutlined, EyeOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import React from 'react';
import { BsTrash } from 'react-icons/bs';
import {
  ContentProps,
  GetPaymentProps,
} from '../../../../api/PaymentConsultService';
import * as S from '../style';

export type Pagination = {
  current: number;
  pageSize?: number;
  total: number;
};

interface TablePaymentProps {
  loading: boolean;
  data?: GetPaymentProps;
  openModalDetail: (row: ContentProps) => void;
  approvePayment: (id: string) => Promise<void>;
  deletePayment: (id: string) => Promise<void>;
  openModalReproved: (name: string, id: string) => void;
  pagination: Pagination;
  setPagination: (pagination: Pagination) => void;
}

const accessLevel = Cookies.get('accessLevel');

export const TablePayments: React.FC<TablePaymentProps> = ({
  loading,
  data,
  openModalDetail,
  openModalReproved,
  approvePayment,
  pagination,
  setPagination,
  deletePayment,
}: TablePaymentProps) => {
  const disabledButton = accessLevel !== '0' && accessLevel !== '1';

  const seeReceipt = (row: ContentProps): Window | null => {
    return window.open(row?.receipt, '_blank');
  };

  const columns = [
    {
      render: (row: ContentProps) => row.partner.name || '--',
      title: 'Parceiro',
      key: 'partnerName',
    },
    {
      render: (row: ContentProps) => row.registerDate,
      title: 'Data de cadastro',
      key: 'registerDate',
    },
    {
      render: (row: ContentProps) => row.transactionDate,
      title: 'Data de Transferência',
      key: 'transactionDate',
    },
    {
      render: (row: ContentProps) => row.value,
      title: 'Valor',
      key: 'value',
    },
    {
      dataIndex: 'cardPaymentStatus',
      title: 'Status',
      key: 'cardPaymentStatus',
    },
    {
      title: 'Comprovante',
      key: 'comprovante',
      render: (row: ContentProps) => (
        <button
          type="button"
          className="receipt"
          onClick={() => seeReceipt(row)}
        >
          <DownCircleOutlined />
        </button>
      ),
    },
    {
      title: 'Detalhes',
      key: 'details',
      render: (row: ContentProps) => (
        <button
          type="button"
          className="detail"
          onClick={() => openModalDetail(row)}
        >
          <EyeOutlined />
        </button>
      ),
    },
    {
      title: 'Ações',
      key: 'close',
      render: (row: ContentProps) => (
        <>
          {!row.statusChangeDate ? (
            <>
              <div className="actions">
                <S.ActionButton
                  $approved
                  onClick={() => approvePayment(row.id)}
                  disabled={loading || disabledButton}
                >
                  Aprovar
                </S.ActionButton>
                <S.ActionButton
                  onClick={() => openModalReproved(row.partner.name, row.id)}
                  disabled={loading || disabledButton}
                >
                  Reprovar
                </S.ActionButton>
              </div>
            </>
          ) : (
            <div className="status">
              <span>
                {row.cardPaymentStatus}
                <br />
                dia&nbsp;
                {row.statusChangeDate}
              </span>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Excluir',
      render: (row: ContentProps) => (
        <button
          type="button"
          onClick={() => deletePayment(row.id)}
          className="excluir"
          disabled={Boolean(row.statusChangeDate) || disabledButton}
        >
          <BsTrash className="icon" />
        </button>
      ),
    },
  ];

  const onChange = (page: number, pageSize?: number): void => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  const onShowSizeChange = (current: number, sizeNumber: number): void => {
    setPagination({ ...pagination, current, pageSize: sizeNumber });
  };

  return (
    <S.TableContainer
      dataSource={data?.content}
      columns={columns}
      pagination={{
        onChange: (page, pageSize) => onChange(page, pageSize),
        current: pagination.current,
        total: pagination.total,
        pageSize: pagination.pageSize,
        position: ['topRight'],
        pageSizeOptions: ['10', '50', '100'],
        showSizeChanger: true,
        onShowSizeChange: (current, sizeNumber) =>
          onShowSizeChange(current, sizeNumber),
      }}
      loading={loading}
    />
  );
};
