import styled from 'styled-components';
import { Menu } from 'antd';
import { primaryColor, softerGrey, white } from '../../config';

export const Container = styled.div`
  background-color: ${softerGrey};
  display: grid;
  grid-template-columns: 150px 3fr;
  grid-template-rows: 8vh calc(100vh - 8vh);
  grid-template-areas:
    'header header'
    'aside main';
`;

export const Header = styled.header`
  background-color: ${primaryColor};
  grid-area: header;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Aside = styled.aside`
  background-color: ${primaryColor};
  grid-area: aside;
  overflow-y: auto;
`;

export const Main = styled.main`
  background-color: ${white};
  grid-area: main;
  overflow-y: auto;
  overflow-x: auto;
  padding: 26px;
`;

export const User = styled.div`
  display: flex;
  padding-right: 20px;
  align-items: center;
`;

export const Logo = styled.div`
  font-size: 1.8em;
  color: white;
  padding-left: 26px;

  span {
    margin-left: 1rem;
    font-size: 1rem;
  }
`;

export const UserName = styled.div`
  font-size: 1em;
  color: white;
  padding-right: 20px;
`;

export const SideBar = styled(Menu)`
  background-color: ${primaryColor} !important;
  height: 100%;
  border-right: 0;

  > .ant-menu:not(.ant-menu-horizontal),
  > .ant-menu-item-selected {
    color: ${white};
    border-left-color: ${white};
    border-left-width: 8px;
    border-left-style: solid;
    background-color: ${primaryColor} !important;
    outline: none !important;
  }
`;

export const SideBarItems = styled(Menu.Item)`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;

  height: 23vh !important;
  width: 100% !important;

  background-color: ${primaryColor};
  color: ${white};
  font-size: '11pt' !important;
  margin: 0 !important;
  padding-left: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  &:hover,
  &:active {
    color: ${white};
    background-color: ${primaryColor} !important;
    border-left: 8px solid ${white};
    outline: none !important;
  }

  &:after {
    border-right: none !important;
  }

  > .anticon {
    font-size: 32px;
    margin-right: 0;
  }
`;

export const SideItemTitle = styled.h6`
  margin-top: 20px;
  color: ${white};
  font-size: 15px;
`;
