/* eslint-disable operator-assignment */
import React, { useState } from 'react';
import {
  Form,
  Button,
  Select,
  DatePicker,
  Descriptions,
  Space,
  Card,
  Spin,
} from 'antd';
import moment from 'moment';
import { getPartnersAsOptions } from '../../../../api/PartnerService';
import { translateTransactionsType } from '../../../../utils/translations/transactions-types.js';
import { formatCurrency } from '../../../../utils/format-currency.js';
import { getClousure } from '../../../../api/ClousureService';
import { getTransactions } from '../../../../api/TransactionService';
import { CardContainer } from './style';

const ConciliationDetails: React.FC = () => {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const [clousureDetails, setClousureDetails] = useState<any>(null);
  const [clousureInsiders, setClousureInsiders] = useState<any>(null);
  const [clousureOutsiders, setClousureOutsiders] = useState<any>(null);
  const [clousureBetween, setClousureBetween] = useState<any>(null);
  const contexts = [
    {
      value: 'insider',
      label: 'Entradas',
    },
    {
      value: 'outsider',
      label: 'Saídas',
    },
    {
      value: 'between',
      label: 'Entre Arranjos',
    },
  ];
  const [partners, setPartners] = useState<Array<any>>([]);

  async function fetchPartners(): Promise<any> {
    if (partners.length > 1) {
      return;
    }
    const response = await getPartnersAsOptions();
    setPartners(response.data);
  }

  async function handleSubmit(values: any): Promise<any> {
    setLoading(true);
    setClousureDetails(null);
    setClousureInsiders(null);
    setClousureOutsiders(null);
    setClousureBetween(null);
    const { context, date, partners } = values;

    let partnersParam: any = [];

    const contextParam: any = context.map((context: any) => context.value);

    const dateParam: any = date.map((date: any) =>
      moment(date).format('YYYY-MM-DD HH:mm:ss'),
    );

    if (partners !== undefined) {
      partnersParam = partners.map((partner: any) => partner.value);
    }

    const data = {
      initDate: dateParam[0],
      endDate: dateParam[1],
      partnerIds: partnersParam,
    };

    const clousureResponse = await getClousure(contextParam, data);

    if (clousureResponse) {
      const revenue = mountClousureObjetect(
        clousureResponse,
        'totalizers.revenue',
      );
      const cost = mountClousureObjetect(clousureResponse, 'totalizers.cost');

      setClousureDetails(
        <Space size="large">
          <Descriptions
            title={`Receitas: ${formatCurrency(revenue.clousureTotal)}`}
            bordered
          >
            {revenue.clousureArray?.map((type: any) => (
              <Descriptions.Item
                span={24}
                label={translateTransactionsType(type.desc)}
                key={type.desc}
              >
                {formatCurrency(type.total)}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Descriptions
            title={`Custos: ${formatCurrency(cost.clousureTotal)}`}
            bordered
          >
            {cost.clousureArray?.map((type: any) => (
              <Descriptions.Item
                span={24}
                label={translateTransactionsType(type.desc)}
                key={type.desc}
              >
                {formatCurrency(type.total)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Space>,
      );
    }

    const transactionsFilter: any = {};

    if (partnersParam.length > 0) {
      transactionsFilter.partner = partnersParam;
    }

    const conciliateRequest = await Promise.all(
      contextParam.map(async (cont: any) => {
        const response = await getTransactions({
          context: cont,
          pagination: {
            current: 1,
            pageSize: 50,
            total: 0,
            position: ['topRight' as const],
          },
          sorter: {},
          filters: { ...transactionsFilter, conciliationStatus: [1, 2] },
          date: `&period=["${dateParam[0]}", "${dateParam[1]}"]`,
        });
        return response;
      }),
    );

    const notConciliateRequest = await Promise.all(
      contextParam.map(async (cont: any) => {
        const response = await getTransactions({
          context: cont,
          pagination: {
            current: 1,
            pageSize: 50,
            total: 0,
            position: ['topRight' as const],
          },
          sorter: {},
          filters: { ...transactionsFilter, conciliationStatus: [0] },
          date: `&period=["${dateParam[0]}", "${dateParam[1]}"]`,
        });
        return response;
      }),
    );

    const insider: any = [];
    const outsider: any = [];
    const between: any = [];

    conciliateRequest.map((conciliate: any) => {
      switch (conciliate.arranj) {
        case 'insider':
          return insider.push({
            type: 'Conciliadas',
            data: conciliate.data,
            total: conciliate.pagination.total,
          });
        case 'outsider':
          return outsider.push({
            type: 'Conciliadas',
            data: conciliate.data,
            total: conciliate.pagination.total,
          });
        case 'between':
          return between.push({
            type: 'Conciliadas',
            data: conciliate.data,
            total: conciliate.pagination.total,
          });
        default:
      }
      return true;
    });

    notConciliateRequest.map((notConciliate: any) => {
      switch (notConciliate.arranj) {
        case 'insider':
          return insider.push({
            type: 'Não conciliadas',
            data: notConciliate.data,
            total: notConciliate.pagination.total,
          });
        case 'outsider':
          return outsider.push({
            type: 'Não conciliadas',
            data: notConciliate.data,
            total: notConciliate.pagination.total,
          });
        case 'between':
          return between.push({
            type: 'Não conciliadas',
            data: notConciliate.data,
            total: notConciliate.pagination.total,
          });
        default:
      }
      return true;
    });

    if (insider.length > 0) {
      setClousureInsiders(
        <>
          <p style={{ fontWeight: 700, fontSize: '16px' }}>Entradas</p>
          <CardContainer>
            {insider.map((insiders: any) => (
              <Card title={insiders.type}>{insiders.total}</Card>
            ))}
          </CardContainer>
        </>,
      );
    }

    if (outsider.length > 0) {
      setClousureOutsiders(
        <>
          <p style={{ fontWeight: 700, fontSize: '16px' }}>Saídas</p>
          <CardContainer>
            {outsider.map((outsider: any) => (
              <Card title={outsider.type}>{outsider.total}</Card>
            ))}
          </CardContainer>
        </>,
      );
    }
    if (between.length > 0) {
      setClousureBetween(
        <>
          <p style={{ fontWeight: 700, fontSize: '16px' }}>Entre arranjos</p>
          <CardContainer>
            {between.map((between: any) => (
              <Card title={between.type}>{between.total}</Card>
            ))}
          </CardContainer>
        </>,
      );
    }
    setLoading(false);
  }

  function mountClousureObjetect(contextsData: any, key: any): any {
    const clousureArray: any = [];

    contextsData.map((contextData: any) =>
      contextData.data.map((data: any) =>
        data.report.map((report: any) => {
          const transactionTypeIndex = clousureArray.findIndex(
            (element: any) =>
              element.desc === report['totalizersTransactionType.desc'],
          );
          if (transactionTypeIndex === -1) {
            return clousureArray.push({
              desc: report['totalizersTransactionType.desc'],
              total: Number(report[key]),
            });
          }
          clousureArray[transactionTypeIndex].total =
            clousureArray[transactionTypeIndex].total + Number(report[key]);

          return clousureArray;
        }),
      ),
    );
    let clousureTotal = 0;

    clousureArray.map((clousure: any) => {
      clousureTotal = Number(clousure.total) + clousureTotal;
      return clousureTotal;
    });

    return { clousureTotal, clousureArray };
  }

  return (
    <Spin spinning={loading}>
      <Form
        name="conciliateDetails"
        onFinish={handleSubmit}
        onFinishFailed={(errorInfo: any) => console.log(errorInfo)}
      >
        <Form.Item
          label="Tipos de transação"
          name="context"
          rules={[
            {
              required: true,
              message: 'Por favor, selecione um tipo de transação.',
            },
          ]}
        >
          <Select
            style={{ width: '340px' }}
            mode="multiple"
            labelInValue
            optionFilterProp="children"
            showSearch
          >
            {contexts.map(context => (
              <Select.Option key={context.label} value={context.value}>
                {context.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Data"
          name="date"
          rules={[
            {
              required: true,
              message: 'Por favor, selecione uma data',
            },
          ]}
        >
          <RangePicker
            format="DD-MM-YYYY HH:mm:ss"
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('00:00:00', 'HH:mm:ss'),
              ],
            }}
            placeholder={['Data Inicial', 'Data Final']}
            allowClear
            allowEmpty={[false, false]}
          />
        </Form.Item>
        <Form.Item label="Parceiros" name="partners">
          <Select
            style={{ width: '419px' }}
            mode="multiple"
            labelInValue
            onFocus={fetchPartners}
            optionFilterProp="children"
          >
            {partners?.map(partners => (
              <Select.Option key={partners.label} value={partners.value}>
                {partners.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button shape="round" type="ghost" size="small" htmlType="submit">
          Buscar detalhes
        </Button>
      </Form>
      <br />
      <br />
      <div>{clousureDetails}</div>
      <br />
      <br />
      {clousureInsiders}
      {clousureOutsiders}
      {clousureBetween}
    </Spin>
  );
};

export default ConciliationDetails;
