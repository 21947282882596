import { account } from './config/consts';
import { httpGet, httpPost, httpPut } from '../services/http/index.js';
import { isEmpty } from 'lodash';

interface Response {
  status: boolean;
  message: string;
}

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  position: any;
}

interface getUsers {
  pagination: Pagination;
  filters: any | undefined;
}

export async function getUsers({pagination, filters}: getUsers): Promise<any> {
  try {
    const { current, pageSize } = pagination;
    let url_filter = '';
    if (!isEmpty(filters)) {
      // eslint-disable-next-line
      for (const key in filters) {
          url_filter += `&${key}=${filters[key]}`;        
      }
    }
    
    const response = await httpGet(
      `${account}/v1beta1/user/filters?page=${current - 1}&take=${pageSize}${url_filter}`,
    );
    return {
      data: response.data.content,
      pagination: {
        current: 1,
        pageSize: 10,
        total: response.data.size,
        position: ['topRight' as const],
      },
    };
  } catch (error) {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        position: ['topRight' as const],
      },
    };
  }
}

export async function addUser(user: any): Promise<any> {
  try {
    const data = { ...user, enable: true };
    const response = await httpPost(`${account}/v1beta1/user`, data);

    return {
      data: response.data,
      status: true,
      message: 'Usuário adicionado com sucesso.',
    };
  } catch (error) {
    let message = 'Ocorreu um erro ao adicionar o usuário.';

    switch (error.response.data.message) {
      case 'Document already register!':
        message = 'Já existe um usuário cadastrado com o documento informado.';
        break;
      case 'E-mail already register!':
        message = 'Já existe um usuário cadastrado com o e-mail informado.';
        break;
      default:
        break;
    }

    return {
      status: false,
      message,
    };
  }
}

export async function editUser(user: any, id: string): Promise<any> {
  try {
    const response = await httpPut(`${account}/v1beta1/user/${id}`, user);
    return {
      data: response.data,
      status: true,
      message: 'Usuário editado com sucesso.',
    };
  } catch (error) {
    let message = 'Ocorreu um erro ao adicionar o usuário.';

    switch (error.response.data.message) {
      case 'Document already register!':
        message = 'Já existe um usuário cadastrado com o documento informado.';
        break;
      case 'E-mail already register!':
        message = 'Já existe um usuário cadastrado com o e-mail informado.';
        break;
      default:
        break;
    }

    return {
      status: false,
      message,
    };
  }
}

export async function changeUserStatus(
  status: boolean,
  id: string,
): Promise<any> {
  try {
    let func = '';
    let action = '';

    if (status) {
      func = 'disable';
      action = 'desativado';
    } else {
      func = 'enable';
      action = 'ativado';
    }
    const response = await httpPut(`${account}/v1beta1/user/${id}/${func}`);

    return {
      data: response.data,
      status: true,
      message: `Usuário ${action} com sucesso`,
    };
  } catch (error) {
    return {
      status: true,
      message: 'Não foi possivel modificar o status do usuário',
    };
  }
}

export async function getUser(id: string): Promise<any> {
  try {
    const response = await httpGet(`${account}/v1beta1/user/${id}`);
    return response;
  } catch (error) {
    return {
      data: [],
    };
  }
}

export async function userRemovePermission(
  id: string,
  params: any,
): Promise<any> {
  try {
    await httpPut(`${account}/v1beta1/user/${id}/permission/remove`, params);

    return true;
  } catch (error) {
    return false;
  }
}

export async function userAddPermission(id: string, params: any): Promise<any> {
  try {
    await httpPut(`${account}/v1beta1/user/${id}/permission/add`, params);
    return true;
  } catch (error) {
    return false;
  }
}

export async function userRemovePartnersPermission(
  id: string,
  params: any,
): Promise<any> {
  try {
    await httpPut(`${account}/v1beta1/user/${id}/partner/remove`, params);
    return true;
  } catch (error) {
    return false;
  }
}

export async function userAddPartnersPermission(
  id: string,
  params: any,
): Promise<any> {
  try {
    await httpPut(`${account}/v1beta1/user/${id}/partner/add`, params);
    return true;
  } catch (error) {
    return false;
  }
}

export async function userAccessLevelChange(
  id: string,
  accessLevel: number,
): Promise<any> {
  try {
    await httpPut(`${account}/v1beta1/user/${id}/access-level`, {
      accessLevel,
    });

    return true;
  } catch (error) {
    return false;
  }
}

export async function userChangePassword(values: any): Promise<Response> {
  try {
    await httpPut(`${account}/v1beta1/user/password`, values);

    return {
      status: true,
      message: 'Senha alterada com sucesso',
    };
  } catch (error) {
    return {
      status: false,
      message: 'Senha atual incorreta!',
    };
  }
}
