import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import {
  FaHandHoldingUsd,
  FaHome,
  FaKey,
  FaSearchDollar,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import AppBase from '../../components/App-base';

interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  const [user, setUser] = useState<string | null>('');
  const userEmail = cookies.get('user');
  const menuItems = [
    { name: 'Minha Conta', action: () => handleRouter('/user-account') },
    { name: 'Logout', action: () => handleLogout() },
  ];

  const permissions = cookies.get('userScreens');
  const userScreens = permissions ? JSON.parse(permissions) : [''];

  const [sideItems, setSideItems] = useState([
    {
      title: 'Home',
      key: 'home',
      icon: <FaHome size="35px" />,
      router: '/home',
    },
    {
      title: 'Conciliação',
      key: 'conciliation',
      icon: <FaHandHoldingUsd size="35px" />,
      router: '/conciliation/options',
    },
    // {
    //   title: 'Faturamento',
    //   key: 'bugde',
    //   icon: <FaSearchDollar size="35px" />,
    //   router: '/billing',
    // },
    {
      title: 'Consultas',
      key: 'consults',
      icon: <FaSearchDollar size="35px" />,
      router: '/consults/options',
    },
    {
      title: 'Permissões',
      key: 'options',
      icon: <FaKey size="35px" />,
      router: '/permissions/options',
    },
  ]);

  useEffect(() => {
    const sideFilter: string[] = [];
    if (!userScreens.includes('consults')) {
      sideFilter.push('consults');
    }
    if (!userScreens.includes('permission')) {
      sideFilter.push('options');
    }
    const filtereds = sideItems.filter(f => !sideFilter.find(s => s === f.key));
    setSideItems([...filtereds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUser = cookies.get('user');
    if (getUser) {
      setUser(getUser);
    }
  }, []);

  function handleRouter(url: string): void {
    history.push(url);
  }
  function handleLogout(): void {
    cookies.remove('token');
    history.push('/');
  }

  return (
    <>
      <AppBase
        user={user}
        userImage={`https://ui-avatars.com/api/?background=683e9b&color=ffffff&name=${userEmail}`}
        dropdownItems={menuItems}
        sideItems={sideItems}
        pageContent={children}
      />
    </>
  );
};

export default AppLayout;
