import loginBackground from '../../../assets/images/loginBackground.svg';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background: linear-gradient(rgba(76, 45, 110, 0.6), rgba(76, 45, 110, 0.5)),
    url(${loginBackground});
  background-size: cover;
  background-position: top;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  section {
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    p {
      color: white;
      font-size: 1rem;
    }
  }

  h1 {
    font-size: 3rem;
    color: #fff;
    margin: none !important;
    animation: moveBottom 1s ease-out;
    backface-visibility: hidden;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes moveBottom {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes moveTop {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const FormButton = styled.div`
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: underline;
  cursor: pointer;
  float: right;
  margin-left: 0.5rem; 
`;

export const BackButton = styled.div`
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: underline;
  cursor: pointer;
  float: right;
  margin-left: 0.5rem; 
  margin-top: 2rem;
  color: black;
`;


export const ForgotPasswordBox = styled.div`
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13pt;
  animation: moveTop 1s ease-out;
  backface-visibility: hidden;

  strong {
    margin-left: 3rem;
    margin-top: 15rem;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const FormContainer = styled.div`
  width: 30rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: rgba(255, 255, 255, 65%);
  box-shadow: 5px 0px 36px -21px rgba(0, 0, 0, 0.75);
  animation: moveLeft 1s ease-out;
  backface-visibility: hidden;

  @keyframes moveLeft {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
    80% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  form {
    width: 75%;

    button {
      width: 79%;
      margin-left: 4rem;
      margin-top: 1rem;

      &:hover {
        transform: translateY(-10px);
      }
    }
  }
`;
