import React from 'react';
import { Bar } from 'react-chartjs-2';

interface Props {
  ChartData: any;
  legend: any;
}

const BarChart: React.FC<Props> = ({ ChartData, legend }: Props) => {
  return (
    <Bar
      data={ChartData}
      options={{
        tooltips: {
          backgroundColor: 'white',
          borderColor: '#E5E5E5',
          titleFontColor: 'black',
          bodyFontColor: 'black',
          displayColors: false,
          cornerRadius: 6,
          borderWidth: 0.5,
        },
        title: {
          display: false,
        },
        legend: {
          display: legend,
        },

        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.9,
              categoryPercentage: 0.55,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      }}
    />
  );
};

export default BarChart;
