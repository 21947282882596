import cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Billing from '../pages/Billing';
import ConciliationOptions from '../pages/Conciliation/Options';
import Functionalities from '../pages/Permissions/Functionalities';
import Home from '../pages/Home';
import Login from '../pages/Auth/Login';
import Menus from '../pages/Permissions/Menus';
import PermissionsOptions from '../pages/Permissions/Options';
import PrivateRoute from './private-routes';
import ResetPassword from '../pages/reset-password';
import SecurityAlert from '../pages/Security-alert';
import Transactions from '../pages/Conciliation/Transactions';
import UserAccount from '../pages/User-account';
import Users from '../pages/Permissions/Users';
import UsersPermissions from '../pages/Permissions/Users/components/user-permissions';
import VerifyEmail from '../pages/Verify-email';
import { BalanceConsult } from '../pages/Conciliation/Balance-Consult';
import { ConsultOptions } from '../pages/Consults/Options';

const Routes: React.FC = () => {
  const history = useHistory();

  const permissions = cookies.get('userScreens');
  const userScreens = permissions ? JSON.parse(permissions) : [''];

  useEffect(() => {
    const token = cookies.get('token');
    if (token) {
      if (window.location.pathname === '/') {
        return history.push('/home');
      }
      return history.push(window.location.pathname);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/reset-password/:hash" component={ResetPassword} />
      <Route
        exact
        path="/security-alert-email/:hash"
        component={SecurityAlert}
      />
      <Route exact path="/verify-email/:hash" component={VerifyEmail} />
      <PrivateRoute exact path="/home" component={Home} />
      {userScreens.includes('permission') && (
        <PrivateRoute
          exact
          path="/permissions/options"
          component={PermissionsOptions}
        />
      )}
      <PrivateRoute exact path="/permissions/options/users" component={Users} />
      <PrivateRoute exact path="/user-account" component={UserAccount} />
      {userScreens.includes('change_permission_button') && (
        <PrivateRoute
          exact
          path="/permissions/options/users/permissions/:id"
          component={UsersPermissions}
        />
      )}
      <PrivateRoute exact path="/permissions/options/menus" component={Menus} />

      <PrivateRoute exact path="/billing" component={Billing} />

      <PrivateRoute
        exact
        path="/permissions/options/funcs"
        component={Functionalities}
      />
      <PrivateRoute
        exact
        path="/conciliation/options"
        component={ConciliationOptions}
      />
      <PrivateRoute
        exact
        path="/conciliation/options/transactions"
        component={Transactions}
      />
      {/*
      {userScreens.includes('tariff_plan') && (
        <PrivateRoute
          exact
          path="/conciliation/options/plans"
          component={Plans}
        />
      )}
      {userScreens.includes('partners') && (
        <PrivateRoute
          exact
          path="/conciliation/options/partners"
          component={Partners}
        />
      )} */}
      {userScreens.includes('balance_consult') && (
        <PrivateRoute
          exact
          path="/conciliation/options/balance-consult"
          component={BalanceConsult}
        />
      )}
      {userScreens.includes('consults') && (
        <PrivateRoute
          exact
          path="/consults/options"
          component={ConsultOptions}
        />
      )}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
