import { account } from './config/consts';
import { httpGet, httpPost, httpPut } from '../services/http/index.js';

export async function getMenus(pagination: any): Promise<any> {
  try {
    const { current, pageSize } = pagination;
    const response = await httpGet(
      `${account}/v1beta1/menu?page=${current - 1}&take=${pageSize}`,
    );

    return {
      data: response.data.content,
      pagination: {
        current: 1,
        pageSize: 10,
        total: response.data.size,
        position: ['topRight' as const],
      },
    };
  } catch (error) {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        position: ['topRight' as const],
      },
    };
  }
}

export async function addMenu(menu: any): Promise<any> {
  try {
    const data = { ...menu, enable: true };
    const response = await httpPost(`${account}/v1beta1/menu`, data);

    return {
      data: response.data,
      status: true,
      message: 'Menu adicionado com sucesso.',
    };
  } catch (error) {
    return {
      status: false,
      message: 'Erro ao adicionar o menu.',
    };
  }
}

export async function editMenu(menu: any, id: string): Promise<any> {
  try {
    const response = await httpPut(`${account}/v1beta1/menu/${id}`, menu);
    return {
      data: response.data,
      status: true,
      message: 'Menu editado com sucesso.',
    };
  } catch (error) {
    return {
      status: false,
      message: 'Erro ao editar o menu.',
    };
  }
}

export async function changeMenuStatus(
  status: boolean,
  id: string,
): Promise<any> {
  try {
    let func = '';
    let action = '';

    if (status) {
      func = 'disable';
      action = 'desativado';
    } else {
      func = 'enable';
      action = 'ativado';
    }
    const response = await httpPut(`${account}/v1beta1/menu/${id}/${func}`);

    return {
      data: response.data,
      status: true,
      message: `Menu ${action} com sucesso`,
    };
  } catch (error) {
    return {
      status: true,
      message: 'Não foi possivel modificar o status do menu',
    };
  }
}
