/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spin, Table, Button, Tag, Card } from 'antd';
import { FaFileDownload } from 'react-icons/fa';
import DataComparison from '../../../../../containers/data-comparison';
import {
  getTotalizersTransactionType,
  getTotalizersBy,
  getTotalizersByCustomer,
  getTotalizersByPartner,
  exportTransactions,
} from '../../../../../api/TransactionService';
import { formatCurrency } from '../../../../../utils/format-currency.js';
import AppNotification from '../../../../../components/notification';
import { TotalizersContainer } from './style';
import { secundaryColor } from '../../../../../config';

interface Props {
  total: number;
  reduxContext?: {
    type?: string;
    totalizers?: any;
    total?: number;
    filters?: any;
    period?: string | undefined;
    dashboardVisibility?: boolean;
  };
}

const Dashboard: React.FC<Props> = ({ reduxContext, total }: Props) => {
  // const history = useHistory();
  const [loadingDash, setLoadingDash] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    partners: false,
    customers: false,
  });

  const [generalTotalizersCards] = useState([
    {
      colGrid: 5,
      title: 'Valor transacionado',
      content: formatCurrency(reduxContext?.totalizers.value),
      router: 'total_value',
    },
    {
      colGrid: 5,
      title: 'Soma custo',
      content: formatCurrency(reduxContext?.totalizers.cost || 0),
      router: 'total_cost',
    },
    {
      colGrid: 5,
      title: 'Soma tarifas',
      content: formatCurrency(reduxContext?.totalizers.chargedTariff),
      router: 'total_chargedTariff',
    },
    {
      colGrid: 5,
      title: 'Soma receita',
      content: formatCurrency(reduxContext?.totalizers.revenue),
      router: 'total_revenue',
    },
    {
      colGrid: 4,
      title: 'Transações',
      content: total,
      router: 'transactions_total',
    },
  ]);

  const [partnersData, setPartnersData] = useState([]);
  const partnersColumns = [
    {
      title: 'Parceiro',
      dataIndex: ['partner', 'name'],
      sorter: (a: any, b: any) => a.partner.name.localeCompare(b.partner.name),
      key: 'partnerName',
    },
    // {
    //   title: 'Detalhamento mensal',
    //   // render: (render: any) => (
    //   //   <Button
    //   //     onClick={() =>
    //   //       history.push(
    //   //         `/conciliation/options/transactions/month-details/${reduxContext.type}/${render.partner.name}/${render.id}`,
    //   //       )
    //   //     }
    //   //   >
    //   //     Detalhamento mensal
    //   //   </Button>
    //   // ),
    // },
    {
      title: 'Total de transações',
      render: (render: any) => render.transactionsCount,
      sorter: (a: any, b: any) => a.transactionsCount - b.transactionsCount,
      key: 'count',
      align: 'center' as const,
    },
    {
      title: 'Valor transacionado',
      render: (render: any) => formatCurrency(render.totalizers.value),
      sorter: (a: any, b: any) => a.totalizers.value - b.totalizers.value,
      key: 'value',
      align: 'right' as const,
    },
    {
      title: 'Soma custo',
      render: (render: any) => formatCurrency(render.totalizers.cost),
      sorter: (a: any, b: any) => a.totalizers.cost - b.totalizers.cost,
      key: 'cost',
      align: 'right' as const,
    },
    {
      title: 'Soma Tarifas',
      render: (render: any) => formatCurrency(render.totalizers.chargedTariff),
      sorter: (a: any, b: any) =>
        a.totalizers.chargedTariff - b.totalizers.chargedTariff,
      key: 'tariff',
      align: 'right' as const,
    },
    {
      title: 'Soma receita',
      render: (render: any) => formatCurrency(render.totalizers.revenue),
      sorter: (a: any, b: any) => a.totalizers.revenue - b.totalizers.revenue,
      key: 'revenue',
      align: 'right' as const,
    },
  ];
  const [partnerTransactionType, setPartnerTransactionType] = useState({
    status: false,
    data: [],
  });
  const [
    partnerTransactionTypeColumns,
    setPartnerTransactionTypeColumns,
  ] = useState([
    {
      title: 'Total de transações',
      render: (render: any) => render?.transactionsCount,
      key: 'count',
      align: 'center' as const,
    },
    {
      title: 'Tipo de transação',
      render: (render: any) => <Tag>{render?.name}</Tag>,
      key: 'type',
    },
    {
      title: 'Valor transacionado',
      render: (render: any) => formatCurrency(render?.totalizers.value),
      sorter: (a: any, b: any) => a.totalizers.value - b.totalizers.value,
      key: 'value',
      align: 'right' as const,
    },
    {
      title: 'Soma custo',
      render: (render: any) => formatCurrency(render?.totalizers.cost),
      sorter: (a: any, b: any) => a.totalizers.cost - b.totalizers.cost,
      key: 'cost',
      align: 'right' as const,
    },
    {
      title: 'Soma Tarifas',
      render: (render: any) => formatCurrency(render?.totalizers.chargedTariff),
      sorter: (a: any, b: any) =>
        a.totalizers.chargedTariff - b.totalizers.chargedTariff,
      key: 'tariff',
      align: 'right' as const,
    },
    {
      title: 'Soma receita',
      render: (render: any) => formatCurrency(render?.totalizers.revenue),
      sorter: (a: any, b: any) => a.totalizers.revenue - b.totalizers.revenue,
      key: 'revenue',
      align: 'right' as const,
    },
  ]);

  const [customersData, setCustomersData] = useState([]);
  const customersColumns = [
    {
      title: 'Cliente',
      dataIndex: ['customer', 'name'],
      sorter: (a: any, b: any) =>
        a.customer.name.localeCompare(b.customer.name),
      key: 'customerName',
    },
    {
      title: 'Total de transações',
      render: (render: any) => render.transactionsCount,
      sorter: (a: any, b: any) => a.transactionsCount - b.transactionsCount,
      key: 'count',
      align: 'center' as const,
    },
    {
      title: 'Valor transacionado',
      render: (render: any) => formatCurrency(render.totalizers.value),
      sorter: (a: any, b: any) => a.totalizers.value - b.totalizers.value,
      key: 'value',
      align: 'right' as const,
    },
    {
      title: 'Soma custo',
      render: (render: any) => formatCurrency(render.totalizers.cost),
      sorter: (a: any, b: any) => a.totalizers.cost - b.totalizers.cost,
      key: 'cost',
      align: 'right' as const,
    },
    {
      title: 'Soma Tarifas',
      render: (render: any) => formatCurrency(render.totalizers.chargedTariff),
      sorter: (a: any, b: any) =>
        a.totalizers.chargedTariff - b.totalizers.chargedTariff,
      key: 'tariff',
      align: 'right' as const,
    },
    {
      title: 'Soma receita',
      render: (render: any) => formatCurrency(render.totalizers.revenue),
      sorter: (a: any, b: any) => a.totalizers.revenue - b.totalizers.revenue,
      key: 'revenue',
      align: 'right' as const,
    },
  ];
  const [customerTransactionType, setCustomerTransactionType] = useState({
    status: false,
    data: [],
  });
  const [
    customerTransactionTypeColumns,
    setCustomerTransactionTypeColumns,
  ] = useState([
    {
      title: 'Total de transações',
      render: (render: any) => render?.transactionsCount,
      key: 'total',
      align: 'center' as const,
    },
    {
      title: 'Tipo de transação',
      render: (render: any) => <Tag>{render?.name}</Tag>,
      key: 'count',
    },
    {
      title: 'Valor transacionado',
      render: (render: any) => formatCurrency(render?.totalizers.value),
      sorter: (a: any, b: any) => a.totalizers.value - b.totalizers.value,
      key: 'value',
      align: 'right' as const,
    },
    {
      title: 'Soma custo',
      render: (render: any) => formatCurrency(render?.totalizers.cost),
      sorter: (a, b) => a.totalizers.cost - b.totalizers.cost,
      key: 'cost',
      align: 'right' as const,
    },
    {
      title: 'Soma Tarifas',
      render: (render: any) => formatCurrency(render?.totalizers.chargedTariff),
      sorter: (a, b) => a.totalizers.chargedTariff - b.totalizers.chargedTariff,
      key: 'tariff',
      align: 'right' as const,
    },
    {
      title: 'Soma receita',
      render: (render: any) => formatCurrency(render?.totalizers.revenue),
      sorter: (a, b) => a.totalizers.revenue - b.totalizers.revenue,
      key: 'revenue',
      align: 'right' as const,
    },
  ]);
  const [transactionsTypeData, setTransactionsTypeData] = useState([]);
  const transactionsTypeColumns = [
    {
      title: 'Tipo de transação',
      dataIndex: ['name'],
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      key: 'type',
    },
    {
      title: 'Total de transações',
      render: (render: any) => render?.transactionsCount,
      key: 'count',
    },
    {
      title: 'Valor transacionado',
      render: (render: any) => formatCurrency(render.totalizers.value),
      sorter: (a: any, b: any) => a.totalizers.value - b.totalizers.value,
      key: 'value',
      align: 'right' as const,
    },
    {
      title: 'Soma custo',
      render: (render: any) => formatCurrency(render.totalizers.cost),
      sorter: (a: any, b: any) => a.totalizers.cost - b.totalizers.cost,
      key: 'cost',
      align: 'right' as const,
    },
    {
      title: 'Soma Tarifas',
      render: (render: any) => formatCurrency(render.totalizers.chargedTariff),
      sorter: (a: any, b: any) =>
        a.totalizers.chargedTariff - b.totalizers.chargedTariff,
      key: 'tariff',
      align: 'right' as const,
    },
    {
      title: 'Soma receita',
      render: (render: any) => formatCurrency(render.totalizers.revenue),
      sorter: (a: any, b: any) => a.totalizers.revenue - b.totalizers.revenue,
      key: 'revenue',
      align: 'right' as const,
    },
  ];

  useEffect(
    function fetchTotalizers() {
      async function getTotalizers(): Promise<any> {
        setLoadingDash(true);
        const response = await getTotalizersBy(
          reduxContext?.type,
          reduxContext?.filters,
          reduxContext?.period,
        );
        if (response) {
          setPartnersData(response.partner);
          setCustomersData(response.customer);
          setTransactionsTypeData(response.transactionsType);
        }

        setLoadingDash(false);
      }

      getTotalizers();
    },
    // eslint-disable-next-line
    [reduxContext?.filters, reduxContext?.type, reduxContext?.totalizers]
  );

  async function handleCustomerTransactionsType(customer: any): Promise<any> {
    setLoadingStates(prevState => {
      return { ...prevState, customers: true };
    });
    const customerFilter = {
      sourceCustomer: [customer.customer.document.value],
    };

    if (reduxContext?.type && reduxContext?.period) {
      const response = await getTotalizersTransactionType(
        reduxContext.type,
        customerFilter,
        reduxContext.period,
      );

      const customerArray: any = [...customerTransactionTypeColumns];

      if (customerArray[0].title === 'Cliente') {
        customerArray[0] = {
          title: 'Cliente',
          render: () => customer.customer.name,
        };
      } else {
        customerArray.unshift({
          title: 'Cliente',
          render: () => customer.customer.name,
        });
      }

      setCustomerTransactionType({ status: true, data: response });
      setCustomerTransactionTypeColumns(customerArray);

      setLoadingStates(prevState => {
        return { ...prevState, customers: false };
      });
    }
  }

  async function handlePartnerTransactionsType(partner: any): Promise<any> {
    setLoadingStates(prevState => {
      return { ...prevState, partners: true };
    });
    const partnerFilter = {
      partner: [partner.id],
    };

    if (reduxContext?.type && reduxContext.period) {
      const response = await getTotalizersTransactionType(
        reduxContext.type,
        partnerFilter,
        reduxContext.period,
      );

      const partnerArray: any = [...partnerTransactionTypeColumns];

      if (partnerArray[0].title === 'Parceiro') {
        partnerArray[0] = {
          title: 'Parceiro',
          render: () => partner.partner.name,
        };
      } else {
        partnerArray.unshift({
          title: 'Parceiro',
          render: () => partner.partner.name,
        });
      }

      setPartnerTransactionType({ status: true, data: response });
      setPartnerTransactionTypeColumns(partnerArray);
      setLoadingStates(prevState => {
        return { ...prevState, partners: false };
      });
    }
  }
  async function handleExportTransactions(): Promise<void> {
    setLoadingDash(true);

    if (reduxContext?.type && reduxContext?.filters && reduxContext?.period) {
      const { type, filters, period } = reduxContext;

      const response = await exportTransactions({
        transactionContext: type,
        sorter: {},
        filters,
        date: period,
      });

      if (response.status && response.email === true) {
        AppNotification('success', response.message);
      }

      if (response.status && response.email === false) {
        window.location.href = response.url;
        AppNotification('success', response.message);
      }

      if (response.status === false) {
        AppNotification('error', response.message);
      }
    }

    setLoadingDash(false);
  }

  return (
    <Spin spinning={loadingDash}>
      <Button
        icon={
          <FaFileDownload color="#4C2D6E" style={{ marginRight: '10px' }} />
        }
        onClick={handleExportTransactions}
      >
        Exportar Dados
      </Button>
      <h4
        style={{ marginTop: '40px', color: secundaryColor, fontSize: '18px' }}
      >
        Totalizadores gerais
      </h4>
      <TotalizersContainer>
        {generalTotalizersCards.map(card => (
          <Card key={card.title} title={card.title}>
            {card.content}
          </Card>
        ))}
      </TotalizersContainer>
      <DataComparison
        labels={{
          main: 'Tipo de transação',
          secondary: 'Comparar com tipos de transações:',
        }}
        title="Totalizadores por tipo de transação"
        placeholder="Selecione um tipo de transação..."
        fetchOptions={() => {
          if (reduxContext?.type) {
            return getTotalizersTransactionType(reduxContext.type);
          }

          return false;
        }}
        aditionalContent={
          <Table
            sortDirections={['ascend', 'descend', 'ascend']}
            dataSource={transactionsTypeData}
            columns={transactionsTypeColumns}
            rowKey={record => record.id}
            pagination={{ position: ['topRight' as const] }}
          />
        }
      />

      <DataComparison
        labels={{ main: 'Cliente:', secondary: 'Comparar com clientes:' }}
        title="Totalizadores por clientes"
        subtitle="Clique sobre um cliente na tabela para visualizar os detalhes por tipo de transação, ou se preferir faça uma comparação entre os clientes."
        placeholder="Selecione um cliente..."
        fetchOptions={() => {
          if (reduxContext?.type) {
            return getTotalizersByCustomer(reduxContext?.type);
          }
          return false;
        }}
        aditionalContent={
          <>
            {customerTransactionType.status && (
              <>
                {/* <SubtitleContainer>
                  <h5 style={{ color: secundaryColor }}>
                    {' '}
                    Cliente por tipo de transação.
                  </h5>
                </SubtitleContainer> */}
                <Table
                  loading={loadingStates.customers}
                  dataSource={customerTransactionType.data}
                  columns={customerTransactionTypeColumns}
                  rowKey={record => record.id}
                  pagination={false}
                />
              </>
            )}
            <br />
            <Table
              style={{ cursor: 'pointer' }}
              dataSource={customersData}
              columns={customersColumns}
              rowKey={record => record.id}
              pagination={{ position: ['topRight'] }}
              onRow={customer => {
                return {
                  onClick: () => handleCustomerTransactionsType(customer),
                };
              }}
            />
          </>
        }
      />
      <DataComparison
        labels={{ main: 'Parceiro:', secondary: 'Comparar com parceiros:' }}
        title="Totalizadores por parceiros"
        subtitle="Clique sobre um parceiro na tabela para visualizar os detalhes por tipo de transação, ou se preferir faça uma comparação entre os parceiros."
        placeholder="Selecione um parceiro..."
        fetchOptions={() => {
          if (reduxContext?.type) {
            return getTotalizersByPartner(reduxContext.type);
          }
          return false;
        }}
        aditionalContent={
          <>
            {partnerTransactionType.status && (
              <>
                {/* <SubtitleContainer>
                  <h5 style={{ color: secundaryColor }}>
                    {' '}
                    Parceiro por tipo de transação.
                  </h5>
                </SubtitleContainer> */}
                <Table
                  loading={loadingStates.partners}
                  dataSource={partnerTransactionType.data}
                  columns={partnerTransactionTypeColumns}
                  rowKey={record => record.id}
                  pagination={false}
                />
              </>
            )}
            <br />
            <Table
              style={{ cursor: 'pointer' }}
              dataSource={partnersData}
              columns={partnersColumns}
              rowKey={record => record.id}
              pagination={{ position: ['topRight' as const] }}
              onRow={partner => {
                return {
                  onClick: () => handlePartnerTransactionsType(partner),
                };
              }}
            />
          </>
        }
      />
    </Spin>
  );
};

const mapStateToProps = (state: any): any => ({
  reduxContext: state.Transactions.context,
});

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
