import { httpGet, httpPost, httpPut } from '../services/http/index.js';
import { conciliation } from './config/consts';

export async function getBanks(pagination: any): Promise<any> {
  try {
    const array: any = [];
    const { current, pageSize } = pagination;
    console.log(array);
    const response = await httpGet(
      `${conciliation}/v1beta1/bank?page=${current - 1}&take=${pageSize}`,
    );

    return {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
    };
  } catch (e) {
    console.log(e);
    return [{ value: null, label: null }];
  }
}

export async function searchBanks(value: any): Promise<any> {
  try {
    const array: any = [];

    const response = await httpGet(
      `${conciliation}/v1beta1/bank?page=${0}&take=${10}&name=${value}`,
    );
    response.data.content.map((bank: any) =>
      array.push({ value: bank.id, label: bank.name }),
    );

    return array;
  } catch (e) {
    console.log(e);
    return [{ value: null, label: null }];
  }
}

export async function addBank(bank: any): Promise<any> {
  try {
    const response = await httpPost(`${conciliation}/v1beta1/bank`, bank);
    return {
      data: response.data,
      status: true,
      message: 'Instituição financeira adicionada com sucessso.',
    };
  } catch (error) {
    return {
      status: false,
      message: 'Já existe um banco cadastrado com esse código.',
    };
  }
}

export async function editBank(bank: any, id: string): Promise<any> {
  try {
    const response = await httpPut(`${conciliation}/v1beta1/bank/${id}`, bank);
    return {
      data: response.data,
      status: true,
      message: 'Instituição financeira editada com sucessso.',
    };
  } catch (error) {
    return {
      status: false,
      message: 'Já existe um banco cadastrado com esse código.',
    };
  }
}
