/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { isEmpty } from 'lodash';
import cookies from 'js-cookie';
import { conciliation } from './config/consts';
import { httpGet, httpPost } from '../services/http/index.js';
import {
  translateTransactionsType,
  translateTransactionsTypeAsOptions,
} from '../utils/translations/transactions-types.js';

interface TransactionsResponse {
  data: Array<any>;
  pagination: Pagination;
  totalizers: number;
  arranj: string;
}

interface TransactionsExternalResponse {
  totalizers: number;
  data: Array<any>;
  pagination: Pagination;
}

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  position: any;
}

interface Sorter {
  orderColumn: string;
  orderDirection: string;
}

interface GetExternalTransactions {
  pagination: Pagination;
  filters: any | undefined;
  date: any;
}
interface GetTransactions {
  context: string;
  pagination: Pagination;
  sorter: Sorter | any;
  filters: any;
  date: string | undefined;
}

interface ExportResponse {
  status: boolean;
  url: string;
  email: boolean;
  message: string;
}

interface ExportTransactions {
  transactionContext: string;
  sorter: any;
  filters: any;
  date: any;
}
interface ExportExternalTransactions {
  filters: any;
  date: any;
}

export const getTransactions = async ({
  context,
  pagination,
  sorter,
  filters,
  date,
}: GetTransactions): Promise<TransactionsResponse> => {
  try {
    const { current, pageSize } = pagination;
    let url_filter = '';
    let url_sort = '';

    if (!isEmpty(sorter)) {
      url_sort = `&${sorter.orderColumn}=${sorter.orderDirection}`;
    }
    
    if (!isEmpty(filters)) {
      // eslint-disable-next-line
      for (const key in filters) {
        if (key === 'date') {
          if (filters[key][1] === undefined) {
            url_filter += `&${key}=${filters[key][0]}`;
          } else {
            url_filter += `&period=${JSON.stringify(filters[key])}`;
          }
        } else if (key === 'id') {
          url_filter += `&${key}=${filters[key]}`;
        } else {
          url_filter += `&${key}=${JSON.stringify(filters[key])}`;
        }
      }
    }

    const response = await httpGet(
      `${conciliation}/v1beta1/transaction/${context}?take=${pageSize}&page=${
        current - 1
      }${date}${url_filter}${url_sort}`,
    );

    const payload = {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
      totalizers: response.data.totalizers,
      arranj: context,
    };

    return payload;
  } catch (e) {
    console.log(e);

    const payload = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        position: ['topRight' as const],
      },
      totalizers: 0,
      arranj: context,
    };

    return payload;
  }
};

export const exportExternalTransactions = async ({
  filters,
  date
}: ExportExternalTransactions): Promise<ExportResponse> => {
  let url_filter = '';

  try {
    if (!isEmpty(filters)) {
      for (const key in filters){
        url_filter += `&${key}=${JSON.stringify(filters[key])}`;
      }
    }

    const response = await httpGet(
      `${conciliation}/v1beta1/transaction-wallet/export?external=1${date}${url_filter}`,
    );

    const url_download = response.data;
    return {
      status: true,
      url: url_download,
      email: false,
      message: 'Sucesso! Seu relatorio está pronto e já esta sendo baixado.',
    };
  } catch (e) {
    if (e.response.data.message === 'Too big for response') {
      try {
        const userEmail = cookies.get('user');
        await httpPost(
          `${conciliation}/v1beta1/transaction-wallet/export?external=1${date}${url_filter}`,
          { email: userEmail },
        );

        return {
          status: true,
          url: '',
          email: true,
          message: `Sucesso! Estamos gerando seu relatorio e em breve enviaremos para o email : ${userEmail}  para download.`,
        };
      } catch (e) {
        return {
          status: false,
          url: '',
          email: false,
          message:
            'Erro! Não conseguimos gerar seu relatorio. Algo deu errado.',
        };
      }
    } else if (
      e.response.data.message === 'Maximum difference of months is three'
    ) {
      return {
        status: false,
        url: '',
        email: false,
        message:
          'Erro! Não conseguimos gerar seu relatorio. Periodo selecionado superior a 3 meses. Por gentileza, selecione um periodo menor.',
      };
    }
    return {
      status: false,
      url: '',
      email: false,
      message: '',
    };
  }
};

export const getTransactionsExternal = async ({
  pagination,
  filters,
  date}: GetExternalTransactions): Promise<TransactionsExternalResponse> => {
  try {
    let url_filter = '';

    if (!isEmpty(filters)) {
      for (const key in filters){
        url_filter += `&${key}=${JSON.stringify(filters[key])}`;
      }
    }
    const { current, pageSize } = pagination;
    const response = await httpGet(
      `${conciliation}/v1beta1/transaction-wallet/external?take=${pageSize}&page=${
        current - 1}${date}&external=1${url_filter}`,
    );

    const payload = {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
      totalizers: response.data.totalizers,
    };
    /*const payload = {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
      totalizers: response.data.totalizers,
      arranj: context,
    };*/

    return payload;

  } catch (e) {
    console.log(e);

    const payload = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        position: ['topRight' as const],
      },
      totalizers: 0,
    };

    return payload;
  }
}


export const exportTransactions = async ({
  transactionContext,
  sorter = null,
  filters = null,
  date,
}: ExportTransactions): Promise<ExportResponse> => {
  let url_sort = '';
  let url_filter = '';
  let period = date !== undefined ? date : '';

  try {
    if (!isEmpty(sorter)) {
      url_sort = url_sort.length > 0 ? '&' : '?';
      url_sort += `${sorter.orderColumn}=${sorter.orderDirection}`;
    }

    if (!isEmpty(filters)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters) {
        url_filter += url_sort.length > 0 || url_filter.length > 0 ? '&' : '?';
        if (key === 'date') {
          if (filters[key][1] === undefined) {
            url_filter += `${key}=${filters[key][0]}`;
          } else {
            url_filter += `period=${JSON.stringify(filters[key])}`;
          }
        } else if (key === 'id') {
          url_filter += `${key}=${filters[key]}`;
        } else {
          url_filter += `${key}=${JSON.stringify(filters[key])}`;
        }
      }
    }

    if (isEmpty(sorter) && isEmpty(filters)) {
      period = date.replace('&', '?');
    }

    const response = await httpGet(
      `${conciliation}/v1beta1/transaction/${transactionContext}/export${url_sort}${url_filter}${period}`,
    );

    const url_download = response.data;
    return {
      status: true,
      url: url_download,
      email: false,
      message: 'Sucesso! Seu relatorio está pronto e já esta sendo baixado.',
    };
  } catch (e) {
    if (e.response.data.message === 'Too big for response') {
      try {
        const userEmail = cookies.get('user');
        await httpPost(
          `${conciliation}/v1beta1/transaction/${transactionContext}/export${url_sort}${url_filter}${period}`,
          { email: userEmail },
        );

        return {
          status: true,
          url: '',
          email: true,
          message: `Sucesso! Estamos gerando seu relatorio e em breve enviaremos para o email : ${userEmail}  para download.`,
        };
      } catch (e) {
        return {
          status: false,
          url: '',
          email: false,
          message:
            'Erro! Não conseguimos gerar seu relatorio. Algo deu errado.',
        };
      }
    } else if (
      e.response.data.message === 'Maximum difference of months is three'
    ) {
      return {
        status: false,
        url: '',
        email: false,
        message:
          'Erro! Não conseguimos gerar seu relatorio. Periodo selecionado superior a 3 meses. Por gentileza, selecione um periodo menor.',
      };
    }
    return {
      status: false,
      url: '',
      email: false,
      message: '',
    };
  }
};

export const getTransactionsType = async (): Promise<any> => {
  try {
    const response = await httpGet(
      `${conciliation}/v1beta1/transaction-type?take=${50}&page=${0}`,
    );
    const translate = translateTransactionsType(response.data);

    return translate;
  } catch (e) {
    console.log(e);

    return [];
  }
};

export async function getTotalizersTransactionType(
  context: string,
  filters?: any,
  date?: string,
): Promise<any> {
  try {
    let urlFilter = '';
    let period: any = date !== undefined ? date : '';

    if (!isEmpty(filters)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters) {
        urlFilter += urlFilter.length > 0 ? '&' : '?';
        if (key === 'date') {
          if (filters[key][1] === undefined) {
            urlFilter += `${key}=${filters[key][0]}`;
          } else {
            urlFilter += `period=${JSON.stringify(filters[key])}`;
          }
        } else {
          urlFilter += `${key}=${JSON.stringify(filters[key])}`;
        }
      }
    } else if (period !== '') {
      period = date?.replace('&', '?');
    }

    const response = await httpGet(
      `${conciliation}/v1beta1/transaction/${context}/totalizer/transaction-type${urlFilter}${period}`,
    );

    const translate = translateTransactionsTypeAsOptions(response.data);

    return translate;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export const getTotalizersBy = async (
  arranj: any,
  filters: any,
  date: any,
): Promise<any> => {
  let urlFilter = '';
  let period = '';

  if (date !== undefined && date !== null) {
    period = date;
  }

  if (!isEmpty(filters)) {
    for (const key in filters) {
      urlFilter += urlFilter.length > 0 ? '&' : '?';
      if (key === 'date') {
        if (filters[key][1] === undefined) {
          urlFilter += `${key}=${filters[key][0]}`;
        } else {
          urlFilter += `period=${JSON.stringify(filters[key])}`;
        }
      } else {
        urlFilter += `${key}=${JSON.stringify(filters[key])}`;
      }
    }
  } else if (period !== '') {
    period = date.replace('&', '?');
  }

  const customer = await httpGet(
    `${conciliation}/v1beta1/transaction/${arranj}/totalizer/customer${urlFilter}${period}`,
  );
  const partner = await httpGet(
    `${conciliation}/v1beta1/transaction/${arranj}/totalizer/partner${urlFilter}${period}`,
  );

  const transactionsType = await httpGet(
    `${conciliation}/v1beta1/transaction/${arranj}/totalizer/transaction-type${urlFilter}${period}`,
  );

  const translateTransactionsType = translateTransactionsTypeAsOptions(
    transactionsType.data,
  );

  return {
    customer: customer.data,
    partner: partner.data,
    transactionsType: translateTransactionsType,
  };
};

export async function getTotalizersByCustomer(context: string): Promise<any> {
  try {
    const response = await httpGet(
      `${conciliation}/v1beta1/transaction/${context}/totalizer/customer`,
    );

    const customers: any = [];
    response.data.map((customer: any) => {
      return customers.push({
        name: customer.customer.name,
        document: customer.customer.document.value,
        totalizers: customer.totalizers,
      });
    });

    return customers;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getTotalizersByPartner(context: string): Promise<any> {
  try {
    const response = await httpGet(
      `${conciliation}/v1beta1/transaction/${context}/totalizer/partner`,
    );

    const partners: any = [];
    response.data.map((customer: any) => {
      return partners.push({
        name: customer.partner.name,
        document: customer.partner.document.value,
        totalizers: customer.totalizers,
      });
    });
    return partners;
  } catch (e) {
    console.log(e);
    return false;
  }
}
