const INITIAL_STATE = {
  context: {
    type: '',
    totalizers: [],
    total: 0,
    period: '',
    filters: {},
    dashboardVisibility: false,
  },
  conciliationTransactions: [],
};

export default (state: any = INITIAL_STATE, action: any): void => {
  switch (action.type) {
    case 'SWITCH_SCREENS':
      return { ...state, dashboardRender: action.payload };
    case 'SET_CONTEXT':
      return { ...state, context: action.payload };
    case 'CONCILIATION_TRANSACTIONS':
      return { ...state, conciliationTransactions: action.payload };
    default:
      return state;
  }
};
