import styled from 'styled-components';
import { Card } from 'antd';
import { primaryColor } from '../../config';

export const CustomCard = styled(Card)`
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 10px 10px -14px rgba(0, 0, 0, 0.35);
  transition: border-left 0.2s;
  cursor: pointer;

  &:hover {
    border-left: 6px solid ${primaryColor};
  }

  > .ant-card-head {
    border-bottom: none;
  }
`;
