import React from 'react';
import { Line } from 'react-chartjs-2';

interface Props {
  ChartData: any;
  legend: any;
}

const LineChart: React.FC<Props> = ({ ChartData, legend }: Props) => {
  return (
    <Line
      data={ChartData}
      options={{
        tooltips: {
          backgroundColor: 'white',
          borderColor: '#E5E5E5',
          titleFontColor: 'black',
          bodyFontColor: 'black',
          displayColors: false,
          cornerRadius: 6,
          borderWidth: 0.5,
        },
        title: {
          display: false,
        },
        legend: {
          display: legend,
        },

        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: 'rgb(95, 93, 93)',
                fontFamily:
                  "'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'",
                padding: 4,
              },
            },
          ],
        },
      }}
    />
  );
};

export default LineChart;
