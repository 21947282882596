import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import {
  FaCommentsDollar,
  // FaHandshake,
  // FaReceipt,
  FaSearchDollar,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Card from '../../../components/Card';
import Header from '../../../components/Header';
import { primaryColor } from '../../../config';

const ConciliationOptions: React.FC = () => {
  const history = useHistory();
  const permissions = cookies.get('userScreens');
  const userScreens = permissions ? JSON.parse(permissions) : [''];

  const [cards, setCards] = useState([
    {
      colGrid: 6,
      title: 'Transações',
      content: <FaCommentsDollar size={40} color={primaryColor} />,
      router: '/conciliation/options/transactions',
      key: 'transactions',
    },
    // {
    //   colGrid: 6,
    //   title: 'Plano Tarifario',
    //   content: <FaReceipt size={40} color={primaryColor} />,
    //   router: '/conciliation/options/plans',
    //   key: 'tariff_plan',
    // },
    // {
    //   colGrid: 6,
    //   title: 'Parceiros',
    //   content: <FaHandshake size={40} color={primaryColor} />,
    //   router: '/conciliation/options/partners',
    //   key: 'partners',
    // },
    {
      colGrid: 6,
      title: 'Consultar Saldos',
      content: <FaSearchDollar size={40} color={primaryColor} />,
      router: '/conciliation/options/balance-consult',
      key: 'balance-consult',
    },
  ]);

  useEffect(() => {
    const cardsFilter: string[] = [];
    if (!userScreens.includes('balance_consult')) {
      cardsFilter.push('balance-consult');
    }
    // if (!userScreens.includes('tariff_plan')) {
    //   cardsFilter.push('tariff_plan');
    // }
    // if (!userScreens.includes('partners')) {
    //   cardsFilter.push('partners');
    // }
    const filtereds = cards.filter(f => !cardsFilter.find(c => c === f.key));
    setCards([...filtereds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRouter(url: string): void {
    history.push(url);
  }

  return (
    <>
      <Header
        title="Opções - Conciliação"
        onBack={() => history.push('/home')}
      />
      <Card handleClick={handleRouter} spaceBetween={[16, 32]} cards={cards} />
    </>
  );
};

export default ConciliationOptions;
