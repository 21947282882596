import { Button } from 'antd';
import React, { Key, useEffect, useState } from 'react';
import {
  addPermissionUser,
  getAppScreens,
  getAppScreensUser,
  removePermissionUser,
} from '../../../../../api/PermissionService';
import appNotification from '../../../../../components/notification';
import { PermissionsTitle } from '../user-permissions/style';
import * as S from './style';
import { TableColumnsNotAddeds, TableColumsAddeds } from './tableColumns';

interface TableUserPermissionProps {
  id: string;
}
export interface AppScreensProps {
  app_id: string;
  name: string;
}

export const TableUserPermissions: React.FC<TableUserPermissionProps> = ({
  id,
}: TableUserPermissionProps) => {
  const [appScreens, setAppScreens] = useState<AppScreensProps[]>([]);
  const [appScreensUser, setAppScreensUser] = useState<AppScreensProps[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRowKeysUser, setSelectedRowKeysUser] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);

  useEffect(() => {
    const GetAppScreens = async (): Promise<void> => {
      const response = await getAppScreens(id);
      setAppScreens(
        response.data.map((data: AppScreensProps) => ({
          ...data,
        })),
      );
    };

    GetAppScreens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  useEffect(() => {
    const GetAppScreensUser = async (): Promise<void> => {
      const response = await getAppScreensUser(id);
      setAppScreensUser(response.data);
    };
    GetAppScreensUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  const handleRowSelecteds = {
    selectedRowKeys: selectedRowKeys.map(
      (selected: AppScreensProps) => selected.app_id,
    ),
    onChange: (selectedRowKeys: Key[], selectedRows: any[]): void => {
      setSelectedRowKeys([...selectedRows]);
    },
  };

  const handleRowSelectedsUser = {
    selectedRowKeys: selectedRowKeysUser.map(
      (selected: AppScreensProps) => selected.app_id,
    ),
    onChange: (selectedRowKeys: Key[], selectedRows: any[]): void => {
      setSelectedRowKeysUser([...selectedRows]);
    },
  };

  const updatingTable = (): void => {
    setUpdateTable(state => !state);
    setSelectedRowKeys([]);
    setSelectedRowKeysUser([]);
  };

  const AddPermissionUser = async (): Promise<void | string | boolean> => {
    const payload = {
      data: selectedRowKeys.map((selecteds: AppScreensProps) => ({
        app_screen_id: selecteds.app_id,
      })),
    };
    setLoading(true);
    try {
      await addPermissionUser(id, payload);
      appNotification('success', 'Permissões adicionadas com sucesso!');
      updatingTable();
    } catch {
      return appNotification('error', 'Oops! Algo de errado aconteceu...!');
    } finally {
      setLoading(false);
    }
    return true;
  };

  const RemovePermissionUser = async (): Promise<void> => {
    const payload = {
      data: selectedRowKeysUser.map((selecteds: AppScreensProps) => ({
        app_screen_id: selecteds.app_id,
      })),
    };

    setLoading(true);
    try {
      await removePermissionUser(id, payload);
      appNotification('success', 'Permissões removidas com sucesso!');
      updatingTable();
    } catch {
      appNotification('error', 'Oops! Algo de errado aconteceu...!');
    } finally {
      setLoading(false);
    }
  };

  const selecteds = selectedRowKeys?.length;

  const selectedsUser = selectedRowKeysUser.length;

  return (
    <S.Container>
      <PermissionsTitle>Menus e funcionalidades do sistema</PermissionsTitle>

      <div className="container-tables">
        <div className="selecteds">
          <S.SelectedsContainer>
            <p>
              {selecteds}
              {selecteds === 1 ? ' selecionado' : ' selecionados'}
            </p>
            <Button
              disabled={selectedRowKeys?.length === 0 || loading}
              onClick={AddPermissionUser}
            >
              Adicionar ao usuário
            </Button>
          </S.SelectedsContainer>
          <TableColumnsNotAddeds
            appScreens={appScreens}
            handleRowSelecteds={handleRowSelecteds}
            loading={loading}
          />
        </div>

        <div className="selecteds">
          <S.SelectedsContainer>
            <p>
              {selectedsUser}
              {selectedsUser === 1 ? ' selecionado' : ' selecionados'}
            </p>
            <div className="buttons">
              <Button
                disabled={selectedRowKeysUser?.length === 0 || loading}
                onClick={RemovePermissionUser}
              >
                Remover do usuário
              </Button>
            </div>
          </S.SelectedsContainer>
          <TableColumsAddeds
            appScreensUser={appScreensUser}
            handleRowSelectedsUser={handleRowSelectedsUser}
            loading={loading}
          />
        </div>
      </div>
    </S.Container>
  );
};
