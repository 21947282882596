/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import Dropdown from '../Dropdown';
import { appVersion } from '../../config';
import {
  Container,
  Header,
  Aside,
  Main,
  User,
  Logo,
  UserName,
  SideBar,
  SideBarItems,
  SideItemTitle,
} from './style';

interface SideItemsProps {
  title: string;
  key: string;
  icon: React.ReactNode;
  router: string;
}

interface MenuItems {
  name: string;
  action: any;
}

interface Props {
  user: string | null;
  userImage: string;
  sideItems: Array<SideItemsProps>;
  pageContent: React.ReactNode;
  dropdownItems: Array<MenuItems>;
}

const AppBase: React.FC<Props> = ({
  user,
  userImage,
  sideItems,
  pageContent,
  dropdownItems,
}: Props) => {
  return (
    <Container>
      <Header>
        <Logo>
          U4crypto
          <span>{appVersion} </span>
        </Logo>
        <User>
          <UserName>{user}</UserName>
          {/* eslint-disable-next-line */}
          <Avatar src={userImage} size={40} />
          <Dropdown menuItems={dropdownItems} />
        </User>
      </Header>
      <Aside>
        <SideBar mode="inline" defaultSelectedKeys={['home']}>
          {sideItems?.map(items => (
            <SideBarItems key={items.key} icon={items.icon}>
              <Link to={items.router}>
                <SideItemTitle>{items.title}</SideItemTitle>
              </Link>
            </SideBarItems>
          ))}
        </SideBar>
      </Aside>
      <Main>{pageContent}</Main>
    </Container>
  );
};

export default AppBase;
