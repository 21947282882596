import React from 'react';
import { Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { Btn, DropDownItems, DropdownMenu } from './style';

interface MenuItems {
  name: string;
  action: any;
}

interface Props {
  menuItems: Array<MenuItems>;
}

const ComponentDropDown: React.FC<Props> = ({ menuItems }: Props) => {
  const menu = (
    <DropdownMenu>
      {menuItems?.map(items => (
        <DropDownItems key={items.name} onClick={items.action}>
          {items.name}
        </DropDownItems>
      ))}
    </DropdownMenu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
      <Btn ghost icon={<CaretDownOutlined style={{ color: 'white' }} />} />
    </Dropdown>
  );
};

export default ComponentDropDown;
