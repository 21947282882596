/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Button, Card, Modal, Spin } from 'antd';
import {
  FaRegWindowClose,
  FaFileDownload,
  FaAngleUp,
  FaAngleDown,
} from 'react-icons/fa';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';
import PeriodPicker from '../../../../containers/Period-picker';
import {
  getDocumentsExternal,
  exportExternalDocuments
} from '../../../../api/DocumentsService';
import { getPartnersAsOptions } from '../../../../api/PartnerService';
import { formatCnpjCpf } from '../../../../utils/format-documents.js';

import TableFilter from '../../../../containers/Table-filter';
import { normalizeFilters } from '../../../../utils/normalize-filters.js';
import { ActionsContainer, CardContainer, Code } from './style';
import { setContext } from '../../../../store/actions/transactions';
import AppNotification from '../../../../components/notification';

interface Props {
  reduxContext?: any;
  setContext?: any;
}

interface Data {  
  serviceType: {
    desc: string;
  };
  documentDate: Date;
  source: {
    name: string;
    document: {
      value: string;
    };
  };
  partner: {
    name: string;
  };
  id: string;
  customerId: string;
  document: string;
}

interface FilterDropDown {
  setSelectedKeys: () => void;
  selectedKeys: Array<any> | string;
  confirm: () => void;
  clearFilters: () => void;
}

const CustomerDocument: React.FC<Props> = ({
  reduxContext,
  setContext,
}: Props) => {
  const [progressPeding, setProgressPeding] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [totalizersVisibility, setTotalizersVisibility] = useState(true);

  const [data, setData] = useState<Array<Data>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    position: ['topRight' as const],
  });
  const [sorters, setSorters] = useState({});
  const [filters, setFilters] = useState<any>({});
  const [filterPeriod, setFilterPeriod] = useState<string | undefined>('');
  const [filtersNormalized, setFiltersNormalized] = useState({});
  const [walletDetails, setWalletDetails] = useState({
    status: false,
    data: {},
  });
  const [partners, setPartners] = useState([
    { value: null, label: 'carregando...' },
  ]);

  async function fetchPartners(): Promise<void> {
    if (partners.length > 2) {
      return;
    }
    const response = await getPartnersAsOptions();
    setPartners(response.data);
  }

  async function fetchDocuments(dataPeriod: string): Promise<void> {
    setProgressPeding(true);

    setFilterPeriod(dataPeriod);

    const newPagination = {
      current: 1,
      pageSize: 50,
      total: 0,
      position: ['topRight' as const],
    };

    const response = await getDocumentsExternal({
      pagination: newPagination,
      filters: filtersNormalized,
      date: dataPeriod,
    });
    if (response.data.length > 0) {
      setData(response.data);
      setPagination(response.pagination);

      setContext({
        totalizers: response.totalizers,
        total: response.pagination.total,
        period: dataPeriod,
        filters: filtersNormalized
      });
    }
    setProgressPeding(false);
  }

  function getColumnSearchProps(
    dataIndex: string,
    filterType: string,
    filterMode: 'multiple' | 'tags' | undefined,
    filterOptions: Array<any> | undefined,
    FilterSelectOnFocus: any,
    onSearch: any,
  ): any {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropDown) => {
        return (
          <TableFilter
            dataIndex={dataIndex}
            filterType={filterType}
            filterMode={filterMode}
            filterOptions={filterOptions}
            FilterSelectOnFocus={FilterSelectOnFocus}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            onSearch={onSearch}
            clearFilters={clearFilters}
            clearSingleFilter={clearSingleFilter}
          />
        );
      },
    };
  }

  async function handleTableChange(
    paginationParam: any,
    filterParam: any,
    sorterParam: any,
  ): Promise<void> {
    const newFilters: any = {};
    const filterForApi: any = {};

    const convertIntoArray = Object.entries(filterParam);

    convertIntoArray.map(filterValue => {
      if (filterValue[1] !== null) {
        const key = filterValue[0]?.split('_');
        // eslint-disable-next-line prefer-destructuring
        newFilters[key[0]] = filterValue[1];
        filterForApi[key[0]] = normalizeFilters(filterValue);

        if (key.length > 2) {
          if (filterForApi[key[0]] === 'checked') {
            delete filterForApi[key[0]];
            filterForApi[key[2]] = 0;
          } else {
            delete filterForApi[key[2]];
            if (filterForApi[key[0]].length > 0)
              filterForApi[key[0]].splice(2, 1);
          }
        }

        if (isEmpty(filterForApi[key[0]])) {
          delete filterForApi[key[0]];
        }
      }
      return false;
    });

    setFilters(newFilters);

    if (!isEmpty(newFilters)) {
      setProgressPeding(true);
      setFiltersNormalized(filterForApi);

      const response = await getDocumentsExternal({
        pagination: paginationParam,
        filters: newFilters,
        date: filterPeriod,
      });

      setData(response.data);

      setContext({
        totalizers: response.totalizers,
        total: response.pagination.total,
        period: filterPeriod,
        filters: filterForApi
      });
      setProgressPeding(false);
    }

    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPeding(true);
      const response = await getDocumentsExternal({
        pagination: paginationParam,
        filters: filterForApi,
        date: filterPeriod,
      });
      setData(response.data);
      setPagination(response.pagination);
      setProgressPeding(false);
      return;
    }

    if (!isEqual(sorterParam, sorters)) {
      setProgressPeding(true);
      const { columnKey, order } = sorterParam;

      const sorterKey = columnKey?.split('_');
      const sorterOrder = order === 'ascend' ? 'ASC' : 'DESC';
      const newSorter = {
        orderColumn: sorterKey[1],
        orderDirection: sorterOrder,
      };
      setSorters(newSorter);
      const newPagination = {
        current: 1,
        pageSize: paginationParam.pageSize,
        total: paginationParam.total,
        position: ['topRight' as const],
      };
      const response = await getDocumentsExternal({
        pagination: newPagination,
        filters: filterForApi,
        date: filterPeriod,
      });
      setData(response.data);
      setPagination({
        current: 1,
        pageSize: paginationParam.pageSize,
        total: response.pagination.total,
        position: ['topRight' as const],
      });

      setContext({
        totalizers: response.totalizers,
        total: response.pagination.total,
        period: filterPeriod
      });
      setProgressPeding(false);
    }
  }

  async function clearSingleFilter(columnKey: string): Promise<void> {
    setProgressPeding(true);

    const split = columnKey.split('_');
    const filterDelete: any = { ...filtersNormalized };

    if (split.length > 1) {
      delete filterDelete[split[0]];
      delete filterDelete[split[1]];
    } else {
      delete filterDelete[columnKey];
    }

    setFiltersNormalized(filterDelete);

    const response = await getDocumentsExternal({
      pagination,
      filters: filterDelete,
      date: filterPeriod,
    });
    setData(response.data);
    if (isEmpty(filterDelete)) {
      setPagination({
        current: 1,
        pageSize: 50,
        total: response.pagination.total,
        position: ['topRight' as const],
      });
    } else {
      setPagination(response.pagination);
    }

    setContext({
      totalizers: response.totalizers,
      total: response.pagination.total,
      period: filterPeriod,
      filters: filterDelete
    });
    setProgressPeding(false);
  }

  async function clearFilters(): Promise<void> {
    setProgressPeding(true);
    setFilters({});
    setFiltersNormalized({});
    const response = await getDocumentsExternal({
      pagination,
      filters: {},
      date: filterPeriod,
    });
    setData(response.data);
    setPagination({
      current: 1,
      pageSize: 50,
      total: response.pagination.total,
      position: ['topRight' as const],
    });

    setContext({
      totalizers: response.totalizers,
      total: response.pagination.total,
      period: filterPeriod,
      filters: {}
    });
    setProgressPeding(false);
  }

  async function handleExportTransactions(): Promise<void> {
    setExportLoading(true);

    const response = await exportExternalDocuments({
      filters: filtersNormalized,
      date: filterPeriod,
    });

    if (response.status && response.email === true) {
      AppNotification('success', response.message);
    }

    if (response.status && response.email === false) {
      window.location.href = response.url;
      AppNotification('success', response.message);
    }

    if (response.status === false) {
      AppNotification('error', response.message);
    }

    setExportLoading(false);
  }
  const columns = [
    {
      title: 'ID',
      key: 'id',
      name: 'id',
      dataIndex: ['id'],         
    },
    {
      title: 'Tipo de Serviço',
      key: 'serviceType',
      name: 'Tipo de serviço',
      dataIndex: ['serviceType'],         
    },
    {
      render: (render: Data) =>
        moment(render.documentDate).utc().format('DD-MM-YYYY HH:mm:ss'),
      title: 'Data de Criação',
      key: 'date_orderTransactionDate',
      filteredValue: filters.date || null,
      sorter: true,
    }, 
    {
      title: 'Nome origem',
      dataIndex: ['source', 'name'],
      key: 'sourceCustomerName_orderSourceCustomerName',
    },
    {
      render: (render: Data) => formatCnpjCpf(render.source.document.value),
      title: 'Documento origem',
      key: 'sourceCustomer',
      filteredValue: filters.sourceCustomer || null,
      ...getColumnSearchProps(
        'sourceCustomer',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),
    },  
    {
      title: 'Tipo de Documento',
      dataIndex: 'documentType',
      key: 'sourceCustomerName_orderSourceCustomerName',
    },
    {
      title: 'Parceiro',
      name: 'Parceiro',
      dataIndex: ['partner', 'name'],
      key: 'partner_orderPartnerName',
      filteredValue: filters.partner || null,
      align: 'center' as const,
      ...getColumnSearchProps(
        'partner',
        'select',
        'multiple',
        partners,
        fetchPartners,
        undefined,
      ),
    },
    {
      title: 'ID do Usuário',
      key: 'customerId',
      name: 'customerId',
      dataIndex: 'customerId',
      filteredValue: filters.customerId || null,
      ...getColumnSearchProps(
        'customerId',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),         
    },
  ];

  const cards = [
    {
      colGrid: 4,
      title: 'Total de Documentos Validados',
      content: reduxContext?.total,
      router: 'total_revenue',
    },
  ];

  return (
    <>
      <Spin spinning={exportLoading}>
        <PeriodPicker bringTransactions={fetchDocuments} />
        {filterPeriod && (
          <div>
            <ActionsContainer>
              <Button
                icon={
                  <FaRegWindowClose
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={clearFilters}
              >
                Limpar todos os filtros
              </Button>
              <Button
                icon={
                  <FaFileDownload
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={handleExportTransactions}
              >
                Exportar dados
              </Button>
              <Button
                icon={
                  totalizersVisibility ? (
                    <FaAngleUp style={{ marginRight: '10px' }} />
                  ) : (
                    <FaAngleDown style={{ marginRight: '10px' }} />
                  )
                }
                onClick={() => setTotalizersVisibility(!totalizersVisibility)}
              >
                {totalizersVisibility
                  ? 'Esconder Totalizadores'
                  : 'Mostrar Totalizadores'}
              </Button>
            </ActionsContainer>
            {totalizersVisibility && (
              <CardContainer>
                {cards.map(card => (
                  <Card title={card.title}>{card.content}</Card>
                ))}
              </CardContainer>
            )}
            <Table
              dataSource={data}
              columns={columns}
              onChange={handleTableChange}
              pagination={pagination}
              sortDirections={['ascend', 'descend', 'ascend']}
              bordered
              loading={progressPeding}
            />
            <Modal
              onCancel={() =>
                setWalletDetails(prev => {
                  return { ...prev, status: false };
                })
              }
              cancelText="Sair"
              onOk={() =>
                setWalletDetails(prev => {
                  return { ...prev, status: false };
                })
              }
              visible={walletDetails.status}
            >
              <Code>{walletDetails.data}</Code>
            </Modal>
          </div>
        )}
      </Spin>
    </>
  );
};

const mapStateToProps = (state: any): any => ({
  reduxContext: state.Transactions.context,
});

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ setContext }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDocument);
