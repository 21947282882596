import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    font-family: Raleway, sans-serif;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
  }


  .ant-notification-close-icon {
    color: #fff !important;
  }

  .ant-table-content{
  transform: rotateX(180deg);
  overflow-x: auto !important;


 > table{
  transform: rotateX(180deg);
 }
  }

`;
