import { conciliation } from './config/consts';
import { httpPost } from '../services/http/index.js';

export async function getClousure(context: any, params: any): Promise<any> {
  try {
    const { initDate, endDate, partnerIds } = params;

    const data = {
      initDate,
      endDate,
      partnerIds,
    };

    const request = await Promise.all(
      context.map(async (cont: any) => {
        const response = await httpPost(
          `${conciliation}/v1beta1/transaction/${cont}/closure`,
          data,
        );
        return response;
      }),
    );

    return request;
  } catch (e) {
    return false;
  }
}
