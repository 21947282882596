import styled from 'styled-components';
import { PageHeader } from 'antd';
import { black, secundaryColor } from '../../config';

export const Header = styled(PageHeader)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 40px;

  > .ant-page-header-content {
    padding-top: 12px;
    color: ${black};
    font-size: 11pt;
  }
`;

export const Styles = styled.div`
  .ant-page-header-heading-title {
    margin-right: 12px;
    margin-bottom: 0;
    color: ${black};
    font-weight: inherit !important;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-page-header-back-button:hover {
    color: ${secundaryColor} !important;
  }
`;
