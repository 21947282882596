interface payload {
  type: string;
  totalizers: any;
  total: number;
  filters: any;
  period: string | undefined;
  dashboardVisibility: boolean;
}

export const setContext = ({
  type,
  totalizers,
  total,
  period,
  filters,
  dashboardVisibility,
}: payload) => {
  return (dispatch: any) => {
    return [
      dispatch({
        type: 'SET_CONTEXT',
        payload: {
          type,
          totalizers,
          total,
          period,
          filters,
          dashboardVisibility,
        },
      }),
    ];
  };
};
