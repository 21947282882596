import { httpGet, httpPost } from '../services/http/index.js';
import { account } from './config/consts';

type PayloadAddPermission = {
  app_screen_id: string;
};
export interface AddPermissionPayload {
  data: PayloadAddPermission[];
}

type PayloadRemovePermission = {
  app_screen_id: string;
};
export interface RemovePermissionsPayload {
  data: PayloadRemovePermission[];
}

type PayloadChangePermission = {
  app_screen_id: string;
};
export interface ChangePermissionPayload {
  data: PayloadChangePermission[];
}

export const getAppScreens = async (id: string): Promise<any> => {
  const endpoint = `${account}/v1beta1/app-screens/${id}`;

  try {
    const response = await httpGet(endpoint);
    return { data: response.data };
  } catch (err) {
    return { data: [] };
  }
};

export const getAppScreensUser = async (id: string): Promise<any> => {
  const endpoint = `${account}/v1beta1/app-screens-by-user/${id}`;

  try {
    const response = await httpGet(endpoint);
    return { data: response.data };
  } catch {
    return { data: [] };
  }
};

export const addPermissionUser = async (
  id: string,
  payload: AddPermissionPayload,
): Promise<void> => {
  const endpoint = `${account}/v1beta1/add-permission/${id}`;

  try {
    await httpPost(endpoint, payload);
  } catch {
    throw new Error();
  }
};

export const removePermissionUser = async (
  id: string,
  payload: RemovePermissionsPayload,
): Promise<void> => {
  const endpoint = `${account}/v1beta1/remove-permission/${id}`;

  try {
    await httpPost(endpoint, payload);
  } catch {
    throw new Error();
  }
};

export const changePermissionUser = async (
  id: string,
  payload: ChangePermissionPayload,
): Promise<void> => {
  const endpoint = `${account}/v1beta1/change-permission/${id}`;

  try {
    await httpPost(endpoint, payload);
  } catch {
    throw new Error();
  }
};
