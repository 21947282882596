export function translateTransactionsType(transactions = null) {
  let transactionTranslated = [];

  const translations = [
    {
      id: 0,
      wallet_movement_type: "cashin from creditcard",
      transalation: "Deposito cartão de credito",
    },
    {
      id: 1,
      wallet_movement_type: "transfer from wallet",
      transalation: "Transferencia Saida Wallet",
    },
    {
      id: 2,
      wallet_movement_type: "transfer to wallet",
      transalation: "Transferencia Entrada Wallet",
    },
    {
      id: 3,
      wallet_movement_type: "transfer account to wallet",
      transalation: "Transferencia de Conta Para Wallet",
    },
    {
      id: 4,
      wallet_movement_type: "transfer wallet to account",
      transalation: "TED",
    },
    {
      id: 5,
      wallet_movement_type: "wallet creation",
      transalation: "Wallet Criada",
    },
    {
      id: 6,
      wallet_movement_type: "cashback-redemption",
      transalation: "Resgate CashBack",
    },
    {
      id: 7,
      wallet_movement_type: "cashin from billet",
      transalation: "Boleto Deposito",
    },
    {
      id: 8,
      wallet_movement_type: "financial adjustment",
      transalation: "Ajuste Financeiro",
    },
    {
      id: 9,
      wallet_movement_type: "cashback credit",
      transalation: "Crédito Cashback",
    },
    {
      id: 10,
      wallet_movement_type: "billet split transfer to wallet",
      transalation: "Split Boleto Cobrança - Saida",
    },
    {
      id: 11,
      wallet_movement_type: "billet split transfer from wallet",
      transalation: "Split Boleto Cobrança - Entrada",
    },
    {
      id: 12,
      wallet_movement_type: "creditcard cash in split transfer from wallet",
      transalation: "Split Cartão de Crédito - Entrada",
    },
    {
      id: 13,
      wallet_movement_type: "creditcard cash in split transfer to wallet",
      transalation: "Split Cartão de Crédito - Saida",
    },
    {
      id: 14,
      wallet_movement_type: "tax charge",
      transalation: "Tarifas",
    },
    {
      id: 15,
      wallet_movement_type: "tax charge chargeback",
      transalation: "Estorno Tarifa de Boleto",
    },
    {
      id: 16,
      wallet_movement_type: "card transaction",
      transalation: "Compra Cartão Interno",
    },
    {
      id: 17,
      wallet_movement_type: "donation transfer",
      transalation: "Doação",
    },
    {
      id: 20,
      wallet_movement_type: "bank transfer reversal",
      transalation: "Estorno TED"
    },
    {
      id: 21,
      wallet_movement_type: "bill payment",
      transalation: "Pagamento de Boleto"
    },
    {
      id: 27,
      wallet_movement_type: "card request",
      transalation: "Requisição de cartões internos"
    },
    {
      id: 29,
      wallet_movement_type: "card withdraw",
      transalation: "Saque Cartão Interno",
    },
    {
      id: 31,
      wallet_movement_type: "monthly charge",
      transalation: "Cobrança de Mensalidade"
    },
    {
      id: 57,
      wallet_movement_type: "expired billet",
      transalation: "Boletos vencidos",
    },
    {
      id: 52,
      wallet_movement_type: "card purchase reversal",
      transalation: "Estorno compra cartão",
    },
    {
      id: 53,
      wallet_movement_type: "card withdraw reversal",
      transalation: "Estorno saque cartão",
    },
    {
      id: 60,
      wallet_movement_type: "ticketTransfer",
      transalation: "Crédito cupom de transferência",
    },
    {
      id: 63,
      wallet_movement_type: "pix transfer from wallet",
      transalation: "Envio PIX",
    },
    {
      id: 64,
      wallet_movement_type: "pix transfer to wallet",
      transalation: "Recebimento PIX",
    },
    {
      id: 100,
      wallet_movement_type: "register billet",
      transalation: "Registro Boleto Cobrança - Entrada",
    },
    {
      id: 101,
      wallet_movement_type: "cancelled billet",
      transalation: "Cancelamento Boleto Cobrança - Entrada",
    },
    {
      id: 102,
      wallet_movement_type: "billet paid by pix",
      transalation: "Boleto Pago por Pix",
    }
  ];

  if (transactions === null) {
    return { value: null, label: null };
  }

  if (Array.isArray(transactions)) {
    transactions.map((array) => {
      return translations.map((translation) => {
        if (array.desc === translation.wallet_movement_type) {
          return transactionTranslated.push({
            value: array.id,
            label: translation.transalation,
            key: translation.wallet_movement_type,
          });
        }
        return false;
      });
    });
  } else {
    const result = translations.find(
      (searchKey) => searchKey.wallet_movement_type === transactions
    );
    transactionTranslated = result?.transalation;
  }

  return transactionTranslated;
}

export function translateTransactionsTypeAsOptions(transactions = null) {
  let transactionTranslated = [];

  const translations =  [
    {
      id: 0,
      wallet_movement_type: "cashin from creditcard",
      transalation: "Deposito cartão de credito",
    },
    {
      id: 1,
      wallet_movement_type: "transfer from wallet",
      transalation: "Transferencia Saida Wallet",
    },
    {
      id: 2,
      wallet_movement_type: "transfer to wallet",
      transalation: "Transferencia Entrada Wallet",
    },
    {
      id: 3,
      wallet_movement_type: "transfer account to wallet",
      transalation: "Transferencia de Conta Para Wallet",
    },
    {
      id: 4,
      wallet_movement_type: "transfer wallet to account",
      transalation: "TED",
    },
    {
      id: 5,
      wallet_movement_type: "wallet creation",
      transalation: "Wallet Criada",
    },
    {
      id: 6,
      wallet_movement_type: "cashback-redemption",
      transalation: "Resgate CashBack",
    },
    {
      id: 7,
      wallet_movement_type: "cashin from billet",
      transalation: "Boleto Deposito",
    },
    {
      id: 8,
      wallet_movement_type: "financial adjustment",
      transalation: "Ajuste Financeiro",
    },
    {
      id: 9,
      wallet_movement_type: "cashback credit",
      transalation: "Crédito Cashback",
    },
    {
      id: 10,
      wallet_movement_type: "billet split transfer to wallet",
      transalation: "Split Boleto Cobrança - Saida",
    },
    {
      id: 11,
      wallet_movement_type: "billet split transfer from wallet",
      transalation: "Split Boleto Cobrança - Entrada",
    },
    {
      id: 12,
      wallet_movement_type: "creditcard cash in split transfer from wallet",
      transalation: "Split Cartão de Crédito - Entrada",
    },
    {
      id: 13,
      wallet_movement_type: "creditcard cash in split transfer to wallet",
      transalation: "Split Cartão de Crédito - Saida",
    },
    {
      id: 14,
      wallet_movement_type: "tax charge",
      transalation: "Tarifas",
    },
    {
      id: 15,
      wallet_movement_type: "tax charge chargeback",
      transalation: "Estorno Tarifa de Boleto",
    },
    {
      id: 16,
      wallet_movement_type: "card transaction",
      transalation: "Compra Cartão Interno",
    },
    {
      id: 17,
      wallet_movement_type: "donation transfer",
      transalation: "Doação",
    },
    {
      id: 21,
      wallet_movement_type: "bill payment",
      transalation: "Pagamento de Boleto"
    },
    {
      id: 27,
      wallet_movement_type: "card request",
      transalation: "Requisição de cartões internos"
    },
    {
      id: 29,
      wallet_movement_type: "card withdraw",
      transalation: "Saque Cartão Interno",
    },
    {
      id: 57,
      wallet_movement_type: "expired billet",
      transalation: "Boletos vencidos",
    },
    {
      id: 52,
      wallet_movement_type: "card purchase reversal",
      transalation: "Estorno compra cartão",
    },
    {
      id: 53,
      wallet_movement_type: "card withdraw reversal",
      transalation: "Estorno saque cartão",
    },
    {
      id: 60,
      wallet_movement_type: "ticketTransfer",
      transalation: "Crédito cupom de transferência",
    },
    {
      id: 63,
      wallet_movement_type: "pix transfer from wallet",
      transalation: "Envio PIX",
    },
    {
      id: 64,
      wallet_movement_type: "pix transfer to wallet",
      transalation: "Recebimento PIX",
    },
    {
      id: 100,
      wallet_movement_type: "register billet",
      transalation: "Registro Boleto Cobrança - Entrada",
    },
    {
      id: 101,
      wallet_movement_type: "cancelled billet",
      transalation: "Cancelamento Boleto Cobrança - Entrada",
    },
    {
      id: 102,
      wallet_movement_type: "billet paid by pix",
      transalation: "Boleto Pago por Pix",
    }
  ];

  if (transactions === null) {
    return { value: null, label: null };
  }

  if (Array.isArray(transactions)) {
    transactions.map((array) => {
      return translations.map((translation) => {
        if (array.transactionType === translation.wallet_movement_type) {

          return transactionTranslated.push({
            name: translation.transalation,
            document: translation.transalation,
            totalizers: array.totalizers,
            transactionsCount: array.transactionsCount
          });
        }
        return false;
      });
    });
  } else {
    const result = translations.find(
      (searchKey) => searchKey.wallet_movement_type === transactions
    );
    transactionTranslated = result?.transalation;
  }

  return transactionTranslated;
}
