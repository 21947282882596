import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button } from 'antd';
import { FaFileDownload } from 'react-icons/fa';
import Header from '../../components/Header';
// import {formatCurrency} from '../../utils/format-currency.js';
// import {formatCnpjCpf} from '../../utils/format-documents.js';
// import  moment from 'moment';

export const Billing: React.FC = () => {
  const history = useHistory();
  const [loading /* setLoading */] = useState(false);
  const [pagination /* setPagination */] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const columns = [
    {
      title: 'CNPJ Parceiro',
      render: (render: any) => null,
    },
    {
      title: 'CNPJ Cliente',
      render: (render: any) => null,
    },
    {
      title: 'Produto',
      render: (render: any) => null,
    },
    {
      title: 'Valor Custo',
      render: (render: any) => null,
    },
    {
      title: 'Valor Cobrado',
      render: (render: any) => null,
    },
    {
      title: 'Data da Transação',
      render: (render: any) => null,
    },
  ];

  async function handleExportTransactions(): Promise<any> {
    return false;
    // setExportLoading(true);
    // const response = await exportTransactions({
    //   transactionContext: context,
    //   sorter: sorters,
    //   filters: filtersNormalized,
    //   date: filterPeriod,
    // });
    // if (response.status && response.email === true) {
    //   AppNotification('success', response.message);
    // }
    // if (response.status && response.email === false) {
    //   window.location.href = response.url;
    //   AppNotification('success', response.message);
    // }
    // if (response.status === false) {
    //   AppNotification('error', response.message);
    // }
    // setExportLoading(false);
  }
  return (
    <>
      <Header
        title="Faturamento - Shankya"
        onBack={() => history.push('/home')}
      />
      <Button
        icon={
          <FaFileDownload color="#4C2D6E" style={{ marginRight: '10px' }} />
        }
        onClick={handleExportTransactions}
      >
        Exportar dados
      </Button>
      <br />
      <br />
      <Table
        dataSource={[]}
        columns={columns}
        // onChange={handleTableChange}
        pagination={pagination}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </>
  );
};

export default Billing;
