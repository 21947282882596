import styled from 'styled-components';

export const PermissionsContainer = styled.div`
  display: flex;

  .ant-table-wrapper {
    width: 50vw;
    padding-right: 20px;
  }
`;

export const PermissionsTitle = styled.p`
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SelectedContainer = styled.div`
  background-color: #aaa3ad;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-left: 1rem;
  }

  button {
    margin-right: 1rem;
    text-align: center;
  }
`;

export const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50.5%;
`;

export const SelectedPartnersContainer = styled.div`
  background-color: #aaa3ad;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;

  p {
    margin-left: 1rem;
  }

  button {
    margin-right: 1rem;
    text-align: center;
  }
`;
