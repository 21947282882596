import * as pack from '../package.json';

const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const primaryColor = process.env.REACT_APP_PRIMARY;
const secundaryColor = process.env.REACT_APP_SECUNDARY;
const white = process.env.REACT_APP_WHITE;
const black = process.env.REACT_APP_BLACK;
const grey = process.env.REACT_APP_GREY;
const softGrey = process.env.REACT_APP_SOFT_GREY;
const softerGrey = process.env.REACT_APP_SOFTER_GREY;
const hoverGrey = process.env.REACT_APP_GREY_HOVER;
const greenAproves = process.env.REACT_APP_APPROVES;
const redDisapprove = process.env.REACT_APP_TRINIDAD;
const blue = process.env.REACT_APP_PRIMARY_BLUE;
const appVersion = pack.version;

export {
  baseUrl,
  apiKey,
  primaryColor,
  secundaryColor,
  white,
  black,
  grey,
  softGrey,
  softerGrey,
  hoverGrey,
  greenAproves,
  redDisapprove,
  blue,
  appVersion,
};
