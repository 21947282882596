import { Redirect, Route, RouteProps } from 'react-router-dom';

import React from 'react';
import cookies from 'js-cookie';
import AppLayout from '../containers/App-layout';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const isAuthenticated = (): boolean => {
  const token = cookies.get('token');

  if (token) {
    return true;
  }

  return false;
};

const PrivateRoute: React.FC<PrivateRouteProps> = (
  props: PrivateRouteProps,
) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={routeProps => {
        return isAuthenticated() ? (
          <AppLayout>
            <Component {...routeProps} />
          </AppLayout>
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default PrivateRoute;
