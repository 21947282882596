import { Spin } from 'antd';
import Cookies from 'js-cookie';
import React, { lazy, Suspense, useState } from 'react';
import { GetPartnersProps } from '../../../../api/BalanceConsultService.js';
import { formatCurrencyIntl } from '../../../../utils/format-currency-Intl.js';
import { formatCurrency } from '../../../../utils/format-currency.js';
import * as S from '../style';

const ModalPayment = lazy(() => {
  return import('./modalPayment').then(({ ModalPayment }) => ({
    default: ModalPayment,
  }));
});

export interface CardProps {
  title: string;
  type: 'pix' | 'arranjo' | 'cartao';
  partnersBalance?: GetPartnersProps;
  partnersPix?: GetPartnersProps;
  partnersArrangement?: GetPartnersProps;
  loading: boolean;
}

const permissions = Cookies.get('userScreens');
const userScreens = permissions ? JSON.parse(permissions) : [''];

export const Card: React.FC<CardProps> = ({
  title,
  type,
  partnersBalance,
  partnersPix,
  partnersArrangement,
  loading,
}: CardProps) => {
  const [openModal, setOpenModal] = useState(false);

  const openModalPayment = (): void => {
    setOpenModal(true);
  };

  const partnerId =
    partnersBalance !== undefined &&
    (partnersBalance.content.length > 0
      ? partnersBalance?.content[0].partner.id
      : '');

  return (
    <S.Container>
      <div className="card-container">
        <Spin spinning={loading}>
          <span>{title}</span>
          {type === 'arranjo' && (
            <>
              <h5>{formatCurrency(partnersArrangement?.totalizers.value)}</h5>
              {partnersArrangement?.content.map(item => (
                <div className="partners" key={item.id}>
                  <span>
                    {item.balance < Number(item.minvalue) ? (
                      <p className="red" />
                    ) : (
                      <p className="green" />
                    )}
                    {item.partner.name}
                  </span>
                  <span>{formatCurrency(item.balance)}</span>
                </div>
              ))}
            </>
          )}
          {type === 'cartao' && (
            <>
              <h5>{formatCurrency(partnersBalance?.totalizers.value)}</h5>
              {partnersBalance?.content.map(item => (
                <div className="partners" key={item.id}>
                  <span>
                    {item.cardbalance < Number(item.minvalue) ? (
                      <p className="red" />
                    ) : (
                      <p className="green" />
                    )}
                    {item.partner.name}
                  </span>
                  <span>{formatCurrency(Math.abs(item.cardbalance))}</span>
                </div>
              ))}
              {userScreens.includes('permission_receipt_payment_button') && (
                <div className="payment">
                  <S.ComprovarPagamento onClick={openModalPayment}>
                    Comprovar Pagamento
                  </S.ComprovarPagamento>
                </div>
              )}
              <Suspense fallback={<Spin />}>
                {openModal && (
                  <ModalPayment
                    visible={openModal}
                    onCancel={() => setOpenModal(false)}
                    partnerid={partnerId || ''}
                    updateTable={() => null}
                  />
                )}
              </Suspense>
            </>
          )}
          {type === 'pix' && (
            <>
              <h5>{formatCurrency(partnersPix?.totalizers.value)}</h5>
              {partnersPix?.content.map(item => (
                <div className="partners" key={item.id}>
                  <span>
                    {item.pixbalance < Number(item.minvalue) ? (
                      <p className="red" />
                    ) : (
                      <p className="green" />
                    )}
                    {item.partner.name}
                  </span>
                  <span>{formatCurrency(Math.abs(item.pixbalance))}</span>
                </div>
              ))}
              <div className="value-transaction">
                <span>
                  A média de valor transicionado nos ultimos 3 meses neste
                  período é&nbsp;
                  <strong>
                    {formatCurrencyIntl(partnersPix?.totalizers.averageExpense)}
                  </strong>
                </span>
              </div>
            </>
          )}
        </Spin>
      </div>
    </S.Container>
  );
};
