import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import NavTab from '../../../components/NavTab';
import BppStatement from '../Bpp-Statement/components/bpp-statement';
import { BalanceArrangements } from './components/balance';
import * as S from './style';

const permissions = cookies.get('userScreens');
const userScreens = permissions ? JSON.parse(permissions) : [''];

export const BalanceConsult: React.FC = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    userScreens.includes('arrangements_balance')
      ? 'arrangements_balance'
      : 'bbp-statement',
  );

  const [tabs, setTabs] = useState([
    {
      title: 'Extrato BBP',
      key: 'bbp-statement',
    },
    {
      title: 'Saldos do Arranjo',
      key: 'arrangements_balance',
    },
  ]);

  useEffect(() => {
    const tabsFilter: string[] = [];
    if (!userScreens.includes('arrangements_balance')) {
      tabsFilter.push('arrangements_balance');
    }
    if (!userScreens.includes('extract')) {
      tabsFilter.push('bbp-statement');
    }
    const filtereds = tabs.filter(f => !tabsFilter.find(t => t === f.key));
    setTabs([...filtereds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTab = (activeTabParam: string): React.ReactNode => {
    switch (activeTabParam) {
      case 'arrangements_balance':
        return <BalanceArrangements />;
      case 'bbp-statement':
        return <BppStatement />;
      default:
        return <h5>404 not found</h5>;
    }
  };

  return (
    <S.Container>
      <Header
        title="Conciliação - Consultar Saldos"
        onBack={() => history.push('/conciliation/options')}
      />
      <NavTab tabs={tabs} selected={activeTab} handleTab={setActiveTab} />
      {renderTab(activeTab)}
    </S.Container>
  );
};
