export function translateFilters(value) {
  if (!value) {
    return "";
  }

  const filters_transalations = [
    {
      transalation: "data",
      key: "date",
    },
    {
      transalation: "periodo",
      key: "period",
    },
    {
      transalation: "tipo de transação",
      key: "type",
    },
    {
      transalation: "documento origem",
      key: "sourceCustomer",
    },
    {
      transalation: "documento destino",
      key: "targetCustomer",
    },
    {
      transalation: "parceito origem",
      key: "sourcePartner",
    },
    {
      transalation: "parceito destino",
      key: "TargetPartner",
    },
    {
      transalation: "parceiro",
      key: "partner",
    },
    {
      transalation: "instituição financeira",
      key: "targetBak",
    },
    {
      transalation: "valor transacionado",
      key: "valueBetween",
    },
    {
      transalation: "tarifa",
      key: "chargedTariffBetween",
    },
    {
      transalation: "status conciliação",
      key: "conciliationStatus",
    },
    {
      transalation: "status transação",
      key: "transactionStatus",
    },
    {
      transalation: "custo",
      key: "costBetween",
    },
    {
      transalation: "receita",
      key: "revenueBetween",
    },
    {
      transalation: "id",
      key: "id"
    },
    {
      transalation: "Instituição Financeira",
      key: "targetBank"
    }
  ];

  const result = filters_transalations.find((array) => value === array.key);

  if(result){
    return result?.transalation;
  }
   return "";
}
