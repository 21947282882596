import styled from 'styled-components';
import { softerGrey, primaryColor } from '../../config';

export const Container = styled.div`
  border-bottom: 1px solid ${softerGrey};
  padding-bottom: 3rem;
`;

export const SubtitleContainer = styled.div`
  margin-top: 3rem;
`;

export const TitleContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: 700;
  font-size: 18px;
`;

export const ChartsContainer = styled.div`
  margin-top: 1rem;
`;
export const AditionalContent = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

export const MainContent = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 16px;
  font-weight: 700;
  color: ${primaryColor};
`;

export const TotalizersContainer = styled.div`
  display: flex;
  margin-bottom: 40px;

  .ant-card {
    width: 25%;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    box-shadow: 0px 10px 10px -14px rgba(0, 0, 0, 0.35);
    margin-right: 20px;
    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      font-weight: 700;
      color: ${primaryColor};
    }
    .ant-card-body {
      text-align: right;
    }
  }
`;
