import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaUser, FaElementor, FaMousePointer } from 'react-icons/fa';
import Header from '../../../components/Header';
import Card from '../../../components/Card';
import { primaryColor } from '../../../config';

const PermissionOptions: React.FC = () => {
  const history = useHistory();

  const cards = [
    {
      colGrid: 6,
      title: 'Usuários',
      content: <FaUser size={40} color={primaryColor} />,
      router: '/permissions/options/users',
    },
    {
      colGrid: 6,
      title: 'Menus',
      content: <FaElementor size={40} color={primaryColor} />,
      router: '/permissions/options/menus',
    },
    {
      colGrid: 6,
      title: 'Funcionalidades',
      content: <FaMousePointer size={40} color={primaryColor} />,
      router: '/permissions/options/funcs',
    },
  ];

  function handleRouter(url: string): void {
    history.push(url);
  }

  return (
    <>
      <Header
        title="Opções - Permissionamento"
        onBack={() => history.push('/home')}
      />
      <Card handleClick={handleRouter} spaceBetween={[16, 32]} cards={cards} />
    </>
  );
};

export default PermissionOptions;
