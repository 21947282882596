import React, { useState } from 'react';
import moment from 'moment';
import { Select, Form, DatePicker, Button } from 'antd';
import { Container } from './style';

interface Props {
  bringTransactions: any;
}

const PeriodPicker: React.FC<Props> = ({ bringTransactions }: Props) => {
  const { RangePicker } = DatePicker;
  const [dataPickerStatus, setDataPickerStatus] = useState(false);
  const periodOptions = [
    { value: 0, label: 'Hoje' },
    { value: 3, label: 'Últimos 3 dias' },
    { value: 7, label: 'Últimos 7 dias' },
    { value: 15, label: 'Últimos 15 dias' },
    { value: 30, label: 'Últimos 30 dias' },
    { value: 5, label: 'Data específica' },
  ];

  async function handleChange(value: number): Promise<void> {
    const filter = [];
    setDataPickerStatus(false);

    if (value === 5) {
      setDataPickerStatus(true);
    } else {
      const current = moment().format('YYYY-MM-DD HH:mm:ss');

      const subtracted = moment(current).subtract(value, 'days');

      const initialDate = moment(subtracted)
        .utc()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
      filter[0] = initialDate.format('YYYY-MM-DD HH:mm:ss');

      const finatlDate = moment(current)
        .utc()
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(0);
      filter[1] = finatlDate.format('YYYY-MM-DD HH:mm:ss');

      if (value === 0) {
        const normalized = `&date=${filter[0]}`;

        bringTransactions(normalized);
      } else {
        const normalized = `&period=${JSON.stringify(filter)}`;

        bringTransactions(normalized);
      }
    }
  }

  async function handleSpecificDate(value: any): Promise<void> {
    const filter = [];

    if (value.rangeData[0] !== undefined && value.rangeData[0] !== null) {
      filter[0] = moment(value.rangeData[0]._d).format('YYYY-MM-DD HH:mm:ss');
    }

    if (value.rangeData[1] !== undefined && value.rangeData[1] !== null) {
      filter[1] = moment(value.rangeData[1]._d).format('YYYY-MM-DD HH:mm:ss');
    }

    if (filter.length > 1) {
      bringTransactions(`&period=${JSON.stringify(filter)}`);
    } else {
      bringTransactions(`&date=${filter[0]}`);
    }
  }

  return (
    <>
      <Container>
        <p>Selecione um período que deseja visualizar as transações.</p>
        <Select
          placeholder="Data"
          onChange={handleChange}
          style={{ width: 200 }}
        >
          {periodOptions.map(option => (
            <Select.Option value={option.value} key={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
        {dataPickerStatus && (
          <Form
            name="rangePicker"
            onFinish={handleSpecificDate}
            onFinishFailed={errorInfo => console.log(errorInfo)}
          >
            <Form.Item label="Selecione uma data" name="rangeData">
              <RangePicker
                format="DD-MM-YYYY HH:mm:ss"
                showTime={{
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('00:00:00', 'HH:mm:ss'),
                  ],
                }}
                placeholder={['Data Inicial', 'Data Final']}
                allowClear
                allowEmpty={[false, true]}
              />
            </Form.Item>
            <Button shape="round" type="ghost" size="middle" htmlType="submit">
              Visualizar Transações
            </Button>
          </Form>
        )}
      </Container>
    </>
  );
};

export default PeriodPicker;
