import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Col, Row, Spin } from 'antd';
import Header from '../../components/Header';
import { Title } from './style';
import appNotification from '../../components/notification';
import { userChangePassword } from '../../api/UserService';

const UserAccount: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const regexPasswordRules = RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
  );
  const initialValues = {
    currentPassoword: '',
    password: '',
    confirmPassword: '',
  };

  const compareToFirstPassword = (
    rule: any,
    value: any,
    callback: any,
  ): any => {
    if (value && value !== form.getFieldValue('password')) {
      callback('- A confirmação é diferente da nova senha.');
    } else {
      callback();
    }
  };

  const passwordRules = (rule: any, value: any, callback: any): any => {
    if (value && !value.match(regexPasswordRules)) {
      callback(
        '- A senha deve conter pelo menos, uma letra maiúscula, uma letra minuscula, um caractere especial e um numero.',
      );
    } else {
      callback();
    }
  };

  async function handleChangePassword(values: any): Promise<any> {
    setLoading(true);
    const response = await userChangePassword(values);

    if (response.status) {
      form.resetFields();
      setLoading(false);
      return appNotification('success', response.message);
    }
    setLoading(false);
    return appNotification('error', response.message);
  }

  return (
    <>
      <Header title="Minha Conta" onBack={() => history.push('/home')} />
      <Title>Alterar Senha</Title>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="change-password"
          initialValues={initialValues}
          onFinish={handleChangePassword}
          onFinishFailed={errorInfo => console.log(errorInfo)}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Senha atual"
                name="currentPassoword"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, preencha a senha atual.',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Nova senha"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, preencha a  nova senha.',
                  },
                  {
                    min: 8,
                    message: '- A senha deve conter no minimo 8 digitos.',
                  },
                  {
                    max: 16,
                    message: '- A senha deve conter no maximo 16 digitos.',
                  },
                  {
                    validator: passwordRules,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Confirmação"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, preencha a confirmação de senha.',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Button htmlType="submit">Salvar</Button>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default UserAccount;
