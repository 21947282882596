import { isEmpty } from 'lodash';
import { httpGet, httpPost } from '../services/http/index.js';
import { conciliation } from './config/consts';
import cookies from 'js-cookie';

interface Pagination {
    current: number;
    pageSize: number;
    total: number;
    position: any;
}

interface GetBppStatements {
    pagination: Pagination;
    filters: any | undefined;
    date: any;
}

interface BppStatementResponse {
    totalizers: number;
    data: Array<any>;
    pagination: Pagination;
}

interface ExportResponse {
  status: boolean;
  url: string;
  email: boolean;
  message: string;
}

interface ExportBppStatements {
  filters: any;
  date: any;
}

export async function getStatement(pagination: any): Promise<any> {
  try {
    const array: any = [];
    const { current, pageSize } = pagination;
    console.log(array);
    const response = await httpGet(
      `${conciliation}/v1beta1/bank?page=${current - 1}&take=${pageSize}`,
    );

    return {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
    };
  } catch (e) {
    console.log(e);
    return [{ value: null, label: null }];
  }
}

export const getBppStatements = async ({
    pagination,
    filters,
    date}: GetBppStatements): Promise<BppStatementResponse> => {
    try {
      let url_filter = '';
  
      if (!isEmpty(filters)) {
        for (const key in filters){
          url_filter += `&${key}=${JSON.stringify(filters[key])}`;
        }
      }
      const { current, pageSize } = pagination;
      const response = await httpGet(
        `${conciliation}/v1beta1/bpp-statement?take=${pageSize}&page=${
          current - 1}${date}${url_filter}`,
      );
  
      const payload = {
        data: response.data.content,
        pagination: {
          current,
          pageSize,
          total: response.data.size,
          position: ['topRight' as const],
        },
        totalizers: response.data.totalizers,
      };
      /*const payload = {
        data: response.data.content,
        pagination: {
          current,
          pageSize,
          total: response.data.size,
          position: ['topRight' as const],
        },
        totalizers: response.data.totalizers,
        arranj: context,
      };*/
  
      return payload;
  
    } catch (e) {
      console.log(e);
  
      const payload = {
        data: [],
        pagination: {
          current: 1,
          pageSize: 50,
          total: 0,
          position: ['topRight' as const],
        },
        totalizers: 0,
      };
  
      return payload;
    }
}

export const exportBppStatement = async ({
  filters,
  date
}: ExportBppStatements): Promise<ExportResponse> => {
  let url_filter = '';

  try {
    if (!isEmpty(filters)) {
      for (const key in filters){
        url_filter += `&${key}=${JSON.stringify(filters[key])}`;
      }
    }

    const response = await httpGet(
      `${conciliation}/v1beta1/bpp-statement/export?${date}${url_filter}`,
    );

    const url_download = response.data;
    return {
      status: true,
      url: url_download,
      email: false,
      message: 'Sucesso! Seu relatorio está pronto e já esta sendo baixado.',
    };
  } catch (e) {
    if (e.response.data.message === 'Too big for response') {
      try {
        const userEmail = cookies.get('user');
        await httpPost(
          `${conciliation}/v1beta1/bpp-statement/export?${date}${url_filter}`,
          { email: userEmail },
        );

        return {
          status: true,
          url: '',
          email: true,
          message: `Sucesso! Estamos gerando seu relatorio e em breve enviaremos para o email : ${userEmail}  para download.`,
        };
      } catch (e) {
        return {
          status: false,
          url: '',
          email: false,
          message:
            'Erro! Não conseguimos gerar seu relatorio. Algo deu errado.',
        };
      }
    } else if (
      e.response.data.message === 'Maximum difference of months is three'
    ) {
      return {
        status: false,
        url: '',
        email: false,
        message:
          'Erro! Não conseguimos gerar seu relatorio. Periodo selecionado superior a 3 meses. Por gentileza, selecione um periodo menor.',
      };
    }
    return {
      status: false,
      url: '',
      email: false,
      message: '',
    };
  }
};
