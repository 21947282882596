import { cleanCnpjCpf } from "./format-documents";
import moment from "moment";

export function normalizeFilters(filter) {

  const needNormalize = [
    "sourceCustomer",
    "targetCustomer",
    "valueBetween_orderValue_valueLowerThen",
    "chargedTariffBetween_orderChargedTariff_chargedTariffLowerThen",
    "costBetween_orderCost_costLowerThen",
    "revenueBetween_orderRevenue_revenueLowerThen",
    "date_orderTransactionDate",
  ];

  const result = needNormalize.find((normalize) => normalize === filter[0]);

  if (result !== undefined) {
    let normalized = [];
    filter[1].map((value) => {
      if (result === "sourceCustomer" || result === "targetCustomer") {
        return normalized.push(cleanCnpjCpf(value));
      } else if (
        result === "valueBetween_orderValue_valueLowerThen" ||
        result === "chargedTariffBetween_orderChargedTariff_chargedTariffLowerThen" ||
        result === "costBetween_orderCost_costLowerThen" ||
        result === "revenueBetween_orderRevenue_revenueLowerThen"
      ) {
        if ( filter[1][2] === "checked") {
          return (normalized = value);
        } else if(filter[1][0] !== null && filter[1][1] !== null){
          let formatter = value.replace("R$", "");
          formatter = formatter.replace(".", "");
          formatter = formatter.replace(",", ".");
          formatter = parseFloat(formatter).toFixed(2);
          normalized.push(Number(formatter));

          if (normalized[0] > normalized[1]) {
            let aux = normalized[1];
            normalized[1] = normalized[0];
            normalized[0] = aux;
          }
        }

      } else if (result === "date_orderTransactionDate") {
        if (filter[1][0] !== undefined && filter[1][0] !== null) {
          let now = moment(filter[1][0]._d)
            .utc()
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
          normalized[0] = now.format("YYYY-MM-DD HH:mm:ss");
        }

        if (filter[1][1] !== undefined && filter[1][1] !== null) {
          let now = moment(filter[1][1]._d)
            .utc()
            .hours(23)
            .minutes(59)
            .seconds(59)
            .milliseconds(0);
          normalized[1] = now.format("YYYY-MM-DD HH:mm:ss");
        }
      }
      return false;
    });

    return normalized;
  } else {
    return filter[1];
  }
}
