/* eslint-disable react/jsx-one-expression-per-line */

import { Button, Spin } from 'antd';
import { Container, SecurityContainer } from './style';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import appNotification from '../../components/notification';
import { appVersion } from '../../config';
import { securityAlert } from '../../api/ResetService';

const SecurityAlert: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory(); 
  
  const params:any = useParams();

  async function handleAlert(): Promise<void> {
    setLoading(true);
    const { status, message } = await securityAlert(params.hash);
    
    if (status) {
      appNotification('success', message);
      history.push('/');
    } else {
      setLoading(false);
      appNotification('error', message);
    }
  }

  return (
    <Spin spinning={loading}>
      <Container>
        <section>
          <h1>U4CRYPTO</h1>
          <SecurityContainer>            
            <div>
              <p>
                Ao clicar em confirmar a sua conta será temporariamente bloqueada por questões de segurança.
                Para o desbloqueio da mesma você deverá entrar em contato com o suporte da U4crypto.
              </p>             
            </div>
              <Button
                shape="round"
                type="primary"
                size="small"
                onClick={handleAlert}
              >
                CONFIRMAR
              </Button>
          </SecurityContainer>
          <p> {appVersion} </p>
        </section>
      </Container>
    </Spin>
  );
};

export default SecurityAlert;
