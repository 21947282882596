import React, { useState, useEffect, useCallback } from 'react';
import { orderBy } from 'lodash';
import { Select, Space, Row, Col, Card } from 'antd';
import LineChart from '../../components/Chart/line-chart';
import BarChart from '../../components/Chart/bar-chart';

import { formatCurrency } from '../../utils/format-currency.js';
import { white, secundaryColor } from '../../config';
import { getRandomColor } from '../../utils/random-color.js';

import {
  Container,
  TitleContainer,
  TotalizersContainer,
  ChartsContainer,
  AditionalContent,
  MainContent,
} from './style';

interface Props {
  fetchOptions: any;
  title: string;
  subtitle?: string;
  placeholder: string;
  labels: any;
  aditionalContent?: React.ReactNode;
}

const DataComparison: React.FC<Props> = ({
  fetchOptions,
  title,
  subtitle,
  placeholder,
  labels,
  aditionalContent,
}: Props) => {
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [mainOptions, setMainOptions] = useState<any>([]);
  const [secondaryOptions, setSecondaryOptions] = useState<any>([]);
  const [totalizersCards, setTotalizersCards] = useState<any>(null);
  const [charts, setCharts] = useState<any>(null);
  const [compare, setCompare] = useState<any>({ main: '', secondary: [] });
  const [optionsBackup, setOptionsBackup] = useState<any>({
    main: [],
    secondary: '',
  });

  async function handleonFocus(): Promise<any> {
    if (mainOptions.length <= 0 && secondaryOptions.length <= 0) {
      setLoadingOptions(true);
      const options: any = [];

      const response = await fetchOptions();

      if (response) {
        response.map((mainData: any) => {
          return options.push({
            name: mainData.name,
            document: mainData.document,
            totalizers: mainData.totalizers,
          });
        });
        setOptions(orderBy(options, ['name'], ['asc']));
        setSecondaryOptions(orderBy(options, ['name'], ['asc']));
        setMainOptions(orderBy(options, ['name'], ['asc']));
      }

      return setLoadingOptions(false);
    }
    return true;
  }

  async function handleMainChange(value: any): Promise<any> {
    if (value.length < 0) {
      return false;
    }
    const secondary = [...secondaryOptions];

    if (optionsBackup.secondary !== '') {
      secondary.push(optionsBackup.secondary);
    }

    const index = secondaryOptions.findIndex(
      (array: any) => array.document === value.value,
    );

    if (index !== -1) {
      setOptionsBackup((prevState: any) => {
        return { ...prevState, secondary: secondaryOptions[index] };
      });

      secondary.splice(index, 1);

      setSecondaryOptions(orderBy(secondary, ['name'], ['asc']));
    }

    return setCompare((prevState: any) => {
      return { ...prevState, main: value };
    });
  }

  async function handleSecondaryChange(value: any): Promise<any> {
    if (value.length < 0) {
      return false;
    }
    const removeMain: any = [];
    const backup: any = [...optionsBackup.main];

    mainOptions.map((array: any) => {
      return value.map((values: any) => {
        if (array.document === values.value) {
          removeMain.push(array);
          return true;
        }
        return false;
      });
    });

    if (removeMain.length > 0) {
      const main = [...mainOptions];
      removeMain.map((values: any) => {
        backup.push(values);
        const index = main.findIndex(
          array => array.document === values.document,
        );

        return main.splice(index, 1);
      });

      setOptionsBackup((prevState: any) => {
        return { ...prevState, main: backup };
      });
      setMainOptions(main);
    }

    return setCompare((prevState: any) => {
      return { ...prevState, secondary: value };
    });
  }

  async function handleDeselect(value: any): Promise<any> {
    const main: any = [...mainOptions];
    const compareOptions: any = [...compare.secondary];
    const backup: any = [...optionsBackup.main];

    if (optionsBackup.main.length > 0) {
      const backupIndex = optionsBackup.main.findIndex(
        (backup: any) => backup.document === value.value,
      );
      main.push(optionsBackup.main[backupIndex]);

      backup.splice(backupIndex, 1);

      setOptionsBackup((prevState: any) => {
        return { ...prevState, main: backup };
      });
    }

    const index = compare.secondary.findIndex(
      (compare: any) => compare.document === value.value,
    );
    compareOptions.splice(index, 1);

    setCompare((prevState: any) => {
      return { ...prevState, secondary: compareOptions };
    });

    setMainOptions(orderBy(main, ['name'], ['asc']));
  }

  const handleMainTotalizers = useCallback(() => {
    if (compare.main === '') {
      return;
    }

    const findMainTotalizers = mainOptions.find((main: any) => {
      if (main.document === compare.main.value) {
        return main.totalizers;
      }
      return false;
    });

    const { totalizers } = findMainTotalizers;

    const mainTotalizersCards = [
      {
        colGrid: 6,
        title: 'Valor transacionado',
        content: formatCurrency(totalizers.value),
      },
      {
        colGrid: 6,
        title: 'Soma custo',
        content: formatCurrency(totalizers.cost),
      },
      {
        colGrid: 6,
        title: 'Soma tarifas',
        content: formatCurrency(totalizers.chargedTariff),
      },
      {
        colGrid: 6,
        title: 'Soma receita',
        content: formatCurrency(totalizers.revenue),
      },
    ];

    const cardsMain = mainTotalizersCards.map((card: any) => (
      <Card title={card.title} key={card.title}>
        {card.content}
      </Card>
    ));

    setTotalizersCards(cardsMain);

    if (compare.secondary.length !== 0) {
      handleCompareOptions();
      return;
    }

    const mainChartData = {
      labels: ['total tarifas', 'custo total', 'valor total', 'receita total'],
      datasets: [
        {
          label: false,
          data: [
            totalizers.chargedTariff,
            totalizers.cost,
            totalizers.value,
            totalizers.revenue,
          ],
          stack: '2',
          fill: false,
          borderColor: '#DC143C',
          pointBackgroundColor: white,
          pointRadius: 5,
          pointStyle: 'circle',
          backgroundColor: ['#DC143C', '#FF4500', '#20B2AA', '#3CB371'],
        },
      ],
    };

    setCharts(
      <>
        <Row>
          <Col span={12}>
            <BarChart legend={false} ChartData={mainChartData} />
          </Col>
          <Col span={12}>
            <LineChart legend={false} ChartData={mainChartData} />
          </Col>
        </Row>
      </>,
    );

    // eslint-disable-next-line
  }, [compare.main, mainOptions]);

  const handleCompareOptions = useCallback(() => {
    if (compare.main === '') {
      return;
    }

    const compareToalizers: any = [];
    const joinCompareOptions = [];
    const Comparechart: any = {
      labels: ['total tarifas', 'custo total', 'valor total', 'receita total'],
      datasets: [],
    };
    const chartColors: any = [];

    joinCompareOptions.push(compare.main);

    compare.secondary.map((secondary: any) => {
      return joinCompareOptions.push(secondary);
    });

    joinCompareOptions.filter(compareOptions => {
      return options.map((options: any) => {
        if (compareOptions.value === options.document) {
          return compareToalizers.push(options);
        }
        return false;
      });
    });

    compareToalizers.map(() => chartColors.push(getRandomColor()));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < compareToalizers.length; i++) {
      Comparechart.datasets.push({
        stack: i + 1,
        label: compareToalizers[i].name,
        fill: false,
        borderColor: chartColors[i],
        pointBackgroundColor: white,
        pointRadius: 5,
        pointStyle: 'circle',
        data: [
          compareToalizers[i].totalizers.chargedTariff,
          compareToalizers[i].totalizers.cost,
          compareToalizers[i].totalizers.value,
          compareToalizers[i].totalizers.revenue,
        ],
        backgroundColor: chartColors[i],
      });
    }

    setCharts(
      <Row>
        <Col span={12}>
          <BarChart legend ChartData={Comparechart} />
        </Col>
        <Col span={12}>
          <LineChart legend ChartData={Comparechart} />
        </Col>
      </Row>,
    );
    // eslint-disable-next-line
  }, [compare.main, compare.secondary, compare.secondary.length]);

  useEffect(() => {
    handleMainTotalizers();
    // eslint-disable-next-line
  }, [compare.main]);

  useEffect(
    function compareOptions() {
      if (compare.secondary.length <= 0) {
        handleMainTotalizers();
        return;
      }
      handleCompareOptions();
    },
    // eslint-disable-next-line
    [compare.secondary]
  );

  return (
    <Container>
      <TitleContainer>
        <h4 style={{ color: secundaryColor }}>{title}</h4>
        <h5 style={{ color: secundaryColor }}>{subtitle}</h5>
      </TitleContainer>
      <Space size="large">
        <p>{labels.main}</p>
        <Select
          style={{ width: '400px' }}
          loading={loadingOptions}
          labelInValue
          placeholder={placeholder}
          onFocus={handleonFocus}
          // name="main"
          // widthSelect="400px"
          showSearch
          onChange={handleMainChange}
        >
          {mainOptions?.map((main: any) => (
            <Select.Option key={main.document} value={main.document}>
              {main?.name}
            </Select.Option>
          ))}
        </Select>
        <p>{labels.secondary}</p>
        <Select
          style={{ width: '400px' }}
          loading={loadingOptions}
          labelInValue
          placeholder="Comparar com...."
          onFocus={handleonFocus}
          // name="secondary"
          // widthSelect="400px"
          mode="multiple"
          onChange={handleSecondaryChange}
          onDeselect={handleDeselect}
        >
          {secondaryOptions?.map((main: any) => (
            <Select.Option key={main.document} value={main.document}>
              {main.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <MainContent>{compare.main?.label}</MainContent>
      <TotalizersContainer>{totalizersCards}</TotalizersContainer>
      <ChartsContainer>{charts}</ChartsContainer>
      <AditionalContent>{aditionalContent}</AditionalContent>
    </Container>
  );
};

export default DataComparison;
