import React, { Key } from 'react';
import { AppScreensProps } from '.';
import * as S from './style';

interface TableColumnsNotAddedsProps {
  appScreens: AppScreensProps[];
  handleRowSelecteds: {
    onChange: (selectedRowKeys: Key[], selectedRows: any[]) => void;
  };
  loading: boolean;
}

interface TableColumnsAddedsProps {
  appScreensUser: AppScreensProps[];
  handleRowSelectedsUser: {
    onChange: (selectedRowKeys: Key[], selectedRows: any[]) => void;
  };
  loading: boolean;
}

export const TableColumnsNotAddeds: React.FC<TableColumnsNotAddedsProps> = ({
  appScreens,
  handleRowSelecteds,
  loading,
}: TableColumnsNotAddedsProps) => {
  const columns = [
    {
      title: 'Nome',
      render: (render: AppScreensProps) => render.name,
      key: 'name',
    },
  ];

  return (
    <S.TableContainer
      scroll={{ y: 350 }}
      title={() => 'Funcionalidades e Menus'}
      dataSource={appScreens}
      columns={columns}
      rowSelection={{ ...handleRowSelecteds }}
      rowKey={(record: any) => record.app_id}
      pagination={false}
      loading={loading}
    />
  );
};

export const TableColumsAddeds: React.FC<TableColumnsAddedsProps> = ({
  appScreensUser,
  handleRowSelectedsUser,
  loading,
}: TableColumnsAddedsProps) => {
  const columns = [
    {
      title: 'Nome',
      render: (render: AppScreensProps) => render.name,
      key: 'name',
    },
  ];

  return (
    <S.TableContainer
      scroll={{ y: 350 }}
      title={() => 'Funcionalidades e Menus adicionados ao usuário'}
      dataSource={appScreensUser}
      columns={columns}
      rowSelection={{ ...handleRowSelectedsUser }}
      rowKey={(record: any) => record.app_id}
      pagination={false}
      loading={loading}
    />
  );
};
