export function formatSplit(split){


    if(split === undefined)
    {
        return false;
    }

    let formatedSplit = split === 'percent' ? 'Porcentagem' : 'Valor pré fixado'
  
    return formatedSplit;
  }
  