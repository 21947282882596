import { httpGet } from '../services/http/index.js';
import { conciliation } from './config/consts';

interface Partner {
  id: string;
  minvalue: string;
  partner: {
    id: string;
    name: string;
    enable: boolean;
  };
  cardbalance: number;
  pixbalance: number;
  balance: number;
}
export interface GetPartnersProps {
  content: Partner[];
  size: number;
  totalizers: {
    value: number;
    averageExpense: number;
  };
}
interface getAsyncPartnersProps {
  data: GetPartnersProps;
}

export const getPartnersBalance = async (
  take: number,
  page: number,
): Promise<getAsyncPartnersProps> => {
  const endpoint = `${conciliation}/v1beta1/partner-card-balance?take=${take}&page=${page}`;

  const response = await httpGet(endpoint);

  return { data: response.data };
};

export const getPartnersPixBalance = async (
  take: number,
  page: number,
): Promise<getAsyncPartnersProps> => {
  const endpoint = `${conciliation}/v1beta1/partner-pix-balance?take=${take}&page=${page}`;

  const response = await httpGet(endpoint);

  return { data: response.data };
};

export const getPartnersArrangementsBalance = async (
  take: number,
  page: number,
): Promise<getAsyncPartnersProps> => {
  const endpoint = `${conciliation}/v1beta1/partner-arrangement-balance?take=${take}&page=${page}`;
  const response = await httpGet(endpoint);

  return { data: response.data };
};
