import { Button, Table } from 'antd';
import styled from 'styled-components';
import { Modal } from '../../../components/Modal';

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  .ant-table-wrapper {
    width: 100%;
  }
  .filters {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: flex-end;

    .label-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 14.5551px;
        line-height: 17px;
        color: #4c2d6e;
      }
      .ant-picker-range,
      .ant-select-selector {
        width: 220px;
        border: 1px solid #4c2d6e;
      }
    }
  }
`;

interface ActionButtonProps {
  $approved?: boolean;
}
export const ActionButton = styled(Button)<ActionButtonProps>`
  color: ${props => (props.$approved ? '#1DD36D' : '#EB5757')};

  font-style: normal;
  font-weight: normal;
  font-size: 12.878px;
  line-height: 15px;
  text-align: center;
`;

export const TableContainer = styled(Table)`
  .ant-table-thead tr th:nth-last-child(2) {
    display: flex;
    justify-content: center;
  }
  .actions {
    width: 100%;
    display: flex;
    gap: 18.5px;
    align-items: center;
    justify-content: center;
  }
  .status {
    display: flex;
    justify-content: center;
    span {
      display: flex;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 15.1579px;
      line-height: 18px;
      text-align: center;
      color: #828282;
    }
  }
  .excluir,
  .detail,
  .receipt {
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.9);
    }
    &:disabled {
      cursor: not-allowed;

      .icon {
        color: #e0e0e0;
      }
    }
  }
`;

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    width: 740px;
  }
  .ant-modal-body .ant-form {
    width: 100%;

    .reason {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 9px;
      justify-content: center;
      padding: 11px 28px;
      align-items: flex-start;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #828282;
      }

      textarea {
        width: 100%;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 83px;
        padding: 5px;
      }
      .row {
        width: 100%;
        display: flex;
        align-items: flex-start;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: #828282;
        margin-left: auto;
      }
      .error {
        margin: 0;
        margin-right: auto;
        color: red;
        font-size: 12px;
      }
    }
  }
`;
