export function transalateConciliationStatus(conciliationStatus = null) {
  const translations = [
    { value: 0, label: "Pendente", color: "#BF5E30" },
    { value: 1, label: "Conciliado", color: "#847D46" },
    { value: 2, label: "Conciliado com diferença", color: "#0D98BA" },
    { value: 3, label: "Não conciliado", color: "#95390f"}
  ];

  if (conciliationStatus === null) {
    return translations;
  } else {
    const result = translations.find(
      (searchKey) => searchKey.value === conciliationStatus
    );
    return result?.label;
  }
}
