import { Button, Modal as ModalComponent } from 'antd';
import React from 'react';

export interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  title: string;
  okText: string;
  footerText: string;
  onCancelText: string;
  onClick: () => void;
  children: React.ReactNode;
  confirmLoading?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  title,
  okText,
  footerText,
  onCancelText,
  onClick,
  children,
  confirmLoading,
  ...rest
}: ModalProps) => {
  return (
    <ModalComponent
      visible={visible}
      onCancel={onCancel}
      title={title}
      okText={okText}
      footer={[
        <Button onClick={onCancel}>{onCancelText}</Button>,
        <Button
          form="form"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={onClick}
        >
          {footerText}
        </Button>,
      ]}
      confirmLoading={confirmLoading}
      {...rest}
    >
      {children}
    </ModalComponent>
  );
};
