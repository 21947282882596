import { ActionsContainer, CardContainer, Code } from './style';
import { Button, Card, Modal, Spin, Table } from 'antd';
import {
  FaAngleDown,
  FaAngleUp,
  FaArrowRight,
  FaFileDownload,
  FaRegEye,
  FaRegWindowClose,
} from 'react-icons/fa';
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import {
  exportTransactions,
  getTransactions,
  getTransactionsType,
} from '../../../../api/TransactionService';
import { isEmpty, isEqual } from 'lodash';

import AppNotification from '../../../../components/notification';
import Dashboard from './Dashboard';
import PeriodPicker from '../../../../containers/Period-picker';
import TableFilter from '../../../../containers/Table-filter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cookies from 'js-cookie';
import { formatCnpjCpf } from '../../../../utils/format-documents.js';
import { formatCurrency } from '../../../../utils/format-currency.js';
import { getPartnersAsOptions } from '../../../../api/PartnerService';
import { getUser } from '../../../../api/UserService';
import moment from 'moment';
import { normalizeFilters } from '../../../../utils/normalize-filters.js';
import { searchBanks } from '../../../../api/BankSerivice';
import { setContext } from '../../../../store/actions/transactions';
import { softGrey } from '../../../../config';
import { transalateConciliationStatus } from '../../../../utils/translations/conciliation-status.js';
import { transalateTransactionsStatus } from '../../../../utils/translations/transaction-status.js';
import { translateTransactionsType } from '../../../../utils/translations/transactions-types.js';

interface Props {
  context: 'outsider';
  reduxContext?: any;
  setContext?: any;
  userScreens: string[];
}

interface Data {
  transactionWallet: {
    data: string;
  };
  transactionType: {
    desc: string;
  };
  transactionDate: Date;
  source: {
    name: string;
    document: {
      value: string;
    };
  };
  target: {
    document: {
      value: string;
    };
  };
  value: number;
  taxOperation: number;
  chargedTariff: number;
  cost: number;
  revenue: number;
  conciliationStatus: number;
  transactionStatus: number;
  partner: {
    name: string;
  };
  id: string;
  referenceId: string;
  pixReference: string;
}

interface FilterDropDown {
  setSelectedKeys: () => void;
  selectedKeys: Array<any> | string;
  confirm: () => void;
  clearFilters: () => void;
}

const Outsiders: React.FC<Props> = ({
  context,
  reduxContext,
  setContext,
  userScreens,
}: Props) => {
  const [progressPeding, setProgressPeding] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [totalizersVisibility, setTotalizersVisibility] = useState(true);

  const [data, setData] = useState<Array<Data>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    position: ['topRight' as const],
  });
  const [sorters, setSorters] = useState({});
  const [filters, setFilters] = useState<any>({});
  const [filterPeriod, setFilterPeriod] = useState<string | undefined>('');
  const [filtersNormalized, setFiltersNormalized] = useState({});
  const [taxOperation, setTaxOperation] = useState({});
  const [walletDetails, setWalletDetails] = useState({
    status: false,
    data: {},
  });
  const [total, setTotal] = useState(0);
  const [partners, setPartners] = useState([
    { value: null, label: 'carregando...' },
  ]);
  const [transactionsType, setTransactionsType] = useState([
    { value: null, label: 'carregando...' },
  ]);
  const [transactionsStatus, setTransactionsStatus] = useState<any>([
    { value: null, label: 'carregando...' },
  ]);
  const [conciliationStatus, setConciliationStatus] = useState<any>([
    { value: null, label: 'carregando...' },
  ]);

  const [banks, setBanks] = useState([{ value: null, label: 'carregando...' }]);

  useEffect(() => {
    async function fetchColumns(): Promise<any> {
      let taxColumn = null;
      const accessLevel = cookies.get('accessLevel');

      const userId = cookies.get('userId');
      const response = await getUser(userId!);

      const userPartners = response.data.partners;

      const isFromCompany = userPartners.find(
        (partner: any) => partner.name === 'U4crypto'
      );

      if(accessLevel === '0' || accessLevel === '1' || !isEmpty(isFromCompany)){
         taxColumn =  {
                        render: (render: Data) => formatCurrency(render.taxOperation),
                        title: 'Taxa sobre a operação',
                        key: 'taxOpBetween_ordertaxOp_taxOpLowerThen',
                        sorter: true,
                        align: 'right' as const,
                      };
         setTaxOperation(taxColumn);
      }
    }
    fetchColumns();
    // eslint-disable-next-line
  }, []);

  async function fetchPartners(): Promise<void> {
    if (partners.length > 2) {
      return;
    }
    const response = await getPartnersAsOptions();
    setPartners(response.data);
  }

  async function fetchTransactionsType(): Promise<void> {
    if (transactionsType.length > 2) {
      return;
    }
    const response = await getTransactionsType();
    setTransactionsType(response);
  }

  async function fetchTransactionsStatus(): Promise<void> {
    if (transactionsStatus.length > 2) {
      return;
    }
    const response = await transalateTransactionsStatus();
    setTransactionsStatus(response);
  }

  async function fetchConciliationStauts(): Promise<void> {
    if (conciliationStatus.length > 2) {
      return;
    }
    const response = await transalateConciliationStatus();
    setConciliationStatus(response);
  }

  async function fetchBanks(value?: any): Promise<any> {
    if (value) {
      setTimeout(async () => {
        const response = await searchBanks(value);
        return setBanks(response);
      }, 800);
    }
  }

  async function fetchTransactions(dataPeriod: string): Promise<void> {
    setProgressPeding(true);

    setFilterPeriod(dataPeriod);

    const newPagination = {
      current: 1,
      pageSize: 50,
      total: 0,
      position: ['topRight' as const],
    };

    const response = await getTransactions({
      context,
      pagination: newPagination,
      sorter: sorters,
      filters: filtersNormalized,
      date: dataPeriod,
    });
    if (response.data.length > 0) {
      setData(response.data);
      setPagination(response.pagination);

      setContext({
        type: response.arranj,
        totalizers: response.totalizers,
        total: response.pagination.total,
        period: dataPeriod,
        filters: filtersNormalized,
        dashboardVisibility: false,
      });
    }
    setTotal(response.pagination.total);
    setProgressPeding(false);
  }

  function handleWalletDetails(data: Data): void {
    setWalletDetails({ status: true, data: JSON.stringify(data, null, 2) });
  }

  function getColumnSearchProps(
    dataIndex: string,
    filterType: string,
    filterMode: 'multiple' | 'tags' | undefined,
    filterOptions: Array<any> | undefined,
    FilterSelectOnFocus: any,
    onSearch: any,
  ): any {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropDown) => {
        return (
          <TableFilter
            dataIndex={dataIndex}
            filterType={filterType}
            filterMode={filterMode}
            filterOptions={filterOptions}
            FilterSelectOnFocus={FilterSelectOnFocus}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            onSearch={onSearch}
            clearFilters={clearFilters}
            clearSingleFilter={clearSingleFilter}
          />
        );
      },
    };
  }

  async function handleTableChange(
    paginationParam: any,
    filterParam: any,
    sorterParam: any,
  ): Promise<void> {
    const newFilters: any = {};
    const filterForApi: any = {};

    const convertIntoArray = Object.entries(filterParam);

    convertIntoArray.map(filterValue => {
      if (filterValue[1] !== null) {
        const key = filterValue[0]?.split('_');
        // eslint-disable-next-line prefer-destructuring
        newFilters[key[0]] = filterValue[1];
        filterForApi[key[0]] = normalizeFilters(filterValue);

        if (key.length > 2) {
          if (filterForApi[key[0]] === 'checked') {
            delete filterForApi[key[0]];
            filterForApi[key[2]] = 0;
          } else {
            delete filterForApi[key[2]];
            if (filterForApi[key[0]].length > 0)
              filterForApi[key[0]].splice(2, 1);
          }
        }

        if (isEmpty(filterForApi[key[0]])) {
          delete filterForApi[key[0]];
        }
      }
      return false;
    });

    setFilters(newFilters);

    if (!isEmpty(newFilters)) {
      setProgressPeding(true);
      setFiltersNormalized(filterForApi);

      const response = await getTransactions({
        context,
        pagination: paginationParam,
        sorter: sorters,
        filters: filterForApi,
        date: filterPeriod,
      });

      setData(response.data);

      setContext({
        type: response.arranj,
        totalizers: response.totalizers,
        total: response.pagination.total,
        period: filterPeriod,
        filters: filterForApi,
        dashboardVisibility: false,
      });
      setTotal(response.pagination.total);
      setProgressPeding(false);
    }

    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPeding(true);
      const response = await getTransactions({
        context,
        pagination: paginationParam,
        sorter: sorters,
        filters: filterForApi,
        date: filterPeriod,
      });
      setData(response.data);
      setPagination(response.pagination);
      setProgressPeding(false);
      return;
    }

    if (!isEqual(sorterParam, sorters)) {
      setProgressPeding(true);
      const { columnKey, order } = sorterParam;

      const sorterKey = columnKey?.split('_');
      const sorterOrder = order === 'ascend' ? 'ASC' : 'DESC';
      const newSorter = {
        orderColumn: sorterKey[1],
        orderDirection: sorterOrder,
      };
      setSorters(newSorter);
      const newPagination = {
        current: 1,
        pageSize: paginationParam.pageSize,
        total: paginationParam.total,
        position: ['topRight' as const],
      };
      const response = await getTransactions({
        context,
        pagination: newPagination,
        sorter: newSorter,
        filters: filterForApi,
        date: filterPeriod,
      });
      setData(response.data);
      setPagination({
        current: 1,
        pageSize: paginationParam.pageSize,
        total: response.pagination.total,
        position: ['topRight' as const],
      });

      setContext({
        type: response.arranj,
        totalizers: response.totalizers,
        total: response.pagination.total,
        period: filterPeriod,
        dashboardVisibility: false,
      });
      setProgressPeding(false);
    }
  }

  async function clearSingleFilter(columnKey: string): Promise<void> {
    setProgressPeding(true);

    const split = columnKey.split('_');
    const filterDelete: any = { ...filtersNormalized };

    if (split.length > 1) {
      delete filterDelete[split[0]];
      delete filterDelete[split[1]];
    } else {
      delete filterDelete[columnKey];
    }

    setFiltersNormalized(filterDelete);

    const response = await getTransactions({
      context,
      pagination,
      sorter: sorters,
      filters: filterDelete,
      date: filterPeriod,
    });
    setData(response.data);
    if (isEmpty(filterDelete)) {
      setPagination({
        current: 1,
        pageSize: 50,
        total: response.pagination.total,
        position: ['topRight' as const],
      });
    } else {
      setPagination(response.pagination);
    }

    setContext({
      type: response.arranj,
      totalizers: response.totalizers,
      total: response.pagination.total,
      period: filterPeriod,
      filters: filterDelete,
      dashboardVisibility: false,
    });
    setProgressPeding(false);
  }

  async function clearFilters(): Promise<void> {
    setProgressPeding(true);
    setFilters({});
    setFiltersNormalized({});
    const response = await getTransactions({
      context,
      pagination,
      sorter: sorters,
      filters: {},
      date: filterPeriod,
    });
    setData(response.data);
    setPagination({
      current: 1,
      pageSize: 50,
      total: response.pagination.total,
      position: ['topRight' as const],
    });

    setContext({
      type: response.arranj,
      totalizers: response.totalizers,
      total: response.pagination.total,
      period: filterPeriod,
      filters: {},
      dashboardVisibility: false,
    });
    setProgressPeding(false);
  }

  async function handleExportTransactions(): Promise<void> {
    setExportLoading(true);

    const response = await exportTransactions({
      transactionContext: context,
      sorter: sorters,
      filters: filtersNormalized,
      date: filterPeriod,
    });

    if (response.status && response.email === true) {
      AppNotification('success', response.message);
    }

    if (response.status && response.email === false) {
      window.location.href = response.url;
      AppNotification('success', response.message);
    }

    if (response.status === false) {
      AppNotification('error', response.message);
    }

    setExportLoading(false);
  }
  const columns = [
    {
      title: 'Detalhes wallet',
      align: 'center' as const,
      render: (render: Data) => (
        <Button
          onClick={() => handleWalletDetails(render)}
          icon={<FaRegEye color={softGrey} />}
        />
      ),
    },
    {
      render: (render: Data) =>
        translateTransactionsType(render.transactionType?.desc),
      title: 'Tipo de Transação',
      sorter: true,
      key: 'type_orderTransactionType',
      filteredValue: filters.type || null,
      ...getColumnSearchProps(
        'type',
        'select',
        'multiple',
        transactionsType,
        fetchTransactionsType,
        undefined,
      ),
    },
    {
      render: (render: Data) =>
        moment(render.transactionDate).utc().format('DD-MM-YYYY HH:mm:ss'),
      title: 'Data Transação',
      key: 'date_orderTransactionDate',
      filteredValue: filters.date || null,
      sorter: true,
    },
    {
      title: 'Nome origem',
      dataIndex: ['source', 'name'],
      sorter: true,
      key: 'sourceCustomerName_orderSourceCustomerName',
    },
    {
      render: (render: Data) => formatCnpjCpf(render.source.document.value),
      title: 'Documento origem',
      key: 'sourceCustomer',
      filteredValue: filters.sourceCustomer || null,
      ...getColumnSearchProps(
        'sourceCustomer',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      title: 'Nome destino',
      dataIndex: ['target', 'name'],
      sorter: true,
      key: 'targetCustomerName_orderTargetCustomerName',
    },
    {
      title: 'Identificador Pix',
      dataIndex: 'pixReference',
      filteredValue: filters.pixReference || null,
      ...getColumnSearchProps(
        'pixReference',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      render: (render: Data) => formatCnpjCpf(render.target.document.value),
      title: 'Documento destino',
      key: 'targetCustomer',
      filteredValue: filters.targetCustomer || null,
      ...getColumnSearchProps(
        'targetCustomer',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      title: 'Instituição financeira',
      sorter: true,
      key: 'targetBank_orderTargetBankName',
      filteredValue: filters.targetBank || null,
      dataIndex: ['targetBank', 'name'],
      ...getColumnSearchProps(
        'targetBank',
        'select',
        'multiple',
        banks,
        undefined,
        fetchBanks,
      ),
    },
    {
      render: (render: Data) => formatCurrency(render.value),
      title: 'Valor da transação',
      key: 'valueBetween_orderValue_valueLowerThen',
      filteredValue: filters.valueBetween || null,
      sorter: true,
      align: 'right' as const,
      ...getColumnSearchProps(
        'valueBetween_valueLowerThen',
        'number',
        undefined,
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      render: (render: Data) => formatCurrency(render.chargedTariff),
      title: 'Tarifa',
      key: 'chargedTariffBetween_orderChargedTariff_chargedTariffLowerThen',
      filteredValue: filters.chargedTariffBetween || null,
      sorter: true,
      align: 'right' as const,
      ...getColumnSearchProps(
        'chargedTariffBetween_chargedTariffLowerThen',
        'number',
        undefined,
        undefined,
        undefined,
        undefined,
      ),
    },
    userScreens.includes('sum_cost')
      ? {
          render: (render: Data) => formatCurrency(render.cost),
          title: 'Custo',
          key: 'costBetween_orderCost_costLowerThen',
          filteredValue: filters.costBetween || null,
          sorter: true,
          align: 'right' as const,
          ...getColumnSearchProps(
            'costBetween_costLowerThen',
            'number',
            undefined,
            undefined,
            undefined,
            undefined,
          ),
        }
      : {},
    userScreens.includes('sum_revenue')
      ? {
          render: (render: Data) => formatCurrency(render.revenue),
          title: 'Receita',
          key: 'revenueBetween_orderRevenue_revenueLowerThen',
          filteredValue: filters.revenueBetween || null,
          sorter: true,
          align: 'right' as const,
          ...getColumnSearchProps(
            'revenueBetween_revenueLowerThen',
            'number',
            undefined,
            undefined,
            undefined,
            undefined,
          ),
        }
      : {},
    {
      render: (render: Data) =>
        transalateConciliationStatus(render.conciliationStatus),
      title: 'Status conciliação',
      key: 'conciliationStatus_orderConciliationStatus',
      filteredValue: filters.conciliationStatus || null,
      sorter: true,
      align: 'center' as const,
      ...getColumnSearchProps(
        'conciliationStatus',
        'select',
        'multiple',
        conciliationStatus,
        fetchConciliationStauts,
        undefined,
      ),
    },
    {
      title: 'Status transação',
      key: 'transactionStatus_orderTransactionStatus',
      sorter: true,
      align: 'center' as const,
      filteredValue: filters.transactionStatus || null,
      ...getColumnSearchProps(
        'transactionStatus',
        'select',
        'multiple',
        transactionsStatus,
        fetchTransactionsStatus,
        undefined,
      ),
      render: (render: Data) =>
        transalateTransactionsStatus(render.transactionStatus),
    },
    {
      title: 'Parceiro',
      name: 'Parceiro',
      dataIndex: ['partner', 'name'],
      key: 'partner_orderPartnerName',
      sorter: true,
      filteredValue: filters.partner || null,
      align: 'center' as const,
      ...getColumnSearchProps(
        'partner',
        'select',
        'multiple',
        partners,
        fetchPartners,
        undefined,
      ),
    },
    {
      title: 'Id da transação',
      key: 'referenceId',
      name: 'id da transação',
      dataIndex: 'referenceId',
      ...getColumnSearchProps(
       'referenceId',
       'select',
       'tags',
       undefined,
       undefined,
       undefined
       ),
    },
    {
      ...taxOperation
    }
  ];

  const cards = [
    {
      colGrid: 4,
      title: 'Valor transacionado',
      content: formatCurrency(reduxContext.totalizers?.value),
      router: 'total_value',
    },
    {
      colGrid: 4,
      title: 'Soma custo',
      content: formatCurrency(reduxContext.totalizers?.cost || 0),
      router: 'total_cost',
    },
    {
      colGrid: 4,
      title: 'Soma tarifas',
      content: formatCurrency(reduxContext.totalizers?.chargedTariff),
      router: 'total_chargedTariff',
    },
    {
      colGrid: 4,
      title: 'Soma receita',
      content: formatCurrency(reduxContext.totalizers?.revenue),
      router: 'total_revenue',
    },
    {
      colGrid: 4,
      title: 'Transações',
      content: reduxContext?.total,
      router: 'total_revenue',
    },
  ];

  if (reduxContext.dashboardVisibility) {
    return <Dashboard total={total} />;
  }

  return (
    <>
      <Spin spinning={exportLoading}>
        <PeriodPicker bringTransactions={fetchTransactions} />
        {filterPeriod && (
          <div>
            <ActionsContainer>
              <Button
                icon={
                  <FaRegWindowClose
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={clearFilters}
              >
                Limpar todos os filtros
              </Button>
              <Button
                icon={
                  <FaFileDownload
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={handleExportTransactions}
              >
                Exportar dados
              </Button>
              <Button
                icon={
                  totalizersVisibility ? (
                    <FaAngleUp style={{ marginRight: '10px' }} />
                  ) : (
                    <FaAngleDown style={{ marginRight: '10px' }} />
                  )
                }
                onClick={() => setTotalizersVisibility(!totalizersVisibility)}
              >
                {totalizersVisibility
                  ? 'Esconder Totalizadores'
                  : 'Mostrar Totalizadores'}
              </Button>
              <Button
                icon={
                  <FaArrowRight
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={() =>
                  setContext({
                    type: context,
                    totalizers: reduxContext.totalizers,
                    total: pagination.total,
                    period: filterPeriod,
                    filters: filtersNormalized,
                    dashboardVisibility: true,
                  })
                }
              >
                Dashboard
              </Button>
            </ActionsContainer>
            {totalizersVisibility && (
              <CardContainer>
                {cards.map(card => (
                  <Card title={card.title}>{card.content}</Card>
                ))}
              </CardContainer>
            )}
            <Table
              dataSource={data}
              columns={columns}
              onChange={handleTableChange}
              pagination={pagination}
              sortDirections={['ascend', 'descend', 'ascend']}
              bordered
              loading={progressPeding}
            />
            <Modal
              onCancel={() =>
                setWalletDetails(prev => {
                  return { ...prev, status: false };
                })
              }
              cancelText="Sair"
              onOk={() =>
                setWalletDetails(prev => {
                  return { ...prev, status: false };
                })
              }
              visible={walletDetails.status}
            >
              <Code>{walletDetails.data}</Code>
            </Modal>
          </div>
        )}
      </Spin>
    </>
  );
};

const mapStateToProps = (state: any): any => ({
  reduxContext: state.Transactions.context,
});

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ setContext }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Outsiders);
