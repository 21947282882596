import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import NavTab from '../../../components/NavTab';
import CustomerDocuments from '../../Conciliation/Documents-External/components/customer-document';
import Banks from '../Banks';
import { PaymentConsultCard } from '../Payment-consult';

const permissions = cookies.get('userScreens');
const userScreens = permissions ? JSON.parse(permissions) : [''];

export const ConsultOptions: React.FC = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    userScreens.includes('card_payments') ? 'card_payments' : 'documents',
  );

  const [tabs, setTabs] = useState([
    {
      title: 'Instituições Financeiras',
      key: 'financial_institution',
    },
    {
      title: 'Documentos',
      key: 'documents',
    },
    {
      title: 'Pagamentos de Cartão',
      key: 'card_payments',
    },
  ]);

  useEffect(() => {
    const tabsFilter: string[] = [];

    if (!userScreens.includes('financial_institution')) {
      tabsFilter.push('financial_institution');
    }
    if (!userScreens.includes('card_payments')) {
      tabsFilter.push('card_payments');
    }
    const filtereds = tabs.filter(f => !tabsFilter.find(t => t === f.key));
    setTabs([...filtereds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTab = (activeTabParam: string): React.ReactNode => {
    switch (activeTabParam) {
      case 'card_payments':
        return <PaymentConsultCard />;
      case 'documents':
        return <CustomerDocuments />;
      case 'financial_institution':
        return <Banks />;
      default:
        return <h5>404 not found</h5>;
    }
  };

  return (
    <>
      <Header
        title={`Consultas - ${tabs.find(f => f.key === activeTab)?.title}`}
        onBack={() => history.push('/home')}
      />
      <NavTab tabs={tabs} selected={activeTab} handleTab={setActiveTab} />
      {renderTab(activeTab)}
    </>
  );
};
