import React, { useState } from 'react';
import { Input, Select, Button, Space, Checkbox } from 'antd';
import { RiArrowLeftRightLine } from 'react-icons/ri';
import { SearchOutlined } from '@ant-design/icons';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { translateFilters } from '../../utils/translations/filters.js';

interface Props {
  filterType: string;
  filterMode: 'multiple' | 'tags' | undefined;
  filterOptions: Array<any> | undefined;
  FilterSelectOnFocus: any | null;
  dataIndex: string;
  setSelectedKeys: (value: string | number | React.ReactText[]) => void;
  selectedKeys: any;
  confirm: () => void;
  clearFilters: () => void;
  clearSingleFilter: (dataIndex: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: any;
}

const TableFilter: React.FC<Props> = ({
  filterType,
  filterMode,
  filterOptions,
  FilterSelectOnFocus,
  dataIndex,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  clearSingleFilter,
  onSearch,
}: Props) => {
  const [negativeValue, setNegativeValue] = useState(false);

  const SelectFilter = (
    <Select
      mode={filterMode}
      value={selectedKeys}
      onSearch={onSearch}
      optionFilterProp="children"
      placeholder={`Filtrar por ${translateFilters(dataIndex)}...`}
      onFocus={FilterSelectOnFocus}
      showSearch
      onChange={value => setSelectedKeys(value || [])}
      getPopupContainer={trigger => trigger}
      notFoundContent
      style={{
        width: 250,
        marginBottom: '18rem',
        display: 'block',
      }}
    >
      {filterOptions?.map(type => (
        <Select.Option value={type.value} key={type.value}>
          {type.label}
        </Select.Option>
      ))}
    </Select>
  );

  const TextFilter = (
    <Input
      style={{ width: 250, marginBottom: 8, display: 'block' }}
      placeholder={`Filtrar por ${translateFilters(dataIndex)}...`}
      value={selectedKeys}
      onChange={e => {
        setSelectedKeys(e.target.value ? e.target.value : []);
      }}
    />
  );

  const defaultMaskOptions = {
    prefix: 'R$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: true,
    allowLeadingZeroes: false,
  };
  const currencyMask = createNumberMask(defaultMaskOptions);
  const NumberFilter = (
    <div style={{ padding: '10px' }}>
      <Checkbox
        style={{ marginBottom: '1rem' }}
        defaultChecked={Array.isArray(selectedKeys) ? false : selectedKeys[2]}
        onChange={e => {
          const negative = [...selectedKeys];
          const value = e.target.checked ? 'checked' : 'unchecked';
          if (value === 'unchecked') {
            negative[0] = null;
            negative[1] = null;
          }
          negative[2] = value;

          setNegativeValue(!negativeValue);
          setSelectedKeys(negative);
        }}
      >
        Apenas negativos
      </Checkbox>
      {negativeValue === false && <br />}
      {negativeValue === false && (
        <Space style={{ marginRight: 20 }}>
          <MaskedInput
            mask={currencyMask}
            render={(ref, props) => (
              <Input
                placeholder="Valor inicial"
                ref={input => {
                  if (input) {
                    return ref(input && input.input);
                  }
                  return false;
                }}
                {...props}
                value={selectedKeys[0]}
                onChange={event => {
                  props.onChange(event);
                  const betweenInitial = [...selectedKeys];
                  betweenInitial[0] = event.target.value;
                  setSelectedKeys(betweenInitial);
                }}
              />
            )}
          />
          <RiArrowLeftRightLine />
          <MaskedInput
            mask={currencyMask}
            render={(ref, props) => (
              <Input
                placeholder="Valor final"
                ref={input => {
                  if (input) {
                    return ref(input && input.input);
                  }
                  return false;
                }}
                {...props}
                value={selectedKeys[1]}
                onChange={event => {
                  props.onChange(event);
                  const betweenFinal = [...selectedKeys];
                  betweenFinal[1] = event.target.value;
                  setSelectedKeys(betweenFinal);
                }}
              />
            )}
          />
        </Space>
      )}
    </div>
  );

  const defaultFilterTypes = [
    {
      type: 'select',
      element: SelectFilter,
    },
    {
      type: 'number',
      element: NumberFilter,
    },
    {
      type: 'text',
      element: TextFilter,
    },
  ];

  const handleFilterType = (): any => {
    const type = defaultFilterTypes.find(types => types.type === filterType);
    if (type) {
      return type.element;
    }

    return false;
  };

  const handleReset = (): void => {
    clearFilters();
    setSelectedKeys([]);
    return clearSingleFilter(dataIndex);
  };

  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      {handleFilterType()}
      {filterType === 'number' && <br />}
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtrar
        </Button>
        <Button
          onClick={() => handleReset()}
          size="small"
          style={{ width: 90 }}
        >
          Limpar
        </Button>
      </Space>
    </div>
  );
};

export default TableFilter;

TableFilter.defaultProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => event,
};
