/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
import { Badge, Button, Descriptions, Select, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getFunctionalities } from '../../../../../api/FunctionalitiesService';
import { getMenus } from '../../../../../api/MenusService';
import { getPartners } from '../../../../../api/PartnerService';
import {
  getUser,
  userAccessLevelChange,
  userAddPartnersPermission,
  // userAddPermission,
  userRemovePartnersPermission,
  // userRemovePermission,
} from '../../../../../api/UserService';
import Header from '../../../../../components/Header';
import appNotification from '../../../../../components/notification';
import { TableUserPermissions } from '../tableUserPermissions';
import {
  PartnersContainer,
  PermissionsContainer,
  PermissionsTitle,
  SelectedPartnersContainer,
} from './style';

interface UserData {
  name: string;
}

const UserPermissions: React.FC<UserData> = () => {
  const params: any = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [permissionIsChanged, setPermissionIsChanged] = useState(false);
  const [userData, setUserData] = useState<any>({});
  // const [permissionsMenus, setPermissionsMenus] = useState<any>([]);
  // const [permissionsFuncs, setPermissionsFuncs] = useState<any>([]);
  const [permissionsPartners, setPermissionsPartners] = useState<any>([]);
  const [menuPagination /* , setMenuPagination */] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const [funcPagination /* setFuncPagination */] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const [partnerPagination, setPartnerPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    position: ['bottomRight' as const],
  });
  // const [removeSelected, setRemoveSelected] = useState<any>({
  //   menusIds: [],
  //   funcsIds: [],
  //   menus: [],
  //   funcs: [],
  // });
  // const [addSelected, setAddSelected] = useState<any>({
  //   menusIds: [],
  //   funcsIds: [],
  //   menus: [],
  //   funcs: [],
  // });
  const [removeSelectedPartners, setRemoveSelectedPartners] = useState<any>({
    partnersIds: [],
    partners: [],
  });
  const [addSelectedPartners, setAddSelectedPartners] = useState<any>({
    partnersIds: [],
    partners: [],
  });
  // const menusColumns = [
  //   { title: 'Nome', render: (render: any) => render.name, key: 'name' },
  //   {
  //     title: 'Caminho',
  //     render: (render: any) => render.path,
  //     key: 'path',
  //   },
  // ];
  // const funcsColumns = [
  //   ...menusColumns,
  //   { title: 'Menu', render: (render: any) => render.menu.name },
  // ];
  const partnersColumns = [
    { title: 'Nome', render: (render: any) => render.name },
  ];
  const [newAccessLevel, setNewAccessLevel] = useState<number | undefined>();
  const accessLevelOptions = [
    { value: 0, label: 'Owner' },
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Client' },
    { value: 3, label: 'Viewer' },
  ];
  function campareUserPermissions(permissions: any, userPermissions: any): any {
    permissions?.map((data: any) =>
      userPermissions?.map((user: any) => {
        const index = permissions.findIndex((data: any) => data.id === user.id);
        if (index !== -1) {
          return permissions.splice(index, 1);
        }
        return false;
      }),
    );

    return permissions;
  }

  useEffect(() => {
    async function fetchUserData(): Promise<any> {
      setLoading(true);
      const response = await Promise.all([
        await getUser(params?.id),
        await getMenus(menuPagination),
        await getFunctionalities(funcPagination),
        await getPartners(partnerPagination),
      ]).then(responses => {
        return responses;
      });

      const userDetails = response[0].data;
      // const userFuncs = response[0].data.funcs;
      // const userMenus = response[0].data.menus;
      const userPartners = response[0].data.partners;
      // const allMenus = response[1].data;
      // const allFuncs = response[2].data;
      const allPartners = response[3].data;

      // const notHasMenus = await campareUserPermissions(allMenus, userMenus);
      // const notHasFuncs = await campareUserPermissions(allFuncs, userFuncs);
      const notHasPartners = await campareUserPermissions(
        allPartners,
        userPartners,
      );

      setUserData(userDetails);
      setPartnerPagination(response[3].pagination);
      // setPermissionsMenus(notHasMenus);
      // setPermissionsFuncs(notHasFuncs);
      setPermissionsPartners(notHasPartners);
      setLoading(false);
    }
    fetchUserData();
    // eslint-disable-next-line
  }, [permissionIsChanged]);

  // const handleMenuRemoveSelection = {
  //   onChange: (selectedRowKeys: any, selectedRows: any) => {
  //     setRemoveSelected({
  //       ...removeSelected,
  //       menusIds: selectedRowKeys,
  //       menus: selectedRows,
  //     });
  //   },
  // };

  // const handleFuncRemoveSelection = {
  //   onChange: (selectedRowKeys: any, selectedRows: any) => {
  //     setRemoveSelected({
  //       ...removeSelected,
  //       funcsIds: selectedRowKeys,
  //       funcs: selectedRows,
  //     });
  //   },
  // };

  // const handleMenuAddSelection = {
  //   onChange: (selectedRowKeys: any, selectedRows: any) => {
  //     setAddSelected({
  //       ...addSelected,
  //       menusIds: selectedRowKeys,
  //       menus: selectedRows,
  //     });
  //   },
  // };

  // const handleFuncAddSelection = {
  //   onChange: (selectedRowKeys: any, selectedRows: any) => {
  //     setAddSelected({
  //       ...addSelected,
  //       funcsIds: selectedRowKeys,
  //       funcs: selectedRows,
  //     });
  //   },
  // };

  const handlePartnersAddSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setAddSelectedPartners({
        ...addSelectedPartners,
        partnersIds: selectedRowKeys,
        partners: selectedRows,
      });
    },
  };

  const handlePartnersRemoveSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setRemoveSelectedPartners({
        ...removeSelectedPartners,
        partnersIds: selectedRowKeys,
        partners: selectedRows,
      });
    },
  };

  // async function handleRemovePermissions(): Promise<any> {
  //   setLoading(true);
  //   const data = {
  //     menusIds: removeSelected.menusIds,
  //     funcsIds: removeSelected.funcsIds,
  //   };

  //   const response = await userRemovePermission(params.id, data);
  //   if (response) {
  //     setRemoveSelected({
  //       menusIds: [],
  //       funcsIds: [],
  //       menus: [],
  //       funcs: [],
  //     });
  //     setPermissionIsChanged(!permissionIsChanged);
  //     setLoading(false);
  //     return appNotification(
  //       'success',
  //       'Permissionamento(s) removido(s) do usuário com sucesso.',
  //     );
  //   }
  //   setLoading(false);
  //   return appNotification(
  //     'error',
  //     'Ocorreu um erro ao tentar remover o(s) permissionamento(s) do usuário.',
  //   );
  // }

  // async function handleAddPermissions(): Promise<any> {
  //   setLoading(true);
  //   const data = {
  //     menusIds: addSelected.menusIds,
  //     funcsIds: addSelected.funcsIds,
  //   };

  //   const response = await userAddPermission(params.id, data);
  //   if (response) {
  //     setAddSelected({
  //       menusIds: [],
  //       funcsIds: [],
  //       menus: [],
  //       funcs: [],
  //     });
  //     setPermissionIsChanged(!permissionIsChanged);
  //     setLoading(false);
  //     return appNotification(
  //       'success',
  //       'Permissionamento(s) adicionado(s) ao usuário com sucesso.',
  //     );
  //   }
  //   setLoading(false);
  //   return appNotification(
  //     'error',
  //     'Ocorreu um erro ao tentar adicionar o(s) permissionamento(s) ao usuário.',
  //   );
  // }

  async function handleRemovePartnersPermissions(): Promise<any> {
    setLoading(true);
    const data = {
      partnersIds: removeSelectedPartners.partnersIds,
    };

    const response = await userRemovePartnersPermission(params.id, data);
    if (response) {
      setRemoveSelectedPartners({
        partnersIds: [],
        partners: [],
      });
      setPermissionIsChanged(!permissionIsChanged);
      setLoading(false);
      return appNotification(
        'success',
        'Parceiro(s) removido(s) do usuário com sucesso.',
      );
    }
    setLoading(false);
    return appNotification(
      'error',
      'Ocorreu um erro ao tentar remover o(s) parceiro(s) do usuário.',
    );
  }

  async function handleAddPartnersPermissions(): Promise<any> {
    setLoading(true);
    const data = {
      partnersIds: addSelectedPartners.partnersIds,
    };

    const response = await userAddPartnersPermission(params.id, data);
    if (response) {
      setAddSelectedPartners({
        partnersIds: [],
        partners: [],
      });
      setPermissionIsChanged(!permissionIsChanged);
      setLoading(false);
      return appNotification(
        'success',
        'Parceiro(s)  adicionado(s) ao usuário com sucesso.',
      );
    }
    setLoading(false);
    return appNotification(
      'error',
      'Ocorreu um erro ao tentar adicionar o(s) parceiro(s) ao usuário.',
    );
  }

  function findUserAccessLevel(): string | undefined {
    const accessLevel = accessLevelOptions.find(
      accessLevel => accessLevel.value === userData.accessLevel,
    );
    return accessLevel?.label;
  }

  /*  async function handleTableChange(paginationParam: any): Promise<void> {
    if (
      paginationParam.current !== partnerPagination.current ||
      paginationParam.pageSize !== partnerPagination.pageSize
    ) {
      setLoading(true);
      const newPagination = {
        current: paginationParam.current,
        pageSize: paginationParam.pageSize,
      };
      const response = await getPartners(newPagination);
      const userPartnerResponse = await getUser(params?.id);
      const userPartners = userPartnerResponse.data.partners;
      const allPartners = response.data;

      const notHasPartners = await campareUserPermissions(
        allPartners,
        userPartners,
      );

      setPermissionsPartners(notHasPartners);
      setPartnerPagination(response.pagination);
      setLoading(false);
    }
  }
*/
  async function handleUserAccessLevel(): Promise<any> {
    if (newAccessLevel !== undefined) {
      const response = await userAccessLevelChange(params.id, newAccessLevel);

      if (response) {
        setUserData({ ...userData, accessLevel: newAccessLevel });
        setNewAccessLevel(undefined);
        return appNotification(
          'success',
          'Nível de acesso alterado com sucesso.',
        );
      }
      return appNotification(
        'success',
        'Ocorreu um erro ao tentar alterar o nível de acesso.',
      );
    }
    return false;
  }

  return (
    <>
      <Header
        title={`Permissionamento usuário ${
          userData.name ? `- ${userData.name}` : ''
        }`}
        onBack={() => history.push('/permissions/options/users')}
      />
      <Descriptions title="Informações do usuário" bordered>
        <Descriptions.Item span={2} label="Nome">
          {userData.realName}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="E-mail">
          {userData.email}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Telefone">
          {userData.phone}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Status">
          <Badge
            status={userData.enable ? 'success' : 'warning'}
            text={userData.enable ? 'Ativo' : 'Inativo'}
          />
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Spin spinning={loading}>
        <PermissionsTitle>Permissionamentos</PermissionsTitle>
        <p>
          Nível de acesso:
          <Tag style={{ marginLeft: '1rem' }}> {findUserAccessLevel()} </Tag>
        </p>
        <label>Editar nível de Acesso</label>
        <Select
          placeholder="Escolha um nível de acesso.."
          value={newAccessLevel}
          style={{ width: '12rem', marginTop: '1rem', marginLeft: '1rem' }}
          onChange={(value: number) => setNewAccessLevel(value)}
        >
          {accessLevelOptions.map(accessLevel => (
            <Select.Option key={accessLevel.value} value={accessLevel.value}>
              {accessLevel.label}
            </Select.Option>
          ))}
        </Select>
        <Button
          style={{ marginLeft: '1rem' }}
          onClick={() => handleUserAccessLevel()}
        >
          Salvar
        </Button>
        <br />
        <br />
        <PermissionsTitle>Parceiros</PermissionsTitle>
        <PermissionsContainer>
          <PartnersContainer>
            <SelectedPartnersContainer>
              <p>{`${removeSelectedPartners.partners.length} selecionados`}</p>
              <Button onClick={handleRemovePartnersPermissions} size="small">
                Remover do usuário
              </Button>
            </SelectedPartnersContainer>
            <Table
              scroll={{ y: 240 }}
              title={() => 'Parceiros do usuário'}
              dataSource={userData.partners}
              columns={partnersColumns}
              // onChange={handlePermissionTable}
              pagination={false}
              // loading={state?.permissions?.loading}
              sortDirections={['ascend', 'descend', 'ascend']}
              rowSelection={{
                ...handlePartnersRemoveSelection,
              }}
              rowKey={(record: any) => record.id}
            />
          </PartnersContainer>
          <PartnersContainer>
            <SelectedPartnersContainer>
              <p>{`${addSelectedPartners.partners.length} selecionados`}</p>
              <Button onClick={handleAddPartnersPermissions} size="small">
                Adicionar ao usuário
              </Button>
            </SelectedPartnersContainer>
            <Table
              scroll={{ y: 240 }}
              title={() => 'Parceiros do sistema'}
              dataSource={permissionsPartners}
              columns={partnersColumns}
              // pagination={partnerPagination}
              // onChange={handleTableChange}
              sortDirections={['ascend', 'descend', 'ascend']}
              rowSelection={{
                ...handlePartnersAddSelection,
              }}
              rowKey={(record: any) => record.id}
            />
          </PartnersContainer>
        </PermissionsContainer>
        <br />
        <TableUserPermissions id={params?.id} />
        {/* <PermissionsTitle>Menus e funcionalidades do usuário.</PermissionsTitle>
        <SelectedContainer>
          <p>
            {`${
              removeSelected.menus.length + removeSelected.funcs.length
            } selecionados`}
          </p>
          <Button onClick={handleRemovePermissions} size="small">
            Remover do usuário
          </Button>
        </SelectedContainer>
        <PermissionsContainer>
          <Table
            scroll={{ y: 240 }}
            title={() => 'Menus'}
            dataSource={userData.menus}
            columns={menusColumns}
            // onChange={handlePermissionTable}
            pagination={false}
            // loading={state?.permissions?.loading}
            sortDirections={['ascend', 'descend', 'ascend']}
            rowSelection={{
              ...handleMenuRemoveSelection,
            }}
            rowKey={(record: any) => record.id}
          />
          <Table
            scroll={{ y: 240 }}
            title={() => 'Funcionalidades'}
            dataSource={userData.funcs}
            columns={funcsColumns}
            // onChange={handlePermissionTable}
            // pagination={state?.permissions?.pagination}
            // loading={state?.permissions?.loading}
            sortDirections={['ascend', 'descend', 'ascend']}
            rowSelection={{
              ...handleFuncRemoveSelection,
            }}
            rowKey={(record: any) => record.id}
          />
        </PermissionsContainer>

        <br />
        <PermissionsTitle>Menus e funcionalidades do sistema.</PermissionsTitle>
        <SelectedContainer>
          <p>
            {`${
              addSelected.menus.length + addSelected.funcs.length
            } selecionados`}
          </p>
          <Button onClick={handleAddPermissions} size="small">
            Adicionar ao usuário
          </Button>
        </SelectedContainer>
        <PermissionsContainer>
          <Table
            scroll={{ y: 240 }}
            title={() => 'Menus'}
            dataSource={permissionsMenus}
            columns={menusColumns}
            // onChange={handlePermissionTable}
            pagination={false}
            // loading={state?.permissions?.loading}
            sortDirections={['ascend', 'descend', 'ascend']}
            rowSelection={{
              ...handleMenuAddSelection,
            }}
            rowKey={(record: any) => record.id}
          />
          <Table
            scroll={{ y: 240 }}
            title={() => 'Funcionalidades'}
            dataSource={permissionsFuncs}
            columns={funcsColumns}
            // onChange={handlePermissionTable}
            // pagination={state?.permissions?.pagination}
            // loading={state?.permissions?.loading}
            sortDirections={['ascend', 'descend', 'ascend']}
            rowSelection={{
              ...handleFuncAddSelection,
            }}
            rowKey={(record: any) => record.id}
          />
        </PermissionsContainer> */}
      </Spin>
    </>
  );
};

export default UserPermissions;
