import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  getPartnersArrangementsBalance,
  getPartnersBalance,
  getPartnersPixBalance,
  GetPartnersProps,
} from '../../../../api/BalanceConsultService';
import * as S from '../style';
import { Card } from './card';

export const BalanceArrangements: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [partnersBalance, setPartnersBalance] = useState<GetPartnersProps>();
  const [partnersPix, setPartnersPix] = useState<GetPartnersProps>();
  const [partnersArrangement, setPartnersArrangement] = useState<
    GetPartnersProps
  >();
  useEffect(() => {
    const getPartners = async (): Promise<void> => {
      setLoading(true);
      try {
        const partnersBalance = await getPartnersBalance(100, 0);
        const partnersPix = await getPartnersPixBalance(100, 0);
        const partnersArrangements = await getPartnersArrangementsBalance(
          100,
          0,
        );
        setPartnersBalance(partnersBalance?.data);
        setPartnersPix(partnersPix?.data);
        setPartnersArrangement(partnersArrangements.data);
      } catch (err) {
        notification.error({
          message: 'Erro!',
          description: 'Algo de errado aconteceu...!.',
        });
      } finally {
        setLoading(false);
      }
    };

    getPartners();
  }, []);

  return (
    <S.Container>
      <div className="cards-container">
        <Card
          title="Saldo de Cartão"
          type="cartao"
          partnersBalance={partnersBalance}
          loading={loading}
        />
        <Card
          title="Saldo Pix"
          type="pix"
          partnersPix={partnersPix}
          loading={loading}
        />
        <Card
          title="Saldo de Arranjos"
          type="arranjo"
          partnersArrangement={partnersArrangement}
          loading={loading}
        />
      </div>
    </S.Container>
  );
};
