/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, DatePicker, Select, Spin } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import {
  ApprovePayment,
  ContentProps,
  DeletePayment,
  Filters,
  GetPaymentProps,
  getPayments,
} from '../../../api/PaymentConsultService';
import appNotification from '../../../components/notification';
import { Pagination, TablePayments } from './components/tablePayments';
import * as S from './style';

moment.locale('pt-br');

const ModalPayment = lazy(() => {
  return import(
    '../../Conciliation/Balance-Consult/components/modalPayment'
  ).then(({ ModalPayment }) => ({ default: ModalPayment }));
});

const ModalReproved = lazy(() => {
  return import('./components/modalReproved').then(({ ModalReproved }) => ({
    default: ModalReproved,
  }));
});

type ModalDetailProps = {
  open: boolean;
  row?: ContentProps;
};

const initialFilters: Filters = {
  transactionDate: null,
  createdAt: null,
  cardPaymentStatus: 3,
};

export const PaymentConsultCard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [data, setData] = useState<GetPaymentProps>();
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState<ModalDetailProps | undefined>({
    open: false,
    row: undefined,
  });
  const [modalReproved, setModalReproved] = useState({
    open: false,
    partnerName: '',
    transactionId: '',
  });
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState<Filters>();
  const [temporaryFilters, setTemporaryFilters] = useState(initialFilters);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const GetPayments = async (): Promise<void> => {
      try {
        setLoading(true);
        const response = await getPayments(
          pagination.pageSize || 10,
          pagination.current - 1,
          filters,
        );
        setPagination({ ...pagination, total: response.data.size });
        setData(response.data);
      } catch (err) {
        appNotification('error', 'Algo de errado aconteceu...!');
      } finally {
        setLoading(false);
      }
    };
    GetPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize, updateTable, filters]);
  const openModal = (): void => {
    setModal(true);
  };

  const openModalDetail = useCallback((row: ContentProps): void => {
    setModalDetail({
      open: true,
      row,
    });
  }, []);

  const openModalReproved = useCallback(
    (partnerName: string, transactionId: string): void => {
      setModalReproved({ open: true, partnerName, transactionId });
    },
    [],
  );

  const closeModalModalPayment = useCallback((): void => {
    setModalDetail({ open: false, row: undefined });
  }, []);

  const updatingTable = (): void => setUpdateTable(state => !state);

  const approvePayment = useCallback(async (transactionId: string): Promise<
    void
  > => {
    try {
      setLoading(true);
      await ApprovePayment(transactionId);
      appNotification('success', 'Pagamento aprovado com sucesso!');
      updatingTable();
    } catch (err) {
      appNotification('error', 'Algo de errado aconteceu...!');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePayment = useCallback(async (transactionId: string): Promise<
    void
  > => {
    try {
      setLoading(true);
      await DeletePayment(transactionId);
      appNotification('success', 'Pagamento Deletado com sucesso!');
      updatingTable();
    } catch (err) {
      appNotification('error', 'Algo de errado aconteceu...!');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTransactiondate = (dateTime: string[]): void => {
    let filter = { ...temporaryFilters };
    filter = { ...temporaryFilters, transactionDate: dateTime };
    const dateValues = dateTime.filter(f => f !== '').length;
    const dateEmpty = dateTime.filter(f => f === '').length;
    if (dateValues >= 2) {
      setTemporaryFilters(filter);
      setFilters(filter);
    } else if (dateEmpty === 2) {
      filter = { ...temporaryFilters, transactionDate: null };
      setTemporaryFilters(filter);
      setFilters(filter);
    }
  };
  const onChangeCreatedAt = (dateTime: string[]): void => {
    let filter = { ...temporaryFilters };
    filter = { ...temporaryFilters, createdAt: dateTime };
    const dateValues = dateTime.filter(f => f !== '').length;
    const dateEmpty = dateTime.filter(f => f === '').length;
    if (dateValues >= 2) {
      setTemporaryFilters(filter);
      setFilters(filter);
    } else if (dateEmpty === 2) {
      filter = { ...temporaryFilters, createdAt: null };
      setTemporaryFilters(filter);
      setFilters(filter);
    }
  };

  const onChangeStatus = (value: number): void => {
    let filter = temporaryFilters;
    filter = {
      ...temporaryFilters,
      cardPaymentStatus: value,
    };
    setTemporaryFilters(filter);
    setFilters(filter);
  };

  const options = [
    {
      value: 3,
      label: 'Todos',
    },
    {
      value: 0,
      label: 'Pendente',
    },
    {
      value: 1,
      label: 'Aprovado',
    },
    {
      value: 2,
      label: 'Reprovado',
    },
  ];

  const partnerId = data?.content[0]?.partner?.id;

  return (
    <S.Container>
      <div className="filters">
        <div className="label-group">
          <label> Data de Cadastro </label>
          <RangePicker
            format="YYYY-MM-DD"
            placeholder={['Data Inicial', 'Data Final']}
            allowClear
            allowEmpty={[false, true]}
            onChange={(date, dateString) => onChangeCreatedAt(dateString)}
            locale={locale}
          />
        </div>
        <div className="label-group">
          <label> Data de Transferência </label>
          <RangePicker
            format="YYYY-MM-DD"
            placeholder={['Data Inicial', 'Data Final']}
            allowClear
            allowEmpty={[false, true]}
            onChange={(date, dateString) => onChangeTransactiondate(dateString)}
            locale={locale}
          />
        </div>
        <div className="label-group">
          <label> Selecione um status: </label>
          <Select
            options={options}
            onChange={onChangeStatus}
            value={
              options.find(f => f.value === filters?.cardPaymentStatus)
                ?.value || options[0].value
            }
          />
        </div>
        {partnerId && (
          <Button onClick={openModal} style={{ marginLeft: 'auto' }}>
            Comprovar Pagamento
          </Button>
        )}
      </div>
      <TablePayments
        loading={loading}
        approvePayment={approvePayment}
        deletePayment={deletePayment}
        openModalDetail={openModalDetail}
        openModalReproved={openModalReproved}
        data={data}
        pagination={pagination}
        setPagination={setPagination}
      />
      <Suspense fallback={<Spin />}>
        <>
          {modal && (
            <ModalPayment
              visible={modal}
              onCancel={() => setModal(false)}
              updateTable={updatingTable}
              partnerid={partnerId}
            />
          )}
          {modalDetail && (
            <ModalPayment
              visible={modalDetail.open}
              onCancel={closeModalModalPayment}
              detail
              row={modalDetail.row}
              updateTable={updatingTable}
            />
          )}
          {modalReproved && (
            <ModalReproved
              visible={modalReproved.open}
              onCancel={() => {
                setModalReproved({
                  open: false,
                  partnerName: '',
                  transactionId: '',
                });
              }}
              partnerName={modalReproved.partnerName}
              transactionId={modalReproved.transactionId}
              updateTable={updatingTable}
            />
          )}
        </>
      </Suspense>
    </S.Container>
  );
};
