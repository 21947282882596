import { Table } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .container-tables {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  .selecteds {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export const TableContainer = styled(Table)`
  .ant-table-thead .ant-table-cell {
    padding: 10px;
  }
  .ant-table-cell {
    padding: 10px 5px;
  }
`;

export const SelectedsContainer = styled.div`
  background-color: #aaa3ad;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    margin: 0 1rem;
  }
  button {
    margin-right: 1rem;
    text-align: center;
  }

  .buttons {
    button {
      margin: 0;
    }
    display: flex;
    gap: 10px;
    margin-right: 1rem;
    text-align: center;
  }
`;
