import { httpGet, httpPost } from '../services/http/index.js';
import { formatCurrencyIntl } from '../utils/format-currency-Intl.js';
import { formatDateIntl } from '../utils/formatDateIntl.js';
import { translationPaymentStatus } from '../utils/translations/payment-status.js';
import { conciliation } from './config/consts';

export interface ContentProps {
  id: string;
  value: string;
  partner: {
    id: string;
    name: string;
  };
  cardPaymentStatus: string;
  registerDate: string;
  transactionDate: string;
  rejectionMotive?: string;
  statusChangeDate?: string;
  receipt: string;
}

export interface GetPaymentProps {
  content: ContentProps[];
  size: number;
}

interface GetPaymentDataProps {
  data: GetPaymentProps;
}

export interface CreatePaymentProps {
  receipt: string;
  partnerid: string;
  transactionDate: string;
  value: number;
}

export interface UpdatePaymentProps {
  transactionId: string;
  partnerid: string;
  value?: number;
  receipt?: string;
  transactionDate?: string;
}

export interface ReprovePaymentProps {
  transactionId: string;
  reprovalMotive: string;
}

export interface Filters {
  transactionDate: string[] | null;
  createdAt: string[] | null;
  cardPaymentStatus: number;
}

export const getPayments = async (
  take: number,
  page: number,
  filters: Filters | undefined,
): Promise<GetPaymentDataProps> => {
  const params = {
    take,
    page,
    transactionDate: filters?.transactionDate
      ? `[${filters?.transactionDate
          ?.map(date => `"${date} 00:00:00"`)
          .join(',')}]`
      : null,
    createdAt: filters?.createdAt
      ? `[${filters?.createdAt?.map(date => `"${date}"`).join(',')}]`
      : null,
    cardPaymentStatus: filters?.cardPaymentStatus || null,
  };

  const sanitizedParams = Object.entries(params).reduce(
    (acc, [key, value]) =>
      value === null || value === 3 ? acc : { ...acc, [key]: value },
    {},
  );

  const query = decodeURI(new URLSearchParams(sanitizedParams).toString());
  const endpoint = `${conciliation}/v1beta1/card-payment-request?${query}`;

  const response = (await httpGet(endpoint)) as GetPaymentDataProps;
  const data = response.data.content.map(item => ({
    ...item,
    registerDate: item.registerDate
      ? (formatDateIntl(item.registerDate) as string)
      : '--',
    transactionDate: item.transactionDate
      ? (formatDateIntl(item.transactionDate) as string)
      : '--',
    value: item.value ? (formatCurrencyIntl(item.value) as string) : '--',
    cardPaymentStatus: translationPaymentStatus(
      item.cardPaymentStatus,
    ) as string,
    rejectionMotive: item.rejectionMotive ? item.rejectionMotive : undefined,
    statusChangeDate: item.statusChangeDate
      ? (formatDateIntl(item.statusChangeDate) as string)
      : undefined,
    receipt: item.receipt || '--',
  }));

  return { data: { content: data, size: response.data.size } };
};

export const CreatePayment = async (
  payload: CreatePaymentProps,
): Promise<void> => {
  const endpoint = `${conciliation}/v1beta1/card-payment-request/create-payment`;

  await httpPost(endpoint, payload);
};

export const UpdatePayment = async (
  payload: UpdatePaymentProps,
): Promise<void> => {
  const endpoint = `${conciliation}/v1beta1/card-payment-request/update-payment`;

  await httpPost(endpoint, payload);
};

export const ApprovePayment = async (payload: string): Promise<void> => {
  const endpoint = `${conciliation}/v1beta1/card-payment-request/approve-payment`;

  await httpPost(endpoint, { transactionId: payload });
};

export const ReprovePayment = async (
  payload: ReprovePaymentProps,
): Promise<void> => {
  const endpoint = `${conciliation}/v1beta1/card-payment-request/recuse-payment`;

  await httpPost(endpoint, payload);
};

export const DeletePayment = async (payload: string): Promise<void> => {
  const endpoint = `${conciliation}/v1beta1/card-payment-request/delete-payment`;

  await httpPost(endpoint, { transactionId: payload });
};
