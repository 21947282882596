import React from 'react';
import { Col, Row } from 'antd';
import { CustomCard } from './style';

interface Cards {
  colGrid: number;
  title: string;
  content: React.ReactNode;
  router: string;
}

interface Props {
  spaceBetween: any;
  handleClick: any;
  cards: Array<Cards>;
}

const ComponentCard: React.FC<Props> = ({
  cards,
  spaceBetween,
  handleClick,
}: Props) => {
  return (
    <>
      <Row gutter={spaceBetween}>
        {cards?.map(card => (
          <Col key={card.title} span={card.colGrid}>
            <CustomCard
              hoverable
              onClick={() => handleClick(card.router)}
              title={card.title}
              bordered
            >
              {card.content}
            </CustomCard>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ComponentCard;
