export const translationPaymentStatus = value => {
  const translations = [
    {
      value: 0,
      label: 'Pendente',
    },
    { value: 1, label: 'Aprovado' },
    { value: 2, label: 'Reprovado' },
  ];

  const result = translations.find(t => t.value === value);
  return result.label;
};
