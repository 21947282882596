
export function formatCnpjCpf(value)
{

  if(value === undefined){
      return false;
  }

  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    // eslint-disable-next-line
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
  }
  // eslint-disable-next-line
  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
}

export function cleanCnpjCpf(value){
  let replaced = '';
  if(value !== undefined){

    replaced = value.replace(/[^0-9]/g, "")
  }

  return replaced;
}

