import React from 'react';
import { Header, Styles } from './style';

interface Props {
  onBack: any;
  title: string;
  subtitle?: string;
}

const ComponentHeader: React.FC<Props> = ({
  title,
  subtitle,
  onBack,
}: Props) => {
  return (
    <Styles>
      <Header onBack={onBack} title={title} subTitle={subtitle} />
    </Styles>
  );
};

export default ComponentHeader;

ComponentHeader.defaultProps = {
  subtitle: '',
};
