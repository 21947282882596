import { httpGet, httpPost, httpPut } from '../services/http/index.js';

import { account } from './config/consts';

interface reset {
  email: string
}

interface verifyAccount{
  hash: string
  password: string
  confirmPassword: string
}

export async function forgetPassword(email: reset) {
  try {
    await httpPost(`${account}/v1beta1/auth/forget-password`, email);
    
    return{
      status: true,
      message: 'Em breve você vai receber um e-mail com instruções para recuperação de senha.'
    }
  } catch (error) {
    let message = '';
    
    if(error.response.status === 404){
      message = 'Não existe nenhum usuário cadastrado com o e-mail fornecido.';
    }
    else
    {
      message = 'Erro ao recuperar senha, contate um administrador.';
    }
    
    return {
      status: false,
      message
    };
  }
}
export async function resetPassword(payload: verifyAccount): Promise<any> {
  try {
    console.log(payload);
    await httpPut(`${account}/v1beta1/auth/reset-password`, payload);
    
    return {
      status: true,
      message: 'Senha alterada, gentileza fazer o login.',
    };
    
  } catch (error) {
    return {
        status: false,
        message: 'Erro ao alterar senha.',
    };
  }
}

export async function checkHash(hash: string){
    try {
      await httpGet(`${account}/v1beta1/auth/check-hash/${hash}`);
      
      return {
        status: true,
        message: 'Senha alterada, gentileza fazer o login.',
      };
    } catch (error) {
      
        return {
          status: false,
          message: 'Link expirado, gentileza solicitar um novo e-mail pelo portal.',
        };
    }
}

export async function securityAlert(hash: string){
  try {
    await httpPut(`${account}/v1beta1/auth/security-alert/${hash}`);
    
    return {
      status: true,
      message: 'Conta bloqueada, gentileza entrar em contato com o suporte da U4crypto',
    };
    
  } catch (error) {
    return {
        status: false,
        message: 'Erro ao bloquear conta.',
    };
  }
}

export async function verifyEmail(payload: verifyAccount){
  try {
    await httpPut(`${account}/v1beta1/auth/verify-email`, payload);
    
    return {
      status: true,
      message: 'Senha criada, gentileza fazer o login.',
    };
    
  } catch (error) {
    return {
        status: false,
        message: 'Erro ao verificar a conta, gentileza entrar em contato com um administrador.',
    };
  }
}

export async function resendEmail(userId: string)
{
  try {
    await httpPut(`${account}/v1beta1/user/${userId}/resend-verify-email`);
    
    return {
      status: true,
      message: 'E-mail de confirmação enviado, solicite ao usuário que cheque sua caixa de e-mail.',
    };
    
  } catch (error) {
    return {
        status: false,
        message: 'Erro ao enviar o e-mail, gentileza entrar em contato com um administrador.',
    };
  }
}