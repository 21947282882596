import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';

import promiseMiddleware from 'redux-promise';
import thunk from 'redux-thunk';
import multi from 'redux-multi';

import reducers from './reducers';

interface Props {
  children: React.ReactNode;
}

const Store: React.FC<Props> = (children: Props) => {
  const middlewares = [thunk, multi, promiseMiddleware];

  const store = createStore(reducers, applyMiddleware(...middlewares));

  return <Provider store={store}>{children.children}</Provider>;
};

export default Store;
