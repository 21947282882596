/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Button, Spin, Modal, Card } from 'antd';
import {
  FaAngleDown,
  FaAngleUp,
  FaFileDownload,
  FaRegEye, FaRegWindowClose,
} from 'react-icons/fa';
import { isEmpty } from 'lodash';
import PeriodPicker from '../../../../containers/Period-picker';
import AppNotification from '../../../../components/notification';
import { 
  exportExternalTransactions,
  getTransactionsExternal, getTransactionsType,
} from '../../../../api/TransactionService';
import { translateTransactionsType } from '../../../../utils/translations/transactions-types.js';
import { formatCnpjCpf } from '../../../../utils/format-documents.js';
import { formatCurrency } from '../../../../utils/format-currency.js';
import { ActionsContainer, CardContainer, Code} from './style';
import { setContext } from '../../../../store/actions/transactions';
import Dashboard from './Dashboard';
import moment from 'moment';
import TableFilter from '../../../../containers/Table-filter';
import { getPartnersAsOptions } from '../../../../api/PartnerService';
import { normalizeFilters } from '../../../../utils/normalize-filters';
import { softGrey } from '../../../../config';

interface FilterDropDown {
  setSelectedKeys: () => void;
  selectedKeys: Array<any> | string;
  confirm: () => void;
  clearFilters: () => void;
}

interface Props {
  context: 'transaction-wallet';
  reduxContext?: any;
  setContext?: any;
}

interface Data {
  status: string;
  tipoTransacao: string;
  cpfCnpjCorrentista: string;
  nomeCorrentista: string,
  cpfCnpjPagador: string;
  cpfCnpjRecebedor: string;
  numeroDocumento: string;
  valorTransacao: number;
  dataTransacao: Date;
  descricaoTransacao: String;
  codigoBancoDestinatario: string;
  agenciaBancoDestinatario: string;
  contaCorrenteDestinatario: string;
  dataAtualizacao: Date;
  taxOperation: number;
  nomeParceiro: String;
  idParceiro: string;
  conta: string;
  autoriz: string;
}

interface totalizers{
  data: Data,
  pagination: {
    current: number,
    pageSize: number,
    position: string,
    total: number,
  }
  totalizers:{
    value: number;
  }
}


const External: React.FC<Props> = ({
  reduxContext,
  setContext,
}: Props) => {
  const [progressPeding, setProgressPeding] = useState(false);
  const [walletDetails, setWalletDetails] = useState<any>({
    status: false,
    data: {},
  });
  const [exportLoading] = useState(false);  
  const [totalizersVisibility, setTotalizersVisibility] = useState(true);
  const [transactionsType, setTransactionsType] = useState([
    { value: null, label: 'carregando...' },
  ]);
  const [data, setData] = useState<Array<Data>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    position: ['topRight' as const],
  });
  const [filterPeriod, setFilterPeriod] = useState<string | undefined>('');
  const [filtersNormalized, setFiltersNormalized] = useState({});
  const [filters, setFilters] = useState<any>({});  
  const [total, setTotal] = useState(0);
  const [partners, setPartners] = useState([
    { value: null, label: 'carregando...' },
  ]);
  
  async function fetchPartners(): Promise<void> {
    if (partners.length > 2) {
      return;
    }
    const response = await getPartnersAsOptions();
    setPartners(response.data);
  }
  
  async function fetchTransactionsType(): Promise<void> {
    if (transactionsType.length > 2) {
      return;
    }
    const response = await getTransactionsType();
    setTransactionsType(response);
  }

  function getColumnSearchProps(
    dataIndex: string,
    filterType: string,
    filterMode: 'multiple' | 'tags' | undefined,
    filterOptions: Array<any> | undefined,
    FilterSelectOnFocus: any,
    onSearch: any,
  ): any {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropDown) => {
        return (
          <TableFilter
            dataIndex={dataIndex}
            filterType={filterType}
            filterMode={filterMode}
            filterOptions={filterOptions}
            FilterSelectOnFocus={FilterSelectOnFocus}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            onSearch={onSearch}
            clearFilters={clearFilters}
            clearSingleFilter={clearSingleFilter}
          />
        );
      },
    };
  }
  
  function handleWalletDetails(details: Data): void {
    setWalletDetails({ status: true, data: JSON.stringify(details, undefined, 2) });
  }

  async function clearFilters(): Promise<void> {
    setProgressPeding(true);
    setFilters({});
    setFiltersNormalized({});

    const response = await getTransactionsExternal({
      pagination,
      filters: {},
      date: filterPeriod,
    });

    const roleList = response.data.map((element) => JSON.parse(element.data));

    setData(roleList);
    setPagination({
      current: 1,
      pageSize: 50,
      total: response.pagination.total,
      position: ['topRight' as const],
    });

    setContext({
      totalizers: response.totalizers,
      total: response.pagination.total,
      period: filterPeriod,
      filters: {},
      dashboardVisibility: false,
    });
    setProgressPeding(false);
  }

  async function clearSingleFilter(columnKey: string): Promise<void> {
    setProgressPeding(true);

    const split = columnKey.split('_');
    const filterDelete: any = { ...filtersNormalized };

    if (split.length > 1) {
      delete filterDelete[split[0]];
      delete filterDelete[split[1]];
    } else {
      delete filterDelete[columnKey];
    }

    setFiltersNormalized(filterDelete);

    const response = await getTransactionsExternal({
      pagination,
      filters: filterDelete,
      date: filterPeriod,
    });

    const roleList = response.data.map((element) => JSON.parse(element.data));

    setData(roleList);

    if (isEmpty(filterDelete)) {
      setPagination({
        current: 1,
        pageSize: 50,
        total: response.pagination.total,
        position: ['topRight' as const],
      });
    } else {
      setPagination(response.pagination);
    }
    setProgressPeding(false);
  }

  async function handleExportTransactions(): Promise<void> {
    setProgressPeding(true);
    const response = await exportExternalTransactions({
      filters: filtersNormalized,
      date: filterPeriod,
    });

    if (response.status && response.email === true) {
      AppNotification('success', response.message);
    }

    if (response.status && response.email === false) {
      window.location.href = response.url;
      AppNotification('success', response.message);
    }

    if (response.status === false) {
      AppNotification('error', response.message);
    }

    setProgressPeding(false);
  }
  async function fetchTransactions(dataPeriod: string): Promise<void> {
    setProgressPeding(true);

    setFilterPeriod(dataPeriod);

    const newPagination = {
      current: 1,
      pageSize: 50,
      total: 0,
      position: ['topRight' as const],
    };
    const response = await getTransactionsExternal({
      pagination: newPagination,
      filters: filtersNormalized,
      date: dataPeriod,
    });


    if (response.data.length > 0) 
    { 
      const roleList = response.data.map((element) => JSON.parse(element.data));
      setPagination(response.pagination);
      setData(roleList);

      setContext({
        type: 'external',
        total: response.pagination.total,
        totalizers: response.totalizers,
        period: dataPeriod,
        dashboardVisibility: false,
      });
    }
    setTotal(response.pagination.total);
    setProgressPeding(false);
  }

  async function handleTableChange(
    paginationParam: any,
    filterParam: any    
  ): Promise<void> {

    const newFilters: any = {};
    const filterForApi: any = {};

    const convertIntoArray = Object.entries(filterParam);

    convertIntoArray.map(filterValue => {
      if (filterValue[1] !== null) {
        const key = filterValue[0]?.split('_');
        // eslint-disable-next-line prefer-destructuring
        newFilters[key[0]] = filterValue[1];
        filterForApi[key[0]] = normalizeFilters(filterValue);

        if (key.length > 2) {
          if (filterForApi[key[0]] === 'checked') {
            delete filterForApi[key[0]];
            filterForApi[key[2]] = 0;
          } else {
            delete filterForApi[key[2]];
            if (filterForApi[key[0]].length > 0)
              filterForApi[key[0]].splice(2, 1);
          }
        }

        if (isEmpty(filterForApi[key[0]])) {
          delete filterForApi[key[0]];
        }
      }
      return false;
    });

    setFilters(newFilters);

    if (!isEmpty(newFilters)) {
      setProgressPeding(true);
      setFiltersNormalized(filterForApi);

      const response = await getTransactionsExternal({
        pagination: paginationParam,
        filters: filterForApi,
        date: filterPeriod
      });
      const roleList = response.data.map((element) => JSON.parse(element.data));

      setData(roleList);
      setPagination(response.pagination);
      setProgressPeding(false);
    }

    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPeding(true);
      const response = await getTransactionsExternal({
        pagination: paginationParam,
        filters: filterForApi,        
        date: filterPeriod,        
      });

      const roleList = response.data.map((element) => JSON.parse(element.data));

      setPagination(response.pagination);
      setData(roleList);
      setProgressPeding(false);

      setContext({
        type: 'external',
        total: response.pagination.total,
        totalizers: response.totalizers,
        period: filterPeriod,
        dashboardVisibility: false,
      });      
      return;
    }    
  }

  const cards = [
    {
      colGrid: 4,
      title: 'Valor transacionado',
      content: formatCurrency(reduxContext.totalizers?.value),
      router: 'total_value',
    },
    {
      colGrid: 4,
      title: 'Transações',
      content: reduxContext?.total,
      router: 'total_revenue',
    },
  ];

  const columns = [
    {
      title: 'Detalhes wallet',
      align: 'center' as const,
      render: (render: Data) => (
        <Button
          onClick={() => handleWalletDetails(render)}
          icon={<FaRegEye color={softGrey} />}
        />
      ),
    },
    {
      render: (render: Data) =>
        translateTransactionsType(render.tipoTransacao),
      title: 'Tipo de Transação',
      key: 'type',
      filteredValue: filters.type || null,
      ...getColumnSearchProps(
        'type',
        'select',
        'multiple',
        transactionsType,
        fetchTransactionsType,
        undefined,
      ),
    },
    {
      render: (render: Data) =>
      moment(render.dataTransacao).utc().format('DD-MM-YYYY HH:mm:ss'),
      title: 'Data Transação',
      key: 'date_orderTransactionDate',     
    },
    {
      render: (render: Data) => formatCnpjCpf(render.cpfCnpjCorrentista),
      title: 'Documento origem',
      key: 'sourceCustomer',
      filteredValue: filters.sourceCustomer || null,
      ...getColumnSearchProps(
        'sourceCustomer',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      title: 'Nome Origem',
      key: 'sourceName',
      name: 'Nome Origem da Transação',
      dataIndex: ['nomeCorrentista'],         
    },
    {
      title: 'Parceiro',
      name: 'Parceiro',
      key: 'partner',
      dataIndex: ['nomeParceiro'],
      filteredValue: filters.partner || null,
      align: 'center' as const,
      ...getColumnSearchProps(
        'partner',
        'select',
        'multiple',
        partners,
        fetchPartners,
        undefined,
      ),
    },
    {
      title: 'Autorizador',
      key: 'autoriz',
      name: 'Parceiro',
      dataIndex: ['autoriz'],   
      filteredValue: filters.autoriz || null,
      ...getColumnSearchProps(
        'autoriz',
        'select',
        'tags',
        undefined,
        undefined,
        undefined,
      ),      
    },
    {
      render: (render: Data) => formatCurrency(render.valorTransacao),
      title: 'Valor da Transação',
      key: 'valueBetween_orderValue_valueLowerThen',
      filteredValue: filters.valueBetween || null,
      align: 'right' as const,
      ...getColumnSearchProps(
        'valueBetween_valueLowerThen',
        'number',
        undefined,
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      render: (render: Data) => formatCurrency(render.taxOperation),
      title: 'Taxa sobre a Operação',
      key: 'taxOperation',
      align: 'right' as const,
    },
    {
      render: (render: Data) =>
      moment(render.dataAtualizacao).utc().format('DD-MM-YYYY HH:mm:ss'),
      title: 'Data de Criação',
      key: 'createdAt',       
      filteredValue: filters.date || null
    }
  ];
  

  if (reduxContext.dashboardVisibility) {
    return <Dashboard total={total} />;
  }

  return (
    <>
      <Spin spinning={exportLoading}>
        <PeriodPicker bringTransactions={fetchTransactions} />
        {filterPeriod && (
          <div>          
            <ActionsContainer>
              <Button
                icon={
                  <FaRegWindowClose
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={clearFilters}
              >
                Limpar todos os filtros
              </Button>
              <Button
                icon={
                  <FaFileDownload
                    color="#4C2D6E"
                    style={{ marginRight: '10px' }}
                  />
                }
                onClick={handleExportTransactions}
              >
                Exportar dados
              </Button>
            <Button
                icon={
                  totalizersVisibility ? (
                    <FaAngleUp style={{ marginRight: '10px' }} />
                  ) : (
                    <FaAngleDown style={{ marginRight: '10px' }} />
                  )
                }
                onClick={() => setTotalizersVisibility(!totalizersVisibility)}
              >
                {totalizersVisibility
                  ? 'Esconder Totalizadores'
                  : 'Mostrar Totalizadores'}
            </Button>      
            </ActionsContainer> 
            {totalizersVisibility && (
              <CardContainer>
                {cards.map(card => (
                  <Card title={card.title}>{card.content}</Card>
                ))}
              </CardContainer>
            )}           
            <Table
              dataSource={data}
              columns={columns}
              onChange={handleTableChange}
              pagination={pagination}
              sortDirections={['ascend', 'descend', 'ascend']}
              bordered
              loading={progressPeding}
            />
            <Modal
              onCancel={() =>
                setWalletDetails({...walletDetails, status: false})
              }
              cancelText="Sair"
              onOk={() =>
                setWalletDetails({...walletDetails, status: false})
              }
              visible={walletDetails.status}
            >
              <Code>{walletDetails.data}</Code>
            </Modal>
          </div>
        )}
      </Spin>
    </>
  );
};

const mapStateToProps = (state: any): any => ({
  reduxContext: state.Transactions.context,
});

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ setContext }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(External);
