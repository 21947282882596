import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Drawer,
  Form,
  Spin,
  Button,
  Row,
  Col,
  Input,
  Select,
  Switch,
} from 'antd';
import { SwitchProps } from 'antd/lib/switch/index.js';
import { FaEdit } from 'react-icons/fa';
import {
  getFunctionalities,
  addFunctionality,
  editFunctionality,
  changeFunctionalityStatus,
} from '../../../api/FunctionalitiesService';
import { getMenus } from '../../../api/MenusService';
import Header from '../../../components/Header';
import appNotification from '../../../components/notification';

interface SwitchComponent extends SwitchProps {
  name?: string;
}

const Functionalities: React.FC<SwitchComponent> = ({
  name,
}: SwitchComponent) => {
  const history = useHistory();
  const [progressPeding, setProgressPending] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [menus, setMenus] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const columns = [
    {
      title: 'Nome',
      render: (render: any) => render.name,
      sorter: true,
    },
    {
      title: 'Caminho',
      render: (render: any) => render.path,
      sorter: true,
    },
    {
      title: 'Icone',
      render: (render: any) => render.icon,
      sorter: true,
    },
    {
      title: 'Menu',
      render: (render: any) => render.menu.name,
      sorter: true,
    },
    {
      title: 'Status',
      render: (render: any) => (
        <Switch
          {...name}
          name={render.id}
          defaultChecked={render.enable}
          checked={statusChecked[render.id]}
          onChange={() => handleStatusChecked(render.enable, render.id)}
        />
      ),
    },
    {
      title: 'Editar',
      render: (render: any) => (
        <Button
          onClick={
            () =>
              setDrawerData({
                ...drawerData,
                status: true,
                action: 'Editar',
                initialValues: {
                  id: render.id,
                  name: render.name,
                  path: render.path,
                  icon: render.icon,
                  enable: render.enable,
                  menuId: render.menu.id,
                  menuName: render.menu.name,
                },
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <FaEdit color="#4C2D6E" />
        </Button>
      ),
    },
  ];

  const [drawerData, setDrawerData] = useState({
    status: false,
    initialValues: {
      id: '',
      name: '',
      path: '',
      icon: '',
      enable: '',
      menuId: '',
      menuName: '',
    },
    action: '',
  });
  const [statusChecked, setStatusChecked] = useState<any>({});
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchFunctionalities(): Promise<any> {
      setProgressPending(true);
      const response = await getFunctionalities(pagination);
      setData(response.data);
      setPagination(response.pagination);
      setProgressPending(false);
    }

    fetchFunctionalities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchMenus(): Promise<any> {
      const MenuPagination = {
        current: 1,
        pageSize: 100,
      };

      const response = await getMenus(MenuPagination);
      setMenus(response.data);
      setPagination(response.pagination);
    }
    fetchMenus();
    // eslint-disable-next-line
  }, []);

  async function handleTableChange(paginationParam: any): Promise<void> {
    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPending(true);
      const newPagination = {
        current: paginationParam.current,
        pageSize: paginationParam.pageSize,
      };
      const response = await getFunctionalities(newPagination);
      setData(response.data);
      setPagination(response.pagination);
      setProgressPending(false);
    }
  }

  async function handleSubmit(values: any): Promise<any> {
    setLoadingSubmit(true);
    if (drawerData.action === 'Adicionar') {
      const response = await addFunctionality(values);
      if (response.status) {
        setData([...data, response.data]);
        setPagination({ ...pagination, total: pagination.total + 1 });
        setDrawerData({ ...drawerData, status: false });
        setLoadingSubmit(false);
        return appNotification('success', response.message);
      }
      setLoadingSubmit(false);
      return appNotification('error', response.message);
    }

    let editedFunctionality = {
      ...values,
      enable: drawerData.initialValues.enable,
    };
    delete editedFunctionality.documentValue;
    const response = await editFunctionality(
      editedFunctionality,
      drawerData.initialValues.id,
    );

    if (response.status) {
      editedFunctionality = {
        id: drawerData.initialValues.id,
        name: values.name,
        path: values.path,
        icon: values.icon,
        enable: drawerData.initialValues.enable,
        menu: {
          id: drawerData.initialValues.menuId,
          name: drawerData.initialValues.menuName,
        },
      };
      const currentData = [...data];

      const editedIndex = currentData.findIndex(
        data => data.id === editedFunctionality.id,
      );
      if (editedIndex !== -1) {
        currentData[editedIndex] = editedFunctionality;
      }

      setData(currentData);
      setDrawerData({ ...drawerData, status: false });
      setLoadingSubmit(false);
      return appNotification('success', response.message);
    }
    setLoadingSubmit(false);
    return appNotification('error', response.message);
  }

  async function handleStatusChecked(
    status: boolean,
    id: string,
  ): Promise<any> {
    setProgressPending(true);
    const response = await changeFunctionalityStatus(status, id);
    if (response.status) {
      const newData = [...data];
      const obj = { ...statusChecked };
      const modifiedDataIndex = newData.findIndex(data => data.id === id);

      if (modifiedDataIndex !== -1) {
        newData[modifiedDataIndex].enable = !status;

        obj[id] = !status;
        setData(newData);
        setStatusChecked(obj);
        setProgressPending(false);
        return appNotification('success', response.message);
      }
    }

    setProgressPending(false);
    return appNotification('error', response.message);
  }

  return (
    <>
      <Header
        title="Permissionamento - Funcionalidades"
        onBack={() => history.push('/permissions/options')}
      />
      <Button
        onClick={
          () =>
            setDrawerData({
              ...drawerData,
              status: true,
              action: 'Adicionar',
              initialValues: {
                id: '',
                name: '',
                path: '',
                icon: '',
                enable: '',
                menuId: '',
                menuName: '',
              },
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        Adicionar
      </Button>
      <Table
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        loading={progressPeding}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <Drawer
        afterVisibleChange={() => form.resetFields()}
        destroyOnClose
        title={`${drawerData.action} funcionalidade`}
        width={720}
        onClose={() => setDrawerData({ ...drawerData, status: false })}
        visible={drawerData.status}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => setDrawerData({ ...drawerData, status: false })}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>
            <Button onClick={form.submit} type="primary">
              {drawerData.action}
            </Button>
          </div>
        }
      >
        <Spin spinning={loadingSubmit}>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            hideRequiredMark
            initialValues={drawerData.initialValues}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="menuId"
                  label="Menus"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, selecione um menu',
                    },
                  ]}
                >
                  <Select filterOption placeholder="selecione um menu">
                    {menus.map(menu => (
                      <Select.Option value={menu.id} key={menu.id}>
                        {menu.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Nome da funcionalidade"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o nome da funcionalidade',
                    },
                  ]}
                >
                  <Input
                    maxLength={120}
                    placeholder="insira o nome da funcionalidade"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="path"
                  label="Caminho"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o caminho da funcionalidade',
                    },
                  ]}
                >
                  <Input placeholder="insira o caminho da funcionalidade" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="icon"
                  label="Icone"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o icone da funcionalidade',
                    },
                  ]}
                >
                  <Input placeholder="insira o icone da funcionalidade" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default Functionalities;
