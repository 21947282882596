/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { isEmpty } from 'lodash';
import cookies from 'js-cookie';
import { conciliation } from './config/consts';
import { httpGet, httpPost } from '../services/http/index.js';

interface TransactionsExternalResponse {
  totalizers: number;
  data: Array<any>;
  pagination: Pagination;
}

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  position: any;
}


interface GetExternalTransactions {
  pagination: Pagination;
  filters: any | undefined;
  date: any;
}


interface ExportResponse {
  status: boolean;
  url: string;
  email: boolean;
  message: string;
}

interface ExportExternalTransactions {
  filters: any;
  date: any;
}


export const exportExternalDocuments = async ({
  filters,
  date
}: ExportExternalTransactions): Promise<ExportResponse> => {
  let url_filter = '';

  try {
    if (!isEmpty(filters)) {
      if (!isEmpty(filters)) {
        for (const key in filters){
          if (key === 'date') {
            if (filters[key][1] === undefined) {
              url_filter += `&${key}=${filters[key][0]}`;
            } else {
              url_filter += `&period=${JSON.stringify(filters[key])}`;
            }
          } else if (key === 'id') {
            url_filter += `&${key}=${filters[key]}`;
          } else {
            url_filter += `&${key}=${JSON.stringify(filters[key])}`;
          }
        }
      }
    }

    const response = await httpGet(
      `${conciliation}/v1beta1/services-external/customer-document/export?${date}${url_filter}`,
    );

    const url_download = response.data;
    return {
      status: true,
      url: url_download,
      email: false,
      message: 'Sucesso! Seu relatorio está pronto e já esta sendo baixado.',
    };
  } catch (e) {
    if (e.response.data.message === 'Too big for response') {
      try {
        const userEmail = cookies.get('user');
        await httpPost(
          `${conciliation}/v1beta1/services-external/customer-document?${date}${url_filter}`,
          { email: userEmail },
        );

        return {
          status: true,
          url: '',
          email: true,
          message: `Sucesso! Estamos gerando seu relatorio e em breve enviaremos para o email : ${userEmail}  para download.`,
        };
      } catch (e) {
        return {
          status: false,
          url: '',
          email: false,
          message:
            'Erro! Não conseguimos gerar seu relatorio. Algo deu errado.',
        };
      }
    } else if (
      e.response.data.message === 'Maximum difference of months is three'
    ) {
      return {
        status: false,
        url: '',
        email: false,
        message:
          'Erro! Não conseguimos gerar seu relatorio. Periodo selecionado superior a 3 meses. Por gentileza, selecione um periodo menor.',
      };
    }
    return {
      status: false,
      url: '',
      email: false,
      message: '',
    };
  }
};

export const getDocumentsExternal = async ({
  pagination,
  filters,
  date}: GetExternalTransactions): Promise<TransactionsExternalResponse> => {
  try {
    let url_filter = '';

    if (!isEmpty(filters)) {
      for (const key in filters){
        if (key === 'date') {
          if (filters[key][1] === undefined) {
            url_filter += `&${key}=${filters[key][0]}`;
          } else {
            url_filter += `&period=${JSON.stringify(filters[key])}`;
          }
        } else if (key === 'id') {
          url_filter += `&${key}=${filters[key]}`;
        } else {
          url_filter += `&${key}=${JSON.stringify(filters[key])}`;
        }
      }
    }
    const { current, pageSize } = pagination;
    const response = await httpGet(
      `${conciliation}/v1beta1/services-external/customer-document?take=${pageSize}&page=${
        current - 1}${date}${url_filter}`,
    );

    const payload = {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
      totalizers: response.data.totalizers,
    };
    /*const payload = {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['topRight' as const],
      },
      totalizers: response.data.totalizers,
      arranj: context,
    };*/

    return payload;

  } catch (e) {
    console.log(e);

    const payload = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        position: ['topRight' as const],
      },
      totalizers: 0,
    };

    return payload;
  }
}

