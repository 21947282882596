import axios from 'axios';
import cookies from 'js-cookie';
import { apiKey, baseUrl } from '../../config';

const setDefaultConfiguration = customConfig => {
  const token = cookies.get('token');

  if (token) {
    return {
      headers: {
        'Access-Control-Allow-Headers': 'apiKey',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        apiKey,
        Authorization: `Bearer ${token}`,
      },
      ...customConfig,
    };
  }
  return {
    headers: {
      'Access-Control-Allow-Headers': 'apiKey',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apiKey,
    },
    ...customConfig,
  };
};

export const httpPost = (url, model, customConfig) => {
  return axios
    .post(`${baseUrl}/${url}`, model, setDefaultConfiguration(customConfig))
    .then(response => response)
    .catch(error => {
      if (error.response.status === 401) {
        cookies.remove('token');
        window.location.reload();
      }

      throw error;
    });
};

export const httpPut = async (url, model, customConfig) => {
  return axios
    .put(`${baseUrl}/${url}`, model, setDefaultConfiguration(customConfig))
    .then(response => response)
    .catch(error => {
      if (error.response.status === 401) {
        cookies.remove('token');
        window.location.reload();
      }

      throw error;
    });
};

export const httpGet = async (url, customConfig) => {
  return axios
    .get(`${baseUrl}/${url}`, setDefaultConfiguration(customConfig))
    .then(response => response)
    .catch(error => {
      if (error.response.status === 401) {
        cookies.remove('token');
        window.location.reload();
      }

      throw error;
    });
};

export const httpDelete = async (url, customConfig) => {
  return await axios
    .delete(`${baseUrl}/${url}`, setDefaultConfiguration(customConfig))
    .then(response => response)
    .catch(error => {
      if (error.response.status === 401) {
        cookies.remove('token');
        window.location.reload();
      }

      throw error;
    });
};

export const httpPatch = (url, model, customConfig) => {
  return axios
    .patch(`${baseUrl}/${url}`, model, setDefaultConfiguration(customConfig))
    .then(response => response)
    .catch(error => {
      if (error.response.status === 401) {
        cookies.remove('token');
        window.location.reload();
      }

      throw error;
    });
};
