export function transalateTransactionsStatus(transactionsStatus = null) {
  const translations = [
    { value: 0, label: "Cancelado/Estornado" },
    { value: 1, label: "Cancelado/baixado acionado" },
    { value: 2, label: "Cancelado/baixado" },
    { value: 3, label: "Em compensação" },
    { value: 4, label: "Não efetuado" },
    { value: 5, label: "Pago" },
    { value: 6, label: "Pendente" },
    { value: 7, label: "Sucesso" },
    { value: 8, label: "Aguardando retorno" },
  ];

  if (transactionsStatus === null) {
    return translations;
  } else {
    const result = translations.find(
      (searchKey) => searchKey.value === transactionsStatus
    );
    return result?.label;
  }
}
