import styled from 'styled-components';
import { primaryColor } from '../../config';

export const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 20px;

  p {
    font-weight: 700;
    color: ${primaryColor};
    font-size: 16px;
  }

  .ant-row,
  .ant-form-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .ant-form-item-label {
    text-align: left !important;
    margin-top: 10px;
    margin-bottom: 1em;
    font-weight: 700;
    color: ${primaryColor} !important;
  }
`;
