export async function findAccess(value) {
    /*if (!value) {
      return '[{}]';
    }*/
    const _accessLevel = [
        {
          id: 0,
          name: "Owner",  
          allowedTypes: 
          [
            {
              value: 0,
              label: "Owner",
            }, 
            {
              value: 1,
              label: "Admin",
            }, 
            {
              value: 2,
              label: "Client",
            },
            {
              value: 3,
              label: "Viewer",
            }, 
          ]      
        },
        {
          id: 1,
          name: "Admin", 
          allowedTypes: 
          [          
            {
              value: 1,
              label: "Admin",
            }, 
            {
              value: 2,
              label: "Client",
            },
            {
              value: 3,
              label: "Viewer",
            }, 
          ]   
        },
        {
          id: 2,
          name: "Client",
          allowedTypes: 
          [
            {
              value: 2,
              label: "Client",
            },
            {
              value: 3,
              label: "Viewer",
            }, 
          ]    
        },
        {
          id: 3,
          name: "Viewer", 
          allowedTypes: 
          [         
            {
                value: 3,
                label: "Viewer",
            }, 
          ]   
        }
      ];
  
    const result = _accessLevel.find((array) => value === array.id);

    if(result){
      return result?.allowedTypes;
    }
     return [{value: 2, label:"abc"}];
  }

