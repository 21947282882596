import React from 'react';
import { Tabs } from 'antd';

interface PropsTabs {
  title: string;
  key: string;
}

interface Props {
  tabs: Array<PropsTabs>;
  handleTab: any;
  selected: string;
}

const NavTab: React.FC<Props> = ({ tabs, handleTab, selected }: Props) => {
  const { TabPane } = Tabs;

  return (
    <Tabs
      style={{ marginBottom: '18px' }}
      defaultActiveKey={selected}
      onChange={handleTab}
    >
      {tabs.map(tab => (
        <TabPane tab={tab.title} key={tab.key} />
      ))}
    </Tabs>
  );
};

export default NavTab;
