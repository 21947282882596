import cookies from 'js-cookie';
import { account } from './config/consts';
import { httpPost } from '../services/http/index.js';

interface User {
  email: string;
  password: string;
}

interface Response {
  status: boolean;
  message: string;
}

export async function authenticate(payload: User): Promise<Response> {
  try {
    const response = await httpPost(`${account}/v1beta1/auth/login`, payload);

    cookies.set('token', response.data.token);
    cookies.set('user', response.data.email);
    cookies.set('accessLevel', response.data.user.accessLevel);
    cookies.set('userId', response.data.user.id);
    cookies.set('partners', response.data.user.partners);
    cookies.set(
      'userScreens',
      response.data.user.userScreens.map(
        (i: any) => i.appScreen.nameTranslation,
      ),
    );

    return {
      status: true,
      message: '',
    };
  } catch (error) {
    let message = '';
    if (error.response) {
      switch (error.response.status) {
        case 404:
          message =
            'Não existe nenhum usuário cadastrado com as credenciais fornecidas.';
          break;
        case 401:
          message = 'Credenciais invalidas.';
          break;
        case 400:
          message = 'Todos os campos devem ser preenchidos.';
          break;
        case 429:
          message = 'Você excedeu o número máximo de tentativas de login, gentileza resetar sua senha.';
          break;
        default:
          message = error.response.message;
          break;
      }
    }

    return {
      status: false,
      message,
    };
  }
}
