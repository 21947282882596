import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Drawer,
  Form,
  Spin,
  Button,
  Row,
  Col,
  Input,
  Switch,
} from 'antd';
import { SwitchProps } from 'antd/lib/switch/index.js';
import { FaEdit } from 'react-icons/fa';
import {
  getMenus,
  addMenu,
  editMenu,
  changeMenuStatus,
} from '../../../api/MenusService';
import Header from '../../../components/Header';
import appNotification from '../../../components/notification';

interface SwitchComponent extends SwitchProps {
  name?: string;
}

const Menus: React.FC<SwitchComponent> = ({ name }: SwitchComponent) => {
  const history = useHistory();
  const [progressPeding, setProgressPending] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const columns = [
    {
      title: 'Nome',
      render: (render: any) => render.name,
    },
    {
      title: 'Caminho',
      render: (render: any) => render.path,
    },
    {
      title: 'Icone',
      render: (render: any) => render.icon,
    },
    {
      title: 'Status',
      render: (render: any) => (
        <Switch
          {...name}
          name={render.id}
          defaultChecked={render.enable}
          checked={statusChecked[render.id]}
          onChange={() => handleStatusChecked(render.enable, render.id)}
        />
      ),
    },
    {
      title: 'Editar',
      render: (render: any) => (
        <Button
          onClick={
            () =>
              setDrawerData({
                ...drawerData,
                status: true,
                action: 'Editar',
                initialValues: {
                  id: render.id,
                  name: render.name,
                  path: render.path,
                  icon: render.icon,
                  enable: render.enable,
                },
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          <FaEdit color="#4C2D6E" />
        </Button>
      ),
    },
  ];
  const [drawerData, setDrawerData] = useState({
    status: false,
    initialValues: {
      id: '',
      name: '',
      path: '',
      icon: '',
      enable: '',
    },
    action: '',
  });
  const [statusChecked, setStatusChecked] = useState<any>({});
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchMenus(): Promise<any> {
      setProgressPending(true);
      const response = await getMenus(pagination);
      setData(response.data);
      setPagination(response.pagination);
      setProgressPending(false);
    }
    fetchMenus();
    // eslint-disable-next-line
  }, []);

  async function handleTableChange(paginationParam: any): Promise<void> {
    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPending(true);
      const newPagination = {
        current: paginationParam.current,
        pageSize: paginationParam.pageSize,
      };
      const response = await getMenus(newPagination);
      setData(response.data);
      setPagination(response.pagination);
      setProgressPending(false);
    }
  }

  async function handleSubmit(values: any): Promise<any> {
    setLoadingSubmit(true);
    if (drawerData.action === 'Adicionar') {
      const response = await addMenu(values);
      if (response.status) {
        setData([...data, response.data]);
        setPagination({ ...pagination, total: pagination.total + 1 });
        setDrawerData({ ...drawerData, status: false });
        setLoadingSubmit(false);
        return appNotification('success', response.message);
      }
      setLoadingSubmit(false);
      return appNotification('error', response.message);
    }

    let editedMenu = { ...values, enable: drawerData.initialValues.enable };
    delete editedMenu.documentValue;
    const response = await editMenu(editedMenu, drawerData.initialValues.id);

    if (response.status) {
      editedMenu = {
        id: drawerData.initialValues.id,
        name: values.name,
        path: values.path,
        icon: values.icon,
        enable: drawerData.initialValues.enable,
      };

      const currentData = [...data];

      const editedIndex = currentData.findIndex(
        data => data.id === editedMenu.id,
      );
      if (editedIndex !== -1) {
        currentData[editedIndex] = editedMenu;
      }

      setData(currentData);
      setDrawerData({ ...drawerData, status: false });
      setLoadingSubmit(false);
      return appNotification('success', response.message);
    }
    setLoadingSubmit(false);
    return appNotification('error', response.message);
  }

  async function handleStatusChecked(
    status: boolean,
    id: string,
  ): Promise<any> {
    setProgressPending(true);
    const response = await changeMenuStatus(status, id);
    if (response.status) {
      const newData = [...data];
      const obj = { ...statusChecked };
      const modifiedDataIndex = newData.findIndex(data => data.id === id);

      if (modifiedDataIndex !== -1) {
        newData[modifiedDataIndex].enable = !status;

        obj[id] = !status;
        setData(newData);
        setStatusChecked(obj);
        setProgressPending(false);
        return appNotification('success', response.message);
      }
    }

    setProgressPending(false);
    return appNotification('error', response.message);
  }

  return (
    <>
      <Header
        title="Permissionamento - Menus"
        onBack={() => history.push('/permissions/options')}
      />
      <Button
        onClick={
          () =>
            setDrawerData({
              ...drawerData,
              status: true,
              action: 'Adicionar',
              initialValues: {
                id: '',
                name: '',
                path: '',
                icon: '',
                enable: '',
              },
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        Adicionar
      </Button>
      <Table
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        loading={progressPeding}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <Drawer
        afterVisibleChange={() => form.resetFields()}
        destroyOnClose
        title={`${drawerData.action} menu`}
        width={720}
        onClose={() => setDrawerData({ ...drawerData, status: false })}
        visible={drawerData.status}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => setDrawerData({ ...drawerData, status: false })}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>
            <Button onClick={form.submit} type="primary">
              {drawerData.action}
            </Button>
          </div>
        }
      >
        <Spin spinning={loadingSubmit}>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            hideRequiredMark
            initialValues={drawerData.initialValues}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Nome do menu"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o nome do menu',
                    },
                  ]}
                >
                  <Input maxLength={120} placeholder="insira o nome do menu" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="path"
                  label="Caminho"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o caminho do menu',
                    },
                  ]}
                >
                  <Input
                    maxLength={120}
                    placeholder="insira o caminho do menu"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="icon"
                  label="Icone do menu"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o icone do menu',
                    },
                  ]}
                >
                  <Input placeholder="insira o icone do menu" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default Menus;
