import styled from 'styled-components';
import { primaryColor } from '../../../../config';

export const ActionsContainer = styled.div`
  margin-bottom: 20px;
  button {
    margin-right: 20px;

    &:hover {
      transform: translateX(-10px);
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  margin-bottom: 40px;

  .ant-card {
    width: 25%;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    box-shadow: 0px 10px 10px -14px rgba(0, 0, 0, 0.35);
    margin-right: 20px;
    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      font-weight: 700;
      color: ${primaryColor};
    }
    .ant-card-body {
      text-align: right;
    }
  }
`;

export const Code = styled.pre`
  width: 100%;
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin-top: 40px;
`;
