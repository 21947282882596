import React, { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import moment from 'moment';
import { Table, Col, Row, Card, Spin } from 'antd';
import { getClousure } from '../../api/ClousureService';
import { CardContainer, HomeContainer } from './style';
import { formatCurrency } from '../../utils/format-currency.js';
import { translateTransactionsType } from '../../utils/translations/transactions-types.js';

const Home: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const context = ['insider', 'outsider', 'between'];
  const permissions = cookies.get('userScreens');
  const userScreens = permissions ? JSON.parse(permissions) : [''];
  const sumCostIncludes = userScreens.includes('sum_cost');
  const sumRevenueIncludes = userScreens.includes('sum_revenue');
  const columns = [
    {
      title: 'Tipo de transação',
      render: (render: any) =>
        translateTransactionsType(render['totalizersTransactionType.desc']),
    },
    {
      title: 'Valor das transações',
      render: (render: any) => formatCurrency(render['totalizers.value']),
      align: 'right' as const,
    },
    {
      title: 'Tarifa',
      render: (render: any) =>
        formatCurrency(render['totalizers.chargedTariff']),
      align: 'right' as const,
    },
    sumCostIncludes
      ? {
          title: 'Custo',
          render: (render: any) => formatCurrency(render['totalizers.cost']),
          align: 'right' as const,
        }
      : {},
    sumRevenueIncludes
      ? {
          title: 'Receita',
          render: (render: any) => formatCurrency(render['totalizers.revenue']),
          align: 'right' as const,
        }
      : {},
  ];
  const [data, setData] = useState([]);
  const [card, setCards] = useState<any>(null);

  useEffect(() => {
    async function getData(): Promise<any> {
      setLoading(true);
      const initDate = moment()
        .utc()
        .subtract(1, 'days')
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('YYYY-MM-DD HH:mm:ss');

      const endDate = moment()
        .utc()
        .subtract(1, 'days')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(0)
        .format('YYYY-MM-DD HH:mm:ss');

      const params = {
        initDate,
        endDate,
        partnerIds: [],
      };

      const clousureResponse = await getClousure(context, params);

      const totalizers = {
        value: 0,
        tarrif: 0,
        cost: 0,
        revenue: 0,
      };

      if (clousureResponse) {
        const baseData: any = [];
        clousureResponse.map((clousure: any) =>
          clousure.data.map((data: any) =>
            data.report.map((report: any) => {
              totalizers.value += report['totalizers.value'];
              totalizers.tarrif += report['totalizers.chargedTariff'];
              totalizers.cost += report['totalizers.cost'];
              totalizers.revenue += report['totalizers.revenue'];
              return baseData.push(report);
            }),
          ),
        );

        setData(baseData);
        setCards(
          <CardContainer>
            <Card title="Valor Transacionado">
              {formatCurrency(totalizers.value)}
            </Card>
            <Card title="Soma Tarifa">{formatCurrency(totalizers.tarrif)}</Card>
            {sumCostIncludes && (
              <Card title="Soma Custo">{formatCurrency(totalizers.cost)}</Card>
            )}
            {sumRevenueIncludes && (
              <Card title="Soma Receita">
                {formatCurrency(totalizers.revenue)}
              </Card>
            )}
          </CardContainer>,
        );
      }

      setLoading(false);
    }
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading}>
      <HomeContainer>
        <h2>Bem-vindo(a) ao portal de conciliação.</h2>
        <br />
        <br />
        <br />
        <h3>Detalhamento do dia anterior</h3>
        <br />
        <br />
        <Row>
          <Col span={24}>{card}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              dataSource={data}
              columns={columns}
              sortDirections={['ascend', 'descend', 'ascend']}
              pagination={false}
              bordered
            />
          </Col>
        </Row>
      </HomeContainer>
    </Spin>
  );
};

export default Home;
