import { Button } from 'antd';
import styled from 'styled-components';
import { Modal } from '../../../components/Modal';

export const Container = styled.div`
  .cards-container {
    width: 100%;
    display: flex;
    gap: 80px;
    justify-content: center;
    padding-top: 2rem;

    .card-container .ant-spin-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 30px 24px;
      border: 1px solid #bdbdbd;
      box-sizing: border-box;
      border-radius: 20px;
      min-width: 300px;
      gap: 10px;
      span {
        font-size: 0.975rem;
        color: #737f96;
        font-weight: 500;
      }
      h5 {
        margin-bottom: 25px;
        font-size: 1.225rem;
        color: #744ff6;
      }
      .partners {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 10px;

        span {
          display: flex;
          gap: 5px;
          align-items: center;
          font-weight: bold;
        }

        .red,
        .green {
          width: 10px;
          height: 10px;
          border-radius: 50px;
          margin-bottom: 0;
        }
        .red {
          background: #eb5757;
        }
        .green {
          background: #6fcf97;
        }
      }
      .payment {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .value-transaction {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-right: auto;
        max-width: 250px;
        margin-top: 20px;
        text-align: justify;
        span {
          color: #734ff4 !important;
        }
      }
    }
  }
`;

export const ComprovarPagamento = styled(Button)`
  margin-top: 20px;
  color: #4c2d6e;
  font-size: 1rem;
  border: 1px solid #4c2d6e;
  border-radius: 100px;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    width: 700px;
  }
  .ant-modal-body {
    min-height: 500px;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .form-group {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .file {
      display: none;
    }
    .label-file {
      cursor: pointer;
      color: #555555;
      font-size: 12px;
    }
    label {
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 14px;
      strong {
        color: #ab0000;
        font-size: 12px;
      }
    }
    input {
      width: 100%;
      max-width: 352px;
      padding-left: 5px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 8px;
      height: 34px;
    }
    p {
      color: red;
      font-size: 12px;
      margin: 0;
    }
  }
  .details {
    margin-top: 22px;

    p {
      color: #555555;
      font-weight: 300;
      font-size: 15.75px;
      line-height: 18px;
      strong {
        font-weight: 700;
      }
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #828282;
    }
    .reason {
      margin-top: 9px;
      padding: 14px 20px 30px 19px;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 8px;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #828282;
    }
  }
`;
