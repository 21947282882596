import {
Button,
Col,
Drawer,
Form,
Input,
Row,
Select,
Spin,
Switch,
Table,
} from 'antd';
import { FaCheck, FaEnvelope, FaKey } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import {
  addUser,
  changeUserStatus,
  editUser,
  getUsers,
} from '../../../api/UserService';

import Header from '../../../components/Header';
import ReactInputMask from 'react-input-mask';
import { SwitchProps } from 'antd/lib/switch/index.js';
import appNotification from '../../../components/notification';
import cookies from 'js-cookie';
import {findAccess} from '../../../utils/access-level'
import { getPartners } from '../../../api/PartnerService'
import { resendEmail } from '../../../api/ResetService'
import { useHistory } from 'react-router-dom';
import TableFilter from '../../../containers/Table-filter';
import { isEmpty } from 'lodash';

interface SwitchComponent extends SwitchProps {
  name?: string;
}

interface FilterDropDown {
  setSelectedKeys: () => void;
  selectedKeys: Array<any> | string;
  confirm: () => void;
  clearFilters: () => void;
}

const Users: React.FC<SwitchComponent> = ({ name }: SwitchComponent) => {
  const history = useHistory();
  const [acessLevel, setAccessLevel] = useState<any>([]);
  const [progressPeding, setProgressPending] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [userAccessLevel, setUserAccessLevel] = useState<any>();
  const [filters, setFilters] = useState<any>({});
  const [partnerSelect, setPartnerSelect] = useState<any>();
  const [data, setData] = useState<Array<any>>([]);
  const [partnerData, setPartnerData] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topRight' as const],
  });
  const [partnerPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
    position: ['topRight' as const],
  });

  const permissions = cookies.get('userScreens');
  const userScreens = permissions ? JSON.parse(permissions) : [''];

  function getColumnSearchProps(
    dataIndex: string,
    filterType: string,
    filterMode: 'multiple' | 'tags' | undefined,
    filterOptions: Array<any> | undefined,
    FilterSelectOnFocus: any,
    onSearch: any,
  ): any {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropDown) => {
        return (
          <TableFilter
            dataIndex={dataIndex}
            filterType={filterType}
            filterMode={filterMode}
            filterOptions={filterOptions}
            FilterSelectOnFocus={FilterSelectOnFocus}
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            onSearch={onSearch}
            clearFilters={clearFilters}
            clearSingleFilter={clearSingleFilter}
          />
        );
      },
    };
  }
  async function clearSingleFilter(columnKey: string): Promise<void> {
    setProgressPending(true);

    const split = columnKey.split('_');
    const filterDelete: any = { ...filters };

    if (split.length > 1) {
      delete filterDelete[split[0]];
      delete filterDelete[split[1]];
    } else {
      delete filterDelete[columnKey];
    }

    await setFilters(filterDelete);
    const response = await getUsers({pagination: pagination, filters: filterDelete});

    setData(response.data);
    if (isEmpty(filterDelete)) {
      setPagination({
        current: 1,
        pageSize: 10,
        total: response.pagination.total,
        position: ['topRight' as const],
      });
    } else {
      setPagination(response.pagination);
    }

    setProgressPending(false);
  }

  const columns = [
    {
      title: 'Nome',
      render: (render: any) => render.realName,
      key: 'realName',
      filteredValue: filters.realName || null,
      ...getColumnSearchProps(
        'realName',
        'text',
        undefined,
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      title: 'Nome de usuário',
      render: (render: any) => render.name,
      sorter: true,
    },
    {
      title: 'Telefone',
      render: (render: any) => render.phone,
      sorter: true,
    },
    {
      title: 'E-mail',
      render: (render: any) => render.email,
      key: 'email',
      filteredValue: filters.email || null,
      ...getColumnSearchProps(
        'email',
        'text',
        undefined,
        undefined,
        undefined,
        undefined,
      ),
    },
    {
      title: 'Status',
      render: (render: any) => (
        <Switch
          {...name}
          name={render.id}
          defaultChecked={render.enable}
          checked={render.enable}
          onChange={() => handleStatusChecked(render.enable, render.id)}
        />
      ),
    },
    userScreens.includes('change_permission_button')
      ? {
          title: 'Permissionamento',
          render: (render: any) => (
            <Button
              onClick={
                () =>
                  history.push(
                    `/permissions/options/users/permissions/${render.id}`,
                  )
                // eslint-disable-next-line react/jsx-curly-newline
              }
            >
              <FaKey color="#4C2D6E" />
            </Button>
          ),
          align: 'center' as const,
        }
      : {},
    {
      title: 'Conta Verificada',
      render: (render: any) =>
      (
        <div>
        {render.verify ?
          (
            <Button
              title="Conta verificada."
            >
            <FaCheck color="#4C2D6E" />
            </Button>
          ) :
          (
            <Button
              title="Clique aqui para reenviar o e-mail de confirmação."
              onClick={
                () =>
                  handleVerifyAccount(render.id)
              }
            >
            <FaEnvelope color="#4C2D6E" />
            </Button>
          )
        }
        </div>
      ),
      align: 'center' as const,
    },
    // {
    //   title: 'Editar',
    //   render: (render: any) => (
    //     <Button
    //       onClick={
    //         () =>
    //           setDrawerData({
    //             ...drawerData,
    //             status: true,
    //             action: 'Editar',
    //             initialValues: {
    //               id: render.id,
    //               documentValue: '',
    //               documentType: 1,
    //               email: render.email,
    //               name: render.name,
    //               phone: render.phone,
    //               realName: render.realName,
    //               enable: render.enable,
    //             },
    //           })
    //         // eslint-disable-next-line react/jsx-curly-newline
    //       }
    //     >
    //       <FaEdit color="#4C2D6E" />
    //     </Button>
    //   ),
    // },
  ];
  const [drawerData, setDrawerData] = useState({
    status: false,
    initialValues: {
      id: '',
      documentValue: '',
      documentType: 1,
      email: '',
      name: '',
      phone: '',
      realName: '',
      enable: '',
    },
    action: '',
  });
  const [statusChecked, setStatusChecked] = useState<any>({});
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchUsers(): Promise<any> {
      setProgressPending(true);
      const _accessLevel = cookies.get('accessLevel');
      setUserAccessLevel(_accessLevel)
      const response = await getUsers({pagination, filters});
      setData(response.data);
      setPagination(response.pagination);
      const partners = await getPartners(partnerPagination);
      setPartnerData(partners.data);
      setProgressPending(false);
      setAccessLevel(await findAccess(Number(_accessLevel)));
    }
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  async function handleTableChange(paginationParam: any, filterParam: any): Promise<void> {
    const newFilters: any = {};
    const filterForApi: any = {};

    const convertIntoArray = Object.entries(filterParam);
    convertIntoArray.map(filterValue => {
      if (filterValue[1] !== null) {
        const key = filterValue[0]?.split('_');
        // eslint-disable-next-line prefer-destructuring
        newFilters[key[0]] = filterValue[1];
        filterForApi[key[0]] = filterValue;

        if (key.length > 2) {
          if (filterForApi[key[0]] === 'checked') {
            delete filterForApi[key[0]];
            filterForApi[key[2]] = 0;
          } else {
            delete filterForApi[key[2]];
            if (filterForApi[key[0]].length > 0)
              filterForApi[key[0]].splice(2, 1);
          }
        }

        if (isEmpty(filterForApi[key[0]])) {
          delete filterForApi[key[0]];
        }
      }
      return false;
    });
    setFilters(newFilters);

    if (!isEmpty(newFilters)) {
      setProgressPending(true);
      const response = await getUsers({
        pagination: paginationParam,
        filters: newFilters
      });

      setData(response.data);
      setProgressPending(false);
    }

    if (
      paginationParam.current !== pagination.current ||
      paginationParam.pageSize !== pagination.pageSize
    ) {
      setProgressPending(true);
      const response = await getUsers({
                                      pagination: paginationParam,
                                      filters: filters});
      setData(response.data);
      setPagination(paginationParam);
      setProgressPending(false);
    }
  }

  async function handleSubmit(values: any): Promise<any> {
    setLoadingSubmit(true);
    if (drawerData.action === 'Adicionar') {
      const response = await addUser({ ...values, documentType: 1 });
      if (response.status) {
        setData([...data, response.data]);
        setPagination({ ...pagination, total: pagination.total + 1 });
        setDrawerData({ ...drawerData, status: false });
        setLoadingSubmit(false);
        return appNotification('success', response.message);
      }
      setLoadingSubmit(false);
      return appNotification('error', response.message);
    }

    let editedUser = { ...values, enable: drawerData.initialValues.enable };
    delete editedUser.documentValue;
    const response = await editUser(editedUser, drawerData.initialValues.id);

    if (response.status) {
      editedUser = {
        id: drawerData.initialValues.id,
        email: values.email,
        name: values.name,
        phone: values.phone,
        realName: values.realName,
        enable: drawerData.initialValues.enable,
      };

      const currentData = [...data];

      const editedIndex = currentData.findIndex(
        data => data.id === editedUser.id,
      );
      if (editedIndex !== -1) {
        currentData[editedIndex] = editedUser;
      }

      setData(currentData);
      setDrawerData({ ...drawerData, status: false });
      setLoadingSubmit(false);
      return appNotification('success', response.message);
    }
    setLoadingSubmit(false);
    return appNotification('error', response.message);
  }

  async function handleVerifyAccount(id: string) {
    setProgressPending(true);

    const { status, message } = await resendEmail(id);

    if (status) {
      appNotification('success', message);
      setProgressPending(false);
    } else {
      setProgressPending(false);
      appNotification('error', message);
    }
  }

  const  SelectPartnerOption =  () => {
      return(
        <Form.Item
          name="partnersIds"
          label="Parceiro do usuário"
          rules={[
            {
              required: true,
              message: 'Por favor, insira qual o parceiro do usuário.',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Data"
            style={{ width: 200 }}
          >
            {partnerData.map((option: any) => (
              <Select.Option value={option.id} key={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
  }

  async function changeSelectOptionHandler( event: number ) {
    if((event === 2 || event === 3) && userAccessLevel < 2){
      setPartnerSelect(true);
    }
    else if(userAccessLevel >= 2 && event >= 2)
    {
      setPartnerSelect(true);
    }
    else
    {
      setPartnerSelect(false)
    }
  }

  async function handleStatusChecked(
    status: boolean,
    id: string,
  ): Promise<any> {
    setProgressPending(true);
    const response = await changeUserStatus(status, id);
    if (response.status) {
      const newData = [...data];
      const obj = { ...statusChecked };
      const modifiedDataIndex = newData.findIndex(data => data.id === id);

      if (modifiedDataIndex !== -1) {
        newData[modifiedDataIndex].enable = !status;

        obj[id] = !status;
        setData(newData);
        setStatusChecked(obj);
        setProgressPending(false);
        return appNotification('success', response.message);
      }
    }

    setProgressPending(false);
    return appNotification('error', response.message);
  }

  return (
    <>
      <Header
        title="Permissionamento - Usuários"
        onBack={() => history.push('/permissions/options')}
      />
      {userScreens.includes('permission_add_user_button') && (
        <Button
          onClick={
            () =>
              setDrawerData({
                ...drawerData,
                status: true,
                action: 'Adicionar',
                initialValues: {
                  id: '',
                  documentValue: '',
                  documentType: 1,
                  email: '',
                  name: '',
                  phone: '',
                  realName: '',
                  enable: '',
                },
              })
           // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          Adicionar
        </Button>
      )}
      <Table
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        loading={progressPeding}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <Drawer
        afterVisibleChange={() => form.resetFields()}
        destroyOnClose
        title={`${drawerData.action} usuário`}
        width={720}
        onClose={() => setDrawerData({ ...drawerData, status: false })}
        visible={drawerData.status}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => setDrawerData({ ...drawerData, status: false })}
              style={{ marginRight: 8 }}
            >
              Cancelar
            </Button>
            <Button onClick={form.submit} type="primary">
              {drawerData.action}
            </Button>
          </div>
        }
      >
        <Spin spinning={loadingSubmit}>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            hideRequiredMark
            initialValues={drawerData.initialValues}
          >
            <Row>
              <Col span={8}>
                <Form.Item
                  name="realName"
                  label="Nome"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o nome do usuário',
                    },
                  ]}
                >
                  <Input
                    maxLength={120}
                    placeholder="insira o nome do usuário"
                  />
                </Form.Item>
              </Col>
              <Col push={1} span={8}>
                <Form.Item
                  name="name"
                  label="Nome de usuário"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o nome de usuário',
                    },
                  ]}
                >
                  <Input
                    maxLength={120}
                    placeholder="insira o nome de usuário"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {drawerData.action === 'Adicionar' && (
                <Col span={8}>
                  <Form.Item
                    name="documentValue"
                    label="Documento do usuário"
                    rules={[
                      {
                        required: true,
                        message: 'por favor, insira o documento do usuário',
                      },
                    ]}
                  >
                    <ReactInputMask mask="999.999.999-99">
                      {() => (
                        <Input placeholder="insira o documento do usuário" />
                      )}
                    </ReactInputMask>
                  </Form.Item>
                </Col>
              )}
              <Col push={drawerData.action === 'Adicionar' ? 1 : 0} span={8}>
                <Form.Item
                  name="phone"
                  label="Telefone do usuário"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o telefone do usuário',
                    },
                  ]}
                >
                  <ReactInputMask mask="(99) 99999-9999">
                    {() => <Input placeholder="insira o email do usuário" />}
                  </ReactInputMask>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="accessLevel"
                  label="´Nivel de acesso"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o nivel de acesso do usuário',
                    },
                  ]}
                >
                  <Select
                    onChange={changeSelectOptionHandler}
                    placeholder="Nivel de Acesso"
                    style={{ width: 200 }}
                  >
                    {acessLevel.map((option: any) => (
                      <Select.Option value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col push={1} span={8}>
                {
                  partnerSelect ? (< SelectPartnerOption />): ''
                }
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'por favor, insira o email do usuário',
                    },
                    { type: 'email', message: 'E-mail invalido.' },
                  ]}
                >
                  <Input
                    maxLength={120}
                    placeholder="insira o email do usuário"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default Users;
