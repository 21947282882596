import { isEmpty } from 'lodash';
import { httpGet, httpPost, httpPut } from '../services/http/index.js';
import { conciliation } from './config/consts';

interface PartnersAsOptionsResponse {
  data: Array<any>;
}

interface PartnerRequest {
  id?: string;
  name: string;
  email: string;
  phone: string;
  documentValue?: string;
}

interface GetBalance {
  filters: any;
}

export async function getPartnersAsOptions(): Promise<
  PartnersAsOptionsResponse
> {
  try {
    const response = await httpGet(
      `${conciliation}/v1beta1/partner?take=${50}&page=${0}`,
    );

    const partnersOptions: any = [];
    response.data.content.map((partner: any) => {
      return partnersOptions.push({ value: partner.id, label: partner.name });
    });

    const payload = {
      data: partnersOptions,
    };
    return payload;
  } catch (e) {
    console.log(e);

    const payload = {
      data: [],
    };

    return payload;
  }
}

export async function getPartners(pagination: any): Promise<any> {
  try {
    const { current, pageSize } = pagination;

    const response = await httpGet(
      `${conciliation}/v1beta1/partner?page=${current - 1}&take=${pageSize}`,
    );

    const payload = {
      data: response.data.content,
      pagination: {
        current,
        pageSize,
        total: response.data.size,
        position: ['bottomRight' as const],
      },
      status: true,
      // message: response_message,
    };
    return payload;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function addPartner(partner: PartnerRequest): Promise<any> {
  try {
    const data = { ...partner, documentType: 1 };

    const response = await httpPost(`${conciliation}/v1beta1/partner`, data);

    return {
      data: response.data,
      status: true,
      message: 'Parceiro adicionado com sucesso',
    };
  } catch (error) {
    let message = 'Ocorreu um erro ao adicionar o parceiro.';

    switch (error.response.data.message) {
      case 'Document already register!':
        message = 'Já existe um parceiro cadastrado com o documento informado.';
        break;
      case 'E-mail already register!':
        message = 'Já existe um parceiro cadastrado com o e-mail informado.';
        break;
      default:
        break;
    }

    return {
      status: false,
      message,
    };
  }
}

export async function editPartner(
  partner: PartnerRequest,
  id: string,
): Promise<any> {
  try {
    const response = await httpPut(
      `${conciliation}/v1beta1/partner/${id}`,
      partner,
    );
    return {
      data: response.data,
      status: true,
      message: 'Parceiro editado com sucesso',
    };
  } catch (error) {
    let message = 'Ocorreu um erro ao editar o parceiro.';

    switch (error.response.data.message) {
      case 'Document already register!':
        message = 'Já existe um parceiro cadastrado com o documento informado.';
        break;
      case 'E-mail already register!':
        message = 'Já existe um parceiro cadastrado com o e-mail informado.';
        break;
      default:
        break;
    }

    return {
      status: false,
      message,
    };
  }
}

export async function getPartnerBalance({
  filters
}: GetBalance): Promise<any> {

  let url_filter = '';

  if (!isEmpty(filters)) {
    // eslint-disable-next-line
    for (const key in filters) {
      if (key === 'date') {
        if (filters[key][1] === undefined) {
          url_filter += `&${key}=${filters[key][0]}`;
        } else {
          url_filter += `&period=${JSON.stringify(filters[key])}`;
        }
      } else if (key === 'id') {
        url_filter += `&${key}=${filters[key]}`;
      } else {
        url_filter += `${JSON.stringify(filters[key])}`;
      }
    }
  }
  try {
    const response = await httpGet(
      `${conciliation}/v1beta1/partner-pix-balance/${url_filter}`,
    );

    const payload = {
      data: response.data.content,
    };
    return payload;
  } catch (e) {
    console.log(e);

    const payload = {
      data: [],
    };

    return payload;
  }
}