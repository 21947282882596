import styled from 'styled-components';
import { Button, Menu } from 'antd';
import { black } from '../../config';

export const Btn = styled(Button)`
  border: inherit !important;
  box-shadow: none !important;

  &::after {
    box-shadow: none !important;
  }
`;
export const DropDownItems = styled(Menu.Item)`
  &:hover {
    color: ${black};
    border-radius: 10px;
  }
`;

export const DropdownMenu = styled(Menu)`
  padding: 0 !important;
  text-justify: justify !important;
`;
