import { notification } from 'antd';

const appNotification = (
  type: 'warning' | 'success' | 'error' | 'info',
  message: string,
): void => {
  notification[type]({
    message,
    description: '',
    icon: undefined,
    style: {
      color: '#fff',
    },
  });
};

export default appNotification;
