import React, { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../../components/Header';
import NavTab from '../../../components/NavTab';
import Insiders from './components/insider';
import Outsiders from './components/outsider';
import ExternalTransaction from './components/external';
import Between from './components/between';
import ConciationDetails from './components/conciliation-details';
import { setContext } from '../../../store/actions/transactions';

interface Props {
  reduxContext: {
    dashboardVisibility: boolean;
  };
}

const Transactions: React.FC<Props> = ({ reduxContext }: Props) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('inside_transaction');

  const permissions = cookies.get('userScreens');
  const userScreens = permissions ? JSON.parse(permissions) : [''];

  const [tabs, setTabs] = useState([
    {
      title: 'Entradas',
      key: 'inside_transaction',
    },
    {
      title: 'Saídas',
      key: 'outside_transaction',
    },
    {
      title: 'Entre arranjos',
      key: 'between_transaction',
    },
    {
      title: 'Detalhes Conciliação',
      key: 'conciliate_details',
    },
    {
      title: 'Transação com erro',
      key: 'external_transaction',
    },
    // {
    //   title: 'Saldo Pix',
    //   key: 'pix_balance',
    // },
  ]);

  useEffect(() => {
    const tabsFilter: string[] = [];

    if (!userScreens.includes('between_transaction')) {
      tabsFilter.push('between_transaction');
    }
    if (!userScreens.includes('conciliate_details')) {
      tabsFilter.push('conciliate_details');
    }
    if (!userScreens.includes('external_transaction')) {
      tabsFilter.push('external_transaction');
    }
    // if (!userScreens.includes('pix_balance')) {
    //   tabsFilter.push('pix_balance');
    // }

    const filtereds = tabs.filter(f => !tabsFilter.find(t => t === f.key));

    setTabs([...filtereds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTab = (activeTabParam: string): React.ReactNode => {
    switch (activeTabParam) {
      case 'inside_transaction':
        return <Insiders context="insider" userScreens={userScreens} />;
      case 'outside_transaction':
        return <Outsiders context="outsider" userScreens={userScreens} />;
      case 'between_transaction':
        return <Between context="between" />;
      case 'external_transaction':
        return <ExternalTransaction context="transaction-wallet" />;
      case 'conciliate_details':
        return <ConciationDetails />;
      // case 'pix_balance':
      //   return <PixBalance />;
      default:
        return <h5>404 not found</h5>;
    }
  };

  const handleOnBack = (): void => {
    if (reduxContext.dashboardVisibility) {
      window.location.reload();
    } else {
      history.push('/conciliation/options');
    }
  };

  return (
    <>
      <Header title="Conciliação - Transações" onBack={handleOnBack} />
      <NavTab tabs={tabs} selected={activeTab} handleTab={setActiveTab} />
      {renderTab(activeTab)}
    </>
  );
};

const mapStateToProps = (state: any): any => ({
  reduxContext: state.Transactions.context,
});

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ setContext }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
