/* eslint-disable react/jsx-one-expression-per-line */

import { Button, Form, Input, Spin } from 'antd';
import { Container, FormContainer } from './style';
import React, { useEffect, useState } from 'react';
import { checkHash, resetPassword } from '../../api/ResetService';
import { useHistory, useParams } from 'react-router-dom';

import appNotification from '../../components/notification';
import { appVersion } from '../../config';

interface formInitialValues {
  password: '';
  confirmPassword: '';
  hash: '';
}

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();   
  const [form] = Form.useForm();
  const regexPasswordRules = RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
  );
  
  const params:any = useParams();

  useEffect(() => {
    async function validateHash(): Promise<any> {
      setLoading(true);

      const {status, message} = await checkHash(params.hash); 

      if(!status)
      {
        setLoading(false);
        appNotification('error', message);
        history.push('/');
      }
      else
      {
        setLoading(false);
      }
    }
    validateHash();
  },[history, params.hash]);
  
  //const params:any = useParams();

  const compareToFirstPassword = (
    rule: any,
    value: any,
    callback: any,
  ): any => {
    if (value && value !== form.getFieldValue('password')) {
      callback('- A confirmação é diferente da nova senha.');
    } else {
      callback();
    }
  };

  const passwordRules = (rule: any, value: any, callback: any): any => {
    if (value && !value.match(regexPasswordRules)) {
      callback(
        '- A senha deve conter pelo menos, uma letra maiúscula, uma letra minuscula, um caractere especial e um numero.',
      );
    } else {
      callback();
    }
  };
  
  async function handleReset(payload: formInitialValues): Promise<void> {
    setLoading(true);
    payload.hash = params.hash;    
    const { status, message } = await resetPassword(payload);
    
    if (status) {
      appNotification('success', message);
      history.push('/');
    } else {
      setLoading(false);
      appNotification('error', message);
    }
    
  }



  return (
    <Spin spinning={loading}>
      <Container>
        <section>
          <h1>U4CRYPTO</h1>
          <FormContainer>
            <Form
              form={form}
              name="ResetPassword"
              onFinish={handleReset}
              onFinishFailed={errorInfo => console.log(errorInfo)}
            >
              <Form.Item
                label="Nova senha"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, preencha a  nova senha.',
                  },
                  {
                    min: 8,
                    message: '- A senha deve conter no minimo 8 digitos.',
                  },
                  {
                    max: 16,
                    message: '- A senha deve conter no maximo 16 digitos.',
                  },
                  {
                    validator: passwordRules,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirmação"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, preencha a confirmação de senha.',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Button
                shape="round"
                type="primary"
                size="small"
                htmlType="submit"
              >
                CONFIRMAR
              </Button>
            </Form>
          </FormContainer>
          <p> {appVersion} </p>
        </section>
      </Container>
    </Spin>
  );
};

export default ResetPassword;
